/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
import React, {
  useState, useEffect, lazy, Suspense,
} from 'react';
import {
  DialogContent, Dialog, Typography, Button, Paper, Link, Box,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import $ from 'jquery';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Entity, BrandSettingByLocation } from '../../../Api Mutation/EntityApi';
import BusinessFavicon from '../../../common/BusinessFavicon';
import { handleUndefined, postDataRequestPublic } from '../../../common/commonfunctions';
import { LoadingPage } from '../../LoadingPage/LoadingPage';
import PoweredBy from '../../../common/PoweredBy';
import useStyle from './styles';
import config from '../../../config';
import './inquiry.css';
import {
  CreateInquiryContact, GetInquiryFormResponse, createInquiry, updateInquiry,
  InquiryById,
} from '../../../Api Mutation/InquiryForm';
import { showNotification } from '../../../components/Notification/ToastifyNotifications';
import Payment from '../../Setting/PaymentConnection/Payment';
import LazyLoader from '../../LoadingPage/LazyLoader';

const FormGenerator = lazy(() => import('./FormGenerator'));

export default function PublicInquiryForm() {
  const {
    formid,
  } = useParams([]);
  const search = window?.location?.search;
  const params = new URLSearchParams(search);
  const inquiryId = params.get('inquiryid');
  const [brandImage, setBrandImage] = useState();
  const [favIcon, setFavIcon] = useState();
  const [locationBrandImage, setLocationBrandImage] = useState();
  const [content, setContent] = useState();
  const [dataLoading, setDataLoading] = useState(false);
  const [ckValue, setCkValue] = useState();
  const [popupThankYouButtonText, setPopupThankYouButtonText] = useState();
  const [popupThankYouUrl, setPopupThankYouUrl] = useState('');
  const [showThankYouButton, setShowThankYouButton] = useState(false);
  const [thankYouFileUrl, setThankYouFileUrl] = useState();
  const [open, setOpen] = useState(false);
  const [callOutText, setCallOutText] = useState('');
  const [callOutBody, setCallOutBody] = useState('');
  const [rows, setRows] = useState(12);
  const [btnName, setBtnName] = useState('Subscribe Now');
  const [colorCode, setColorCode] = useState('#005eb2');
  const [backgroundColorCode, setBackgroundColorCode] = useState('transparent');
  const [foreColor, setForeColor] = useState('#000');
  const [btnPos, setBtnPos] = useState('right');
  const [fontColorCode, setFontColorCode] = useState('black');
  const [textPos, setTextPos] = useState('left');
  const [btnThemeColor, setBtnThemeColor] = useState('#005eb2');
  const [btnTextColor, setBtnTextColor] = useState('#000');
  const [thanksMsg, setThanksMsg] = useState(ckValue);
  const [termDesc, setTermDesc] = useState();
  const [enableTerm, setEnableTerm] = useState(false);
  const [enablePublicLogo, setEnablePublicLogo] = useState(true);
  const [paidForm, setPaidForm] = useState(false);
  const [radioPos, setRadioPos] = useState('vertical');
  const [checkBoxPos, setCheckBoxPos] = useState('vertical');
  const [formPrice, setFormPrice] = useState(0);
  const [defaultCountryPhoneCode, setDefaultCountryPhoneCode] = useState('1');
  const [contactData, setContactData] = useState([]);
  const [organizationData, setOrganizationData] = useState([]);
  const [isPayment, setIsPayment] = useState(false);
  const [paymentId, setPaymentId] = useState([]);
  const [submittedData, setData] = useState('');
  const [eventType, setEventType] = useState('single-page');
  const [formWidth, setFormWidth] = useState('50');
  const [locationid, setLocationId] = useState('');
  const [businessid, setBusinessId] = useState('');

  const classes = useStyle({
    color: colorCode,
    background: backgroundColorCode,
    textColor: foreColor,
    btnPosition: btnPos,
    textPosition: textPos,
    btnColor: btnThemeColor,
    formwidth: formWidth,
    btnText: btnTextColor,
  });

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setDataLoading(true);
      postDataRequestPublic(config.baseURLApi, {
        query: GetInquiryFormResponse,
        variables: {
          inquiryformid: formid,
        },
      }).then((res) => {
        setShowThankYouButton(res?.data?.inquiryform?.isshowthanksbutton);
        setPopupThankYouUrl(res?.data?.inquiryform?.thanksbuttonlink);
        setPopupThankYouButtonText(res?.data?.inquiryform?.thanksbuttontext);
        setCkValue(res?.data?.inquiryform?.thanksmessage);
        setContent(JSON.parse(res?.data?.inquiryform?.formdata));
        setThankYouFileUrl(res?.data?.inquiryform?.thanksbuttonfileurl);
        setCallOutText(res?.data?.inquiryform?.callouttext);
        setCallOutBody(res?.data?.inquiryform?.calloutbody);
        setRows(res?.data?.inquiryform?.selectedrows);
        setBtnName(res?.data?.inquiryform?.calloutbuttontext);
        setRadioPos(res?.data?.inquiryform?.radioview);
        setCheckBoxPos(res?.data?.inquiryform?.checkboxview);
        setFontColorCode(res?.data?.inquiryform?.fieldtextcolor);
        setColorCode(res?.data?.inquiryform?.themecolor);
        setBackgroundColorCode((res?.data?.inquiryform?.backgroundcolor === '#ffffff' || res?.data?.inquiryform?.backgroundcolor === '#fff') ? 'transparent' : res?.data?.inquiryform?.backgroundcolor);
        setForeColor(res?.data?.inquiryform?.textcolor);
        setFormWidth(res?.data?.inquiryform?.formwidth);
        setBtnPos(res?.data?.inquiryform?.calloutbtnposition);
        setTextPos(res?.data?.inquiryform?.textposition);
        if (res?.data?.inquiryform?.btnthemecolor) {
          setBtnThemeColor(res?.data?.inquiryform?.btnthemecolor);
          setBtnTextColor(res?.data?.inquiryform?.btntextcolor);
        }
        setTermDesc(res?.data?.inquiryform?.termdesc);
        setEnableTerm(res?.data?.inquiryform?.termenable);
        setEnablePublicLogo(res?.data?.inquiryform?.isshowfooterlogo);
        setPaidForm(res?.data?.inquiryform?.ispaid);
        setFormPrice(res?.data?.inquiryform?.formprice);
        setEventType(res?.data?.inquiryform?.formtype);
        setLocationId(res?.data?.inquiryform?.locationid);
        setBusinessId(res?.data?.inquiryform?.businessid);
        setDataLoading(false);
      });
    }
    return () => {
      isMounted = false;
    };
  }, [formid]);

  const getContact = (cid) => {
    if (cid) {
      postDataRequestPublic(config.baseURLApi, {
        query: `
        query contact($contactid: ID!){
          contact(contactid:$contactid){
            contactid,primaryemail,firstname,lastname,contactavatar,phonenumber,lifecyclestage,locationid,
            linkedin,createdby,updatedby,jobid,ownerid,createdAt,updatedAt,istargetlist,ispininchat,organization{
              organizationid,name,orgwebsite
            }
      }}
      `,
        variables: {
          contactid: cid,
        },
      }).then((res) => {
        if (res?.data?.contact) {
          setContactData(res?.data?.contact);
        }
      });
    }
  };

  const getOrganization = (oid) => {
    if (oid) {
      postDataRequestPublic(config.baseURLApi, {
        query: `
              query organization($organizationid: ID!){
                organization(organizationid: $organizationid){
                      organizationid,name,locationid,orgphonenumber,orgwebsite,orgtwitter,orgfacebook,orgstatus,categories,employeesize,
                      yearsinbusiness,revenue,businessstartdate,typeofentity,orgmap,orglinkedin,orggmb,orgemail,orgyoutubeurl,
                      address{addressid,street1,street2,city,county,stateid,countryid,zip,country{countryid,name},state{stateid,name}},orgyelp,orgtripadvisor,
                      createdby,updatedby,phonecountryid,createdAt,updatedAt,orgattribute1,orgattribute2,orgattribute3,orgattribute4,
                  }
              }`,
        variables: {
          organizationid: oid,
        },
      }).then((res) => {
        if (res?.data?.organization) {
          setOrganizationData(res?.data?.organization);
        }
      });
    }
  };

  const onFormSubmit = (data) => {
    const firstname = data.find((i) => i?.dbfieldName === 'firstname')?.value || data.find((i) => i.name.includes('First Name'))?.value;
    const lastname = data.find((i) => i?.dbfieldName === 'lastname')?.value || data.find((i) => i.name.includes('Last Name'))?.value;
    const orgname = data.find((i) => i?.dbfieldName === 'name')?.value || data.find((i) => i.name.includes('Organization Name'))?.value;
    const msg = ckValue?.replace(/{{Name}}/g, firstname ? `${firstname} ${lastname || ''}` : orgname || '');
    setThanksMsg(msg);
    setData(data);
    setDataLoading(true);
    postDataRequestPublic(config.baseURLApi, {
      query: CreateInquiryContact,
      variables: {
        responsedata: JSON.stringify(data),
        inquiryformid: formid,
        locationid,
        businessid,
      },
    }).then((res) => {
      const result = res?.data?.createinquirycontact;
      if (result) {
        if (result.contactid) {
          getContact(result.contactid);
        } else if (result.organizationid) {
          getOrganization(result.organizationid);
        }
        if (inquiryId) {
          postDataRequestPublic(config.baseURLApi, {
            query: updateInquiry,
            variables: {
              inquiryid: inquiryId,
              responsedata: JSON.stringify(data),
              inquiryformid: formid,
              status: 'active',
              locationid,
              businessid,
              contactid: result.contactid,
              organizationid: result.organizationid,
            },
          }).then((response) => {
            if (response.data) {
              $('form').trigger('reset');
              setOpen(true);
              setDataLoading(false);
            } else {
              showNotification(res?.errors?.[0]?.message, 'error');
              setDataLoading(false);
            }
          });
        } else if (paidForm) {
          showNotification('Please make payment to submit form..!', 'info');
          setIsPayment(true);
        } else {
          postDataRequestPublic(config.baseURLApi, {
            query: createInquiry,
            variables: {
              responsedata: JSON.stringify(data),
              inquiryformid: formid,
              status: 'active',
              locationid,
              businessid,
              contactid: result.contactid,
              organizationid: result.organizationid,
            },
          }).then((response) => {
            if (response.data) {
              $('form').trigger('reset');
              setOpen(true);
              setDataLoading(false);
            } else {
              showNotification(res?.errors?.[0]?.message, 'error');
              setDataLoading(false);
            }
          });
        }
      }
    });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted && paymentId.length > 0) {
      setDataLoading(true);
      postDataRequestPublic(config.baseURLApi, {
        query: createInquiry,
        variables: {
          responsedata: JSON.stringify(submittedData),
          inquiryformid: formid,
          status: 'active',
          locationid,
          businessid,
          contactid: contactData?.contactid,
          organizationid: organizationData?.organizationid,
          paymentid: paymentId,
        },
      }).then((response) => {
        const respo = response?.data?.createInquiry;
        if (respo?.message) {
          showNotification(respo.message, 'success');
          setDataLoading(false);
        } else {
          $('form').trigger('reset');
          setOpen(true);
          setIsPayment(false);
          setDataLoading(false);
        }
      });
    }
    return () => {
      isMounted = false;
    };
  }, [paymentId]);

  useEffect(() => {
    let isMounted = true;
    if (inquiryId && isMounted) {
      postDataRequestPublic(config.baseURLApi, {
        query: InquiryById,
        variables: {
          inquiryid: inquiryId,
        },
      }).then((res) => {
        if (res?.data?.inquiry?.responsedata) {
          setData(JSON.parse(res?.data?.inquiry?.responsedata));
        }
      });
    }
    return () => {
      isMounted = false;
    };
  }, [inquiryId]);

  const entityData = () => {
    if (locationid) {
      postDataRequestPublic(config.baseURLApi, {
        query: Entity,
        variables: { entityid: locationid },
      })
        .then((res) => {
          if (res?.data?.entity) {
            setLocationBrandImage(res?.data?.entity?.logourl);
            setFavIcon(res?.data?.entity?.favicon);
            if (res?.data?.entity?.defaultphonecountrycode) {
              setDefaultCountryPhoneCode(res?.data?.entity?.defaultphonecountrycode);
            }
            if (handleUndefined(res?.data?.entity?.parententityid)) {
              postDataRequestPublic(config.baseURLApi, {
                query: BrandSettingByLocation,
                variables: {
                  locationid: handleUndefined(res?.data?.entity?.parententityid),
                },
              }).then((r) => setBrandImage(r?.data?.brandSettingByLocation?.logourl));
            }
          }
          setDataLoading(false);
        });
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      entityData();
    }
    return () => {
      isMounted = false;
    };
  }, [locationid]);

  return (
    <>
      <BusinessFavicon
        name={callOutText}
        logo={favIcon || locationBrandImage || brandImage}
      />
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="responsive-dialog-title"
        maxWidth="xs"
      >
        <DialogContent id="responsive-dialog-title" style={{ padding: 20 }}>
          <center>
            <CheckCircleOutlineIcon style={{ fontSize: 70, color: 'green' }} />
          </center>
          <Typography variant="body1" dangerouslySetInnerHTML={{ __html: thanksMsg }} />
          <br />
          {thankYouFileUrl ? (
            <Link
              href={thankYouFileUrl}
              target="_blank"
              download
            >
              <Button
                variant="contained"
                size="large"
                color="primary"
              >
                {popupThankYouButtonText}
              </Button>
            </Link>
          ) : showThankYouButton
            ? (
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={() => {
                  window.open(popupThankYouUrl, '_blank');
                }}
              >
                {popupThankYouButtonText}
              </Button>
            )
            : ''}
          <center>
            {enablePublicLogo ? <PoweredBy id={locationid} /> : ''}
          </center>
        </DialogContent>
      </Dialog>
      {dataLoading ? (
        <LoadingPage />
      ) : (
        <div className={classes.paper}>
          <Paper className={classes.cal} style={{ border: eventType === 'single-page' ? '1px solid lightgrey' : 'none', marginTop: eventType === 'single-page' ? '0' : '10rem' }} elevation={6}>
            {isPayment ? (
              <Payment
                contactData={contactData}
                organizationData={organizationData}
                amount={formPrice}
                userId={businessid}
                businessId={businessid}
                locationId={locationid}
                setPaymentId={setPaymentId}
                formId={formid}
                inquiryform
              />
            )
              : (
                <>
                  <Box>
                    <Typography variant="h1" className={classes.callOutTextHead}>{callOutText}</Typography>
                    <Typography variant="body1" className={classes.LowWeightText}>{callOutBody}</Typography>
                  </Box>
                  <br />
                  <Suspense fallback={<LazyLoader />}>
                    <FormGenerator
                      content={content}
                      onFormSubmit={onFormSubmit}
                      defaultCountryPhoneCode={defaultCountryPhoneCode}
                      rows={rows}
                      btnName={btnName}
                      colorCode={colorCode}
                      fontColorCode={fontColorCode}
                      formWidth={formWidth}
                      backgroundColorCode={backgroundColorCode}
                      foreColor={foreColor}
                      btnPos={btnPos}
                      radioPos={radioPos}
                      checkBoxPos={checkBoxPos}
                      textPos={textPos}
                      btnThemeColor={btnThemeColor}
                      btnTextColor={btnTextColor}
                      termDesc={termDesc}
                      enableTerm={enableTerm}
                      eventType={eventType}
                      formData={submittedData}
                    />
                  </Suspense>
                  <br />
                  <center>
                    {enablePublicLogo ? <PoweredBy id={locationid} /> : ''}
                  </center>
                </>
              )}
          </Paper>
        </div>
      )}
    </>
  );
}
