export const Campaignrunsbyemailcampaign = `
query campaignrunsbyemailcampaign($emailcampaignid: ID!, $pagenumber: Int!, $pagesize: Int!,$issent: Boolean, $isdelivered: Boolean, $isopened: Boolean, $isclicked: Boolean, $isunsubscribed: Boolean, $isbounced: Boolean, $iserror: Boolean) {
    campaignrunsbyemailcampaign(emailcampaignid: $emailcampaignid, pagenumber: $pagenumber, pagesize: $pagesize,issent: $issent, isdelivered: $isdelivered, isopened: $isopened, isclicked: $isclicked, isunsubscribed: $isunsubscribed, isbounced: $isbounced, iserror: $iserror) {
      count,
      rows {
        campaignrunid
        sendto
        sgemailid
        opened
        status
        openeddate
        delivereddate
        clickeddate
        bounceddate
        unsubscribeddate
        scheduleat
        senttime
        errormessage
        customerid
        locationid
        createdby
        updatedby
        emailcampaignid
        campaignrunstatus
        campaign {
          campaignid
          name
          subject
          communicationtypeid
          body
          scheduledatetime
        }
        serviceproviderid
        serviceprovider
        threadid
        openeddata
        clickeddata
        opencount
        clickcount
        contact {
          contactid
          primaryemail
          firstname
          lastname
          phonenumber
          locationid
          jobid
        }
      }
    }
  }`;

export const AddActivityContentMarketing = `
  mutation addActivityContentMarketing($contentmarketingnid: ID!, $openeddata: String, $clickeddata: String) {
    addActivityContentMarketing(contentmarketingnid: $contentmarketingnid, openeddata: $openeddata, clickeddata: $clickeddata)
  }`;

export const Emailcampaignclickreportbyid = `
  query emailcampaignclickreportbyid($emailcampaignid: ID!) {
    emailcampaignclickreportbyid(emailcampaignid: $emailcampaignid) {
      url
      totalcount
      uniquecount
      customerid
      datetime
      contact {
        contactid
        primaryemail
        firstname
        lastname
        phonenumber
        jobid
        locationid
      }
    }
  }`;

export const Campaignrun = `
query campaignrun($campaignrunid: ID!) {
  campaignrun(campaignrunid: $campaignrunid) {
    campaignrunid
    sendto
    sgemailid
    opened
    status
    openeddate
    delivereddate
    clickeddate
    bounceddate
    unsubscribeddate
    scheduleat
    senttime
    errormessage
    customerid
    locationid
    createdby
    updatedby
    emailcampaignid
    campaignrunstatus
    campaign {
      campaignid
      name
      communicationtypeid
      subject
      body
      customers
      senditnow
      scheduledatetime
      locationid
      createdby
      updatedby
      status
      reviewtypeid
      createdAt
      updatedAt
      campaigntype
      createdUser {
        userid
        roleid
        firstname
        lastname
        phonenumber
        email
        role
        lastlogin
        deleted
        usertype
        businessid
        locationid
        defaultcontactview
      }
    }
    serviceproviderid
    serviceprovider
    threadid
    openeddata
    clickeddata
    opencount
    clickcount
    emailmarketing {
      emailcampaignid
      name
      fromname
      fromemail
      subject
      emailtemplate
      customers
      senditnow
      scheduledatetime
      locationid
      createdby
      updatedby
      status
      createdAt
      updatedAt
      templatedesign
      totaldelivered
      totalopened
      totalclicked
      totalbounced
      totalunsubscribed
      serviceproviderid
      serviceprovider
    }
  }
}`;

export const Emailcampaignurlreportbyid = `
query emailcampaignurlreportbyid($emailcampaignid: ID!, $url: String!) {
    emailcampaignurlreportbyid(emailcampaignid: $emailcampaignid, url: $url) {
      url
      totalcount
      uniquecount
      customerid
      datetime
      contact {
        contactid
        primaryemail
        firstname
        lastname
        phonenumber
      }
    }
  }`;

export const UniqueEmailCampaignUrlReportById = `
query uniqueEmailcampaignurlreportbyid($emailcampaignid: ID!, $url: String!) {
  uniqueEmailcampaignurlreportbyid(emailcampaignid: $emailcampaignid, url: $url) {
      url
      totalcount
      uniquecount
      customerid
      datetime
      contact {
        contactid
        primaryemail
        firstname
        lastname
        phonenumber
      }
    }
  }`;

export const ContactWithReport = `
  query contactWithReport($contactid: ID!){
    contactWithReport(contactid: $contactid){
      contactid,primaryemail,contactavatar,firstname,lastname,lifecyclestage,
      locationid,createdby,updatedby,ownerid,createdAt,updatedAt,phonenumber
     status,totalunread,todayactivity,totalactivity,todaytask,lastfivedaystask,pasttask,
     opentask,opennote,totalunread,organizationid
    }
}`;

export const CampaignrunsbycampaignReport = `
query campaignrunsbycampaign($campaignid: ID!, $pagenumber: Int!, $pagesize: Int!) {
  campaignrunsbycampaign(campaignid: $campaignid, pagenumber: $pagenumber, pagesize: $pagesize) {
    count
    rows {
      campaignrunid
      sendto
      sgemailid
      opened
      status
      openeddate
      delivereddate
      clickeddate
      bounceddate
      unsubscribeddate
      scheduleat
      senttime
      errormessage
      customerid
      locationid
      createdby
      updatedby
      emailcampaignid
      campaignrunstatus
      campaign {
        campaignid
        name
        communicationtypeid
        subject
        body
        customers
        senditnow
        scheduledatetime
        locationid
        createdby
        updatedby
        status
        reviewtypeid
        createdAt
        updatedAt
        campaigntype
        createdUser {
          userid
          roleid
          firstname
          lastname
          phonenumber
          email
          role
          lastlogin
          deleted
          usertype
          businessid
          locationid
          defaultcontactview
        }
      }
      serviceproviderid
      serviceprovider
      threadid
      openeddata
      clickeddata
      opencount
      clickcount
      contact {
        contactid
        primaryemail
        firstname
        lastname
        phonenumber
        locationid
        ownerid
      }
      emailmarketing {
        emailcampaignid
        name
        fromname
        fromemail
        subject
        emailtemplate
        customers
        senditnow
        scheduledatetime
        locationid
        createdby
        updatedby
        status
        createdAt
        updatedAt
        templatedesign
        totaldelivered
        totalopened
        totalclicked
        totalbounced
        totalunsubscribed
        serviceproviderid
        serviceprovider
      }
    }
  }
}`;

export const Campaignrunsbycampaign = `query Campaignrunsbyemailcampaign($emailcampaignid: ID!, $pagenumber: Int!, $pagesize: Int!, $isdelivered: Boolean, $isopened: Boolean, $isclicked: Boolean, $isbounced: Boolean, $isunsubscribed: Boolean, $iserror: Boolean) {
    campaignrunsbyemailcampaign(emailcampaignid: $emailcampaignid, pagenumber: $pagenumber, pagesize: $pagesize, isdelivered: $isdelivered, isopened: $isopened, isclicked: $isclicked, isbounced: $isbounced, isunsubscribed: $isunsubscribed, iserror: $iserror) {
      count
      rows {
        campaignrunid
        sendto
        sgemailid
        opened
        status
        openeddate
        delivereddate
        clickeddate
        bounceddate
        unsubscribeddate
        scheduleat
        senttime
        errormessage
        customerid
        locationid
        createdby
        updatedby
        emailcampaignid
        campaignrunstatus
        campaign {
          campaignid
          name
          communicationtypeid
          subject
          body
          customers
          senditnow
          scheduledatetime
          locationid
          createdby
          updatedby
          status
          reviewtypeid
          createdAt
          updatedAt
          campaigntype
          createdUser {
            userid
            roleid
            firstname
            lastname
            phonenumber
            email
            role
            lastlogin
            deleted
            usertype
            businessid
            locationid
            defaultcontactview
          }
        }
        serviceproviderid
        serviceprovider
        threadid
        openeddata
        clickeddata
        opencount
        clickcount
        contact {
          contactid
          primaryemail
          firstname
          lastname
          phonenumber
        }
      }
    }
  }`;

export const GetEmailCampaignReportData = `query getEmailCampaignReportData($emailcampaignid:ID!){
                getEmailCampaignReportData(emailcampaignid:$emailcampaignid){
                communicationtypename 
                reviewtypename
                totalerror
                totalsuccess
                totalsentemail
                total
                customerid
                errorpercentage
                successpercentage
                lastopeneddate
                lastclickeddate
                scheduleat
                totalclicked
                totaldelivered
                totalbounced
                totalopened
                totalunsubscribed
                campaignid
                name
                communicationtypeid
                subject
                body
                fromemail
                fromname
                serviceproviderid
                serviceprovider
                threadid
                senditnow
                scheduledatetime
                locationid
                createdby
                updatedby
                status
                reviewtypeid
                createdAt
                updatedAt
                graphdata
                }}`;

export const GetCampaignReportData = `query getCampaignReportData($campaignid:ID!){
    getCampaignReportData(campaignid:$campaignid){
    communicationtypename 
    reviewtypename
    totalerror
    totalsuccess
    totalsentemail
    total
    errorpercentage
    successpercentage
    lastopeneddate
    lastclickeddate
    scheduleat
    totalclicked
    totaldelivered
    totalbounced
    totalopened
    totalunsubscribed
    campaignid
    name
    communicationtypeid
    subject
    body
    customerid
    senditnow
    scheduledatetime
    locationid
    createdby
    updatedby
    status
    reviewtypeid
    createdAt
    updatedAt
    graphdata
    }}`;

export const ContentMarketing = `query contentMarketing($contentmarketingnid: ID!) {
  contentMarketing(contentmarketingnid: $contentmarketingnid) {
    contentmarketingnid
    name
    category
    author
    contenttemplate
    templatedesign
    locationid
    status
    isfree
    createdby
    updatedby
    createdAt
    updatedAt
    openeddata
    clickeddata
    sharecount
    isshowfooterlogo
    shorturl
  }
}`;

export const ContentMarketingUrlReportByid = `query contentMarketingUrlReportByid($contentmarketingnid: ID!) {
      contentMarketingUrlReportByid(contentmarketingnid: $contentmarketingnid) {
        count
        url
      }
    }`;

export const EditContentMarketing = `mutation editContentMarketing($contentmarketingnid: ID!, $updatedby: ID!,$isshowfooterlogo: Boolean, $name: String, $category: [String], $author: String, $contenttemplate: String, $templatedesign: String, $locationid: ID, $status: Int, $openeddata: String, $clickeddata: String, $sharecount: Int, $isfree: Boolean, $businessid: ID, $pageimage: Upload, $listpageimage: String, $ispublish: Boolean) {
  editContentMarketing(contentmarketingnid: $contentmarketingnid,isshowfooterlogo: $isshowfooterlogo, updatedby: $updatedby, name: $name, category: $category, author: $author, contenttemplate: $contenttemplate, templatedesign: $templatedesign, locationid: $locationid, status: $status, openeddata: $openeddata, clickeddata: $clickeddata, sharecount: $sharecount, isfree: $isfree, businessid: $businessid, pageimage: $pageimage, listpageimage: $listpageimage, ispublish: $ispublish)
}`;

export const CreateContentMarketing = `
      mutation 
      createContentMarketing(
        $name: String
        $category: [String]
        $author: String
        $contenttemplate: String
        $templatedesign: String
        $locationid: ID!
        $status: Int
        $createdby: ID!
        $isfree: Boolean
        $isshowfooterlogo: Boolean
         ){
          createContentMarketing( 
            name:$name
            category:$category
            author:$author
            isfree:$isfree
            contenttemplate:$contenttemplate
            templatedesign:$templatedesign
            locationid:$locationid
            status:$status
            createdby:$createdby
            isshowfooterlogo:$isshowfooterlogo
          ){
            contentmarketingnid
            name
            category
            author
            isfree
            isshowfooterlogo
            contenttemplate
            templatedesign
            locationid
            status
            shorturl
            createdby
            updatedby
            createdAt
            updatedAt
          }
         }`;
export const CreateContentTemplate = `
         mutation
         createContentTemplate(
           $contenthtml: String
           $contentjson: String
           $name: String
           $usagetype: Int
           $industrytype: Int
           $type: Int
           $locationid: ID!
           $ownerid: ID
           $createdby: ID!
           $ispublic: Boolean
             ){
               createContentTemplate(
                 contenthtml:$contenthtml
                 contentjson:$contentjson
                 name:$name
                 usagetype:$usagetype
                 industrytype:$industrytype
                 type:$type
                 locationid:$locationid
                 ownerid:$ownerid
                 createdby:$createdby
                 ispublic:$ispublic
                   ){
                     contenttemplateid
                     contenthtml
                     contentjson
                     name
                     usagetype
                     industrytype
                     type
                     locationid
                     ownerid
                     ispublic
                     previewimage
                     deleted
                     createdby
                     updatedby
                   }
                  }`;

export const ContentMarketingbyshorturl = `
query contentMarketingbyshorturl($shorturl: String!, $locationid: ID!) {
  contentMarketingbyshorturl(shorturl: $shorturl, locationid: $locationid) {
    contentmarketingnid,
    name,
    category,
    author,
    contenttemplate,
    templatedesign,
    locationid,
    status,
    isfree,
    createdby,
    updatedby,
    createdAt,
    updatedAt,
    openeddata,
    clickeddata,
    sharecount,
    shorturl,
    isshowfooterlogo,
  }
}`;

export const ReplicateEmailCampaign = `
mutation replicateEmailCampaign($emailcampaignid: ID!) {
  replicateEmailCampaign(emailcampaignid: $emailcampaignid) {
    emailcampaignid
    name
    fromname
    fromemail
    subject
    emailtemplate
    customers
    senditnow
    scheduledatetime
    locationid
    createdby
    updatedby
    status
    createdAt
    updatedAt
    templatedesign
    totaldelivered
    totalopened
    totalclicked
    totalbounced
    totalunsubscribed
    serviceproviderid
    serviceprovider
  }
}`;

export const ReplicateSmsCampaign = `
mutation replicateSmsCampaign($smscampaignid: ID!) {
  replicateSmsCampaign(smscampaignid: $smscampaignid) {
    smscampaignid
    name
    fromnumber
    fromname
    customers
    scheduledatetime
    smstext
    senditnow
    sendtime
    delevertime
    mmsurl
    locationid
    createdby
    updatedby
    status
    createdAt
    updatedAt
  }
}`;

export const ReplicateContentMarketing = `
mutation replicateContentMarketing($contentmarketingnid: ID!) {
  replicateContentMarketing(contentmarketingnid: $contentmarketingnid) {
    contentmarketingnid
    name
    category
    author
    contenttemplate
    templatedesign
    locationid
    status
    isfree
    createdby
    updatedby
    createdAt
    updatedAt
    openeddata
    clickeddata
    sharecount
    shorturl
    businessid
  }
}
`;

export const EmailcampaignSenders = `query emailcampaignsenders($locationid: ID!) {
  emailcampaignsenders(locationid: $locationid)
}`;

export const EmailCampaignsByContactId = `
query emailCampaignsByContactid($contactid: ID){
    emailCampaignsByContactid(contactid:$contactid){
        emailcampaignid,name,createdby,subject,scheduledatetime,openeddate,
        delivereddate,unsubscribeddate,bounceddate,errormessage,createdAt,sendto
 }}`;

export const CampaignsWithReviewByContact = `
query campaignsWithReviewByContact($contactid: ID){
    campaignsWithReviewByContact(contactid:$contactid){
        campaignid
        name
        communicationtypeid
        subject
        body
        customers
        senditnow
        scheduledatetime
        locationid
        createdby
        updatedby
        status
        reviewtypeid
        createdAt
        updatedAt
        campaigntype
        reviewid
        placeid
        providedby
        reviewdatetime
        review
        title
        reviewer
        displayinwidget
        reviewaction
        reviewstatusbyreplied
        rating
        replied
        reviewreplyid
        sourcereviewid
        customerid
        sourceid
        sharedcount
        likecount
        reviewcreatedate
        reviewupdatedat
        reviewstatus
        createdUser{firstname, lastname, email}
 }}`;

export const SendQuickReviewEmailSMS = `mutation
             sendQuickReviewEmailSMS(
                     $locationid: ID!,
                     $businessid: ID,
                     $customername: String!,
                     $customerid: ID!,
                     $isemail: Boolean,
                     $email: String!
                     $reviewTypeId: ID,
                     $createdby:ID!
                   ){
                     sendQuickReviewEmailSMS(
                         locationid: $locationid,
                         businessid: $businessid,
                         customername: $customername,
                         customerid: $customerid,
                         isemail: $isemail,
                         email: $email,
                         reviewtypeid : $reviewTypeId
                         createdby:$createdby
                       )
                   }`;

export const EditSmsCampaign = `mutation EditSmsCampaign($smscampaignid: ID!, $updatedby: ID!, $name: String, $fromnumber: String, $fromname: String, $bixpandnumber: Boolean, $documents: [Upload], $senditnow: Boolean, $customers: String, $scheduledatetime: String, $smstext: String, $sendtime: String, $delevertime: String, $locationid: ID, $createdby: ID, $status: Int, $searchText: String, $advancedfilter: [AdvancedFilter!], $filterid: ID, $selectiontype: Int, $systemfilters: [SystemFiltersInput!]) {
  editSmsCampaign(smscampaignid: $smscampaignid, updatedby: $updatedby, name: $name, fromnumber: $fromnumber, fromname: $fromname, bixpandnumber: $bixpandnumber, documents: $documents, senditnow: $senditnow, customers: $customers, scheduledatetime: $scheduledatetime, smstext: $smstext, sendtime: $sendtime, delevertime: $delevertime, locationid: $locationid, createdby: $createdby, status: $status, searchText: $searchText, advancedfilter: $advancedfilter, filterid: $filterid, selectiontype: $selectiontype, systemfilters: $systemfilters)
}`;

export const sendSms = `
mutation
  sendSms(
    $text: String!,
    $number: String!,
    $fullurl: String!,
  ){
    sendSms(
      text:$text,
      number:$number,
      fullurl:$fullurl,
    )
  }`;

export const FileUpload = `
mutation
    fileUpload(
        $fileName: String!,
        $data: String!,
        $mimeType: String!
         ){
            fileUpload(
             fileName:$fileName,
             data:$data,
             mimeType:$mimeType
             )
         }`;

export const SendTestEmailMarketing = `
mutation
sendtestemailmarketing(
  $subject: String!
  $emailtemplate: String!
  $email: String!
  $fromemail: String
  $fromname: String
){
  sendtestemailmarketing(
    subject:$subject
    emailtemplate:$emailtemplate
    email:$email
    fromemail:$fromemail
    fromname:$fromname
    )
}`;

export const EmailTemplateByFilters = `query emailtemplatebyfilters($locationid: ID, $templatefolderid: ID, $pagesize: Int, $usagetype: [Int!], $pagenumber: Int, $type: [Int!], $industrytype: [Int!], $ispublic: Boolean) {
  emailtemplatebyfilters(locationid: $locationid, templatefolderid: $templatefolderid, pagesize: $pagesize, usagetype: $usagetype, pagenumber: $pagenumber, type: $type, industrytype: $industrytype, ispublic: $ispublic) {
    count
    rows {
      emailtemplateid
      templatefolderid
      emailhtml
      emailjson
      name
      usagetype
      industrytype
      type
      locationid
      businessid
      ownerid
      ispublic
      previewimage
      deleted
      createdby
      updatedby
      subject
    }
  }
}`;

export const LookupdataByName = `query lookupdatabyname($name:String!)
{lookupdatabyname(name:$name){lookupid,name,key,value,parentvalue}}`;

export const Emailtemplate = `query Emailtemplate($emailtemplateid: ID!) {
  emailtemplate(emailtemplateid: $emailtemplateid) {
    emailtemplateid
    emailhtml
    emailjson
    name
    usagetype
    industrytype
    type
    locationid
    ownerid
    ispublic
    previewimage
    deleted
    createdby
    updatedby
  }
}`;
export const DeleteEmailTemplate = `
mutation deleteEmailTemplate($emailtemplateid: ID!) {
  deleteEmailTemplate(emailtemplateid: $emailtemplateid)
}`;

export const CommunicationSettingByLocation = `query communicationSettingByLocation($locationid: ID!) {
  communicationSettingByLocation(locationid: $locationid) {
    communicationsettingid
    fromname
    fromemail
    phonenumber
    sendgridkey
    locationid
    createdby
    updatedby
  }
}`;

export const EditCampaign = `mutation EditCampaign($campaignid: ID!, $updatedby: ID!, $communicationtypeid: ID, $reviewtypeid: ID, $name: String, $subject: String, $body: String, $customers: String, $brandsettingid: ID, $companyname: String, $companyaddress: String, $senditnow: Boolean, $scheduledatetime: String, $locationid: ID, $businessid: ID, $status: Int, $campaigntype: Int, $searchText: String, $advancedfilter: [AdvancedFilter!], $systemfilters: [SystemFiltersInput!], $filterid: ID, $selectiontype: Int) {
  editCampaign(campaignid: $campaignid, updatedby: $updatedby, communicationtypeid: $communicationtypeid, reviewtypeid: $reviewtypeid, name: $name, subject: $subject, body: $body, customers: $customers, brandsettingid: $brandsettingid, companyname: $companyname, companyaddress: $companyaddress, senditnow: $senditnow, scheduledatetime: $scheduledatetime, locationid: $locationid, businessid: $businessid, status: $status, campaigntype: $campaigntype, searchText: $searchText, advancedfilter: $advancedfilter, systemfilters: $systemfilters, filterid: $filterid, selectiontype: $selectiontype)
}`;

export const CreateEditEmailCampaign = `mutation EditEmailCampaign($emailcampaignid: ID!, $updatedby: ID!, $name: String, $subject: String, $emailtemplate: String, $customers: String, $fromname: String, $fromemail: String, $senditnow: Boolean, $scheduledatetime: String, $locationid: ID, $status: Int, $templatedesign: String, $serviceproviderid: ID, $serviceprovider: Int, $searchText: String, $advancedfilter: [AdvancedFilter!], $filterid: ID, $selectiontype: Int, $systemfilters: [SystemFiltersInput!], $emaildelaytimer: Int) {
  editEmailCampaign(emailcampaignid: $emailcampaignid, updatedby: $updatedby, name: $name, subject: $subject, emailtemplate: $emailtemplate, customers: $customers, fromname: $fromname, fromemail: $fromemail, senditnow: $senditnow, scheduledatetime: $scheduledatetime, locationid: $locationid, status: $status, templatedesign: $templatedesign, serviceproviderid: $serviceproviderid, serviceprovider: $serviceprovider, searchText: $searchText, advancedfilter: $advancedfilter, filterid: $filterid, selectiontype: $selectiontype, systemfilters: $systemfilters,emaildelaytimer: $emaildelaytimer)
}`;

export const DeleteTamplateMutation = `
mutation
sendtestemailmarketing(
  $subject: String!
  $emailtemplate: String!
  $email: String!
  $fromemail: String
  $fromname: String
){
  sendtestemailmarketing(
    subject:$subject
    emailtemplate:$emailtemplate
    email:$email
    fromemail:$fromemail
    fromname:$fromname
    )
}`;

export const EmailCampaign = `
query emailCampaign($emailcampaignid: ID!){
  emailCampaign(emailcampaignid: $emailcampaignid){
    name, emailcampaignid,subject,fromemail,fromname,templatedesign, emailtemplate,senditnow,scheduledatetime,locationid, createdby,
    status,
  }
} `;

export const FromUser = `query fromusers(
  $ownerid: ID!,
   $businessid: ID!,
    $locationid: ID!
  ){
  fromusers(
    ownerid: $ownerid,
     businessid: $businessid,
      locationid: $locationid
    ) {
      emailsettingid
      accountid
      refreshtoken
      accesstoken
      historyid
      useremail
      accesstokenexpirydate
      status
      servicesprovider
      createdby
      updatedby
      deleted
      locationid
      ownerid
      iscalendar
      isemail
      iscontact
      isdefaultemail
      username
      createdAt
      updatedAt
                }
              }`;

export const EditCommunicationSetting = `mutation editCommunicationSetting($communicationsettingid: ID!, $fromname: String!, $fromemail: String!, $updatedby: ID!, $phonenumber: String, $sendgridkey: String) {
  editCommunicationSetting(communicationsettingid: $communicationsettingid, fromname: $fromname, fromemail: $fromemail, updatedby: $updatedby, phonenumber: $phonenumber, sendgridkey: $sendgridkey)
}`;

export const Contenttemplatebyfilters = `query contenttemplatebyfilters($locationid: ID, $pagenumber: Int, $usagetype: [Int!], $industrytype: [Int!], $type: [Int!], $ispublic: Boolean, $pagesize: Int) {
  contenttemplatebyfilters(locationid: $locationid, pagenumber: $pagenumber, usagetype: $usagetype, industrytype: $industrytype, type: $type, ispublic: $ispublic, pagesize: $pagesize) {
    count
    rows {
      contenttemplateid
      contenthtml
      contentjson
      name
      usagetype
      industrytype
      type
      locationid
      ownerid
      ispublic
      previewimage
      deleted
      createdby
      updatedby
    }
  }
}`;

export const ContentTemplate = `
query contenttemplate($contenttemplateid: ID!){
  contenttemplate(contenttemplateid:$contenttemplateid){
    contenttemplateid
    contenthtml
    contentjson
    name
    usagetype
    industrytype
    type
    locationid
    ownerid
    ispublic
    previewimage
    deleted
    createdby
    updatedby
  }}`;

export const DeleteContentTemplate = `
mutation
deleteContentTemplate(
    $contenttemplateid: ID!
){
  deleteContentTemplate(
    contenttemplateid: $contenttemplateid
    )
}`;

export const CreateOrUpdateContentMarketingSettings = `mutation createOrUpdateContentMarketingSettings($ownerid: ID!, $businessid: ID!, $locationid: ID!, $createdby: ID!, $pagetitle: String, $subtitle: String,$isshowfooterlogo: Boolean, $contentposition: String, $isshowsubscribeform: Boolean, $lifecyclestage: Int, $subscriptiontitle: String, $status: Int) {
  createOrUpdateContentMarketingSettings(ownerid: $ownerid, businessid: $businessid, locationid: $locationid, createdby: $createdby, pagetitle: $pagetitle, subtitle: $subtitle, contentposition: $contentposition,isshowfooterlogo:$isshowfooterlogo, isshowsubscribeform: $isshowsubscribeform, lifecyclestage: $lifecyclestage, subscriptiontitle: $subscriptiontitle, status: $status) {
    contentmarketingsettingsid
    pagetitle
    subtitle
    contentposition
    isshowsubscribeform
    lifecyclestage
    subscriptiontitle
    ownerid
    status
    businessid
    locationid
    createdby
    updatedby
    isshowfooterlogo
  }
}`;

export const ContentMarketingSettingsByLocationId = `query contentMarketingSettingsByLocationId($locationid: ID!) {
  contentMarketingSettingsByLocationId(locationid: $locationid) {
    contentmarketingsettingsid
    pagetitle
    subtitle
    contentposition
    isshowsubscribeform
    lifecyclestage
    subscriptiontitle
    ownerid
    status
    businessid
    locationid
    createdby
    updatedby
    isshowfooterlogo
  }
}`;

export const ContentMarketingbylocation = `query contentMarketingbylocation($locationid: ID!, $pageNumber: Int!, $pageSize: Int!, $status: Int, $startdate: String, $enddate: String, $searchtext: String, $ispublish: Boolean) {
  contentMarketingbylocation(locationid: $locationid, pageNumber: $pageNumber, pageSize: $pageSize, status: $status, startdate: $startdate, enddate: $enddate, searchtext: $searchtext, ispublish: $ispublish) {
    count
    rows {
      contentmarketingnid
      name
      category
      author
      contenttemplate
      templatedesign
      locationid
      status
      isfree
      createdby
      updatedby
      createdAt
      updatedAt
      openeddata
      clickeddata
      sharecount
      shorturl
      businessid
      listpageimage
      ispublish
    }
  }
}`;

export const CreateTextTemplate = `mutation createTextTemplate($locationid: ID!, $businessid: ID!, $createdby: ID!, $name: String, $smstext: String, $type: Int, $ownerid: ID, $templatefolderid: ID, $ispublic: Boolean) {
  createTextTemplate(locationid: $locationid, businessid: $businessid, createdby: $createdby, name: $name, smstext: $smstext, type: $type, ownerid: $ownerid, templatefolderid: $templatefolderid, ispublic: $ispublic) {
    texttemplateid
    templatefolderid
    smstext
    name
    type
    locationid
    businessid
    ownerid
    ispublic
    deleted
    createdby
    updatedby
  }
}`;

export const EditTextTemplate = `mutation editTextTemplate($texttemplateid: ID!, $updatedby: ID!, $smstext: String, $templatefolderid: ID, $name: String, $type: Int, $locationid: ID, $businessid: ID, $ownerid: ID, $ispublic: Boolean) {
  editTextTemplate(texttemplateid: $texttemplateid, updatedby: $updatedby, smstext: $smstext, templatefolderid: $templatefolderid, name: $name, type: $type, locationid: $locationid, businessid: $businessid, ownerid: $ownerid, ispublic: $ispublic)
}`;

export const DeleteTextTemplate = `mutation deleteTextTemplate($texttemplateid: ID!) {
  deleteTextTemplate(texttemplateid: $texttemplateid)
}`;

export const CreateTemplateFolder = `mutation createTemplateFolder($name: String!, $createdby: ID!, $businessid: ID!, $locationid: ID!) {
  createTemplateFolder(name: $name, createdby: $createdby, businessid: $businessid, locationid: $locationid) {
    templatefolderid
    name
    locationid
    businessid
    isdefault
  }
}`;

export const UpdateTemplateFolder = `mutation updateTemplateFolder($templatefolderid: ID!, $name: String!, $updatedby: ID!) {
  updateTemplateFolder(templatefolderid: $templatefolderid, name: $name, updatedby: $updatedby)
}`;

export const DeleteTemplateFolder = `mutation deleteTemplateFolder($templatefolderid: ID!) {
  deleteTemplateFolder(templatefolderid: $templatefolderid)
}`;

export const TemplateFoldersByLocation = `query templateFoldersByLocation($locationid: ID!) {
  templateFoldersByLocation(locationid: $locationid) {
    templatefolderid
    name
    locationid
    businessid
    isdefault
  }
}`;

export const LocationSetting = `query Locationsetting($locationid: ID!) {
  locationsetting(locationid: $locationid) {
    locationsettingid
    badwords
    locationid
    businessid
    deleted
    createdby
    updatedby
  }
}`;

export const CreateEmailTemplate = `mutation createEmailTemplate($locationid: ID!, $createdby: ID!, $emailhtml: String, $subject: String, $emailjson: String, $name: String, $usagetype: Int, $industrytype: Int, $type: Int, $businessid: ID, $templatefolderid: ID, $ownerid: ID, $ispublic: Boolean) {
  createEmailTemplate(locationid: $locationid, createdby: $createdby, emailhtml: $emailhtml, emailjson: $emailjson, subject: $subject, name: $name, usagetype: $usagetype, industrytype: $industrytype, type: $type, businessid: $businessid, templatefolderid: $templatefolderid, ownerid: $ownerid, ispublic: $ispublic) {
    emailtemplateid
    templatefolderid
    emailhtml
    emailjson
    name
    usagetype
    industrytype
    type
    locationid
    businessid
    ownerid
    ispublic
    previewimage
    deleted
    createdby
    updatedby
    subject
  }
}`;

export const DeleteCampaign = `mutation DeleteCampaign($campaignid: ID!) {
  deleteCampaign(campaignid: $campaignid)
}`;

export const EmailCampaignsbylocation = `query EmailCampaignsbylocation($locationid: ID!, $pageNumber: Int!, $pageSize: Int!, $searchtext: String) {
  emailCampaignsbylocation(locationid: $locationid, pageNumber: $pageNumber, pageSize: $pageSize, searchtext: $searchtext) {
    rows {
      name
      emailcampaignid
    }
    count
  }
}`;

export const GetCampaignWorkflow = `query WorkflowAutomationsByLocation($locationid: ID!, $pageNumber: Int!, $pageSize: Int!) {
  workflowAutomationsByLocation(locationid: $locationid, pageNumber: $pageNumber, pageSize: $pageSize) {
    rows {
      workflowid
      name
    }
    count
  }
}`;

export const WorkflowReportDashboardById = `query WorkflowReportDashboardById($workflowid: ID!) {
  workflowReportDashboardById(workflowid: $workflowid) {
    totalsent
    totaldelivered
    totalopened
    totalclicked
    totalbounced
    totalunsubscribed
    totalerror
    totalsuccess
  }
}`;

export const EmailMarketingDashboardReportById = `query EmailMarketingDashboardReportById($emailcampaignid: ID!) {
  emailMarketingDashboardReportById(emailcampaignid: $emailcampaignid) {
    totalsent
    totaldelivered
    totalopened
    totalclicked
    totalbounce
    totalunsubscribed
  }
}`;

export const EmailDashboardReport = `query EmailDashboardReport($locationid: ID!, $interval: String!, $startdate: String, $enddate: String) {
  emailDashboardReport(locationid: $locationid, interval: $interval, startdate: $startdate, enddate: $enddate) {
    startdate
    enddate
    totalsent
    totaldelivered
    totalopened
    totalclicked
    totalbounce
    totalunsubscribed
  }
}`;

export const findEmail = `mutation FindEmail($createdby: ID!, $locationid: ID!, $contactids: [ID!], $businessid: ID) {
  findEmail(createdby: $createdby, locationid: $locationid, contactids: $contactids, businessid: $businessid)
}`;

export const findDomainByCompany =  `query Query($name: String!) {
  findDomainByCompany(name: $name)
}`;

export const findEmailByDomain = `mutation FindEmailByDomain($firstname: String!, $lastname: String!, $companyName: String!, $domain: String!, $createdby: ID!, $locationid: ID!, $businessid: ID) {
  findEmailByDomain(firstname: $firstname, lastname: $lastname, companyName: $companyName, domain: $domain, createdby: $createdby, locationid: $locationid, businessid: $businessid)
}`;