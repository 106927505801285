import React, { useEffect, useState } from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './CustomReactPhoneInput.css';
import { FormHelperText, Typography } from '@material-ui/core';
import { Entity } from '../../Api Mutation/EntityApi';
import { handleUndefined, postDataRequest } from '../../common/commonfunctions';
import config from '../../config';
import { useGlobalState } from '../../context/GlobalContext';

export default function PhoneNoWithCountryCode({
  input,
  status,
}) {
  const classes = useStyle();
  const [error, setError] = useState(false);
  const globalContext = useGlobalState();
  const locationId = globalContext.globalState.currentLocation.entityid;
  const entityId = localStorage.getItem('entityLocationId');
  const [defaultCountry, setDefaultCountry] = useState('');
  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    let isMounted = true;
    if ((locationId || entityId) && isMounted) {
      postDataRequest(config.baseURLApi, {
        query: Entity,
        variables: {
          entityid: locationId || entityId,
        },
      }).then((country) => {
        if (country?.data?.entity?.defaultcountrycode) {
          setDefaultCountry(country?.data?.entity?.defaultcountrycode);
        }
      });
    }
    return () => {
      isMounted = false;
    };
  }, [entityId, locationId]);
  return (
    <>
      <ReactPhoneInput
        {...input}
        isValid={(value, country) => {
          setDefaultValue(handleUndefined(value));
          if (value.length < 11 && value.length > 2 && country) {
            setError(true);
            return false;
          }
          setError(false);
          return true;
        }}
        country={defaultValue?.length > 4 ? '' : `${defaultCountry || 'us'}`}
        placeholder="Enter Phone Number"
        variant="outlined"
        disabled={status}

      />
      {error ? <FormHelperText error={error} variant="contained" id="component-error-text"><Typography variant="body2" className={classes.errorMsg}>Phone number is invalid</Typography></FormHelperText> : ''}
      <Typography variant="body2" className={classes.disclaimer}>*Add country code before the phonenumber</Typography>
    </>
  );
}
