/* eslint-disable react/jsx-no-bind */
/* eslint-disable indent */
import React, {
  Suspense, lazy, useEffect, useState,
} from 'react';
import {
  Tooltip,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Radio,
  IconButton,
  RadioGroup,
  CardActions,
  CardHeader,
  Select,
  Grid,
  Checkbox,
  Card,
  Chip,
  CardContent,
  Divider,
  Typography,
  Tabs,
  Tab,
  Avatar,
  TextField,
  InputAdornment,
  Box,
  Switch,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import 'font-awesome/css/font-awesome.min.css';
import Input from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import SendIcon from '@material-ui/icons/Send';
import ReplyIcon from '@material-ui/icons/Reply';
import { Alert, AlertTitle } from '@material-ui/lab';
import EditIcon from '@material-ui/icons/Edit';
import './Image.css';
import Rating from '@material-ui/lab/Rating';
import FormControl from '@material-ui/core/FormControl';
import Moment from 'react-moment';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CancelPresentationOutlinedIcon from '@material-ui/icons/CancelPresentationOutlined';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import { subDays } from 'date-fns';
import CachedIcon from '@material-ui/icons/Cached';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateFnsUtils from '@date-io/date-fns';
import ReadMoreAndLess from 'react-read-more-less';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import Popover from '@material-ui/core/Popover';
import ReportIcon from '@material-ui/icons/Report';
import AddIcon from '@material-ui/icons/Add';
import { ArrowBackIos } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import {
  ReviewSource, ReviewStatus, ReviewByStatus, ReviewImage,
} from '../../../common/const';
import {
  handleUndefined, postDataRequestPublic, postDataRequest, handleValidWebsite,
} from '../../../common/commonfunctions';
import { LoadingPage } from '../../LoadingPage/LoadingPage';
import Message from '../../Messages/Message';
import AccessError from '../../Access/AccessError';
import { useGlobalState } from '../../../context/GlobalContext';
import useStyles from './styles';
import SocialShare from '../../SocialShare/SocialShare';
import { showNotification } from '../../../components/Notification/ToastifyNotifications';
import config from '../../../config';
import usePagination from './Pagination';
import LazyLoader from '../../LoadingPage/LazyLoader';
import MuiCustomButton from '../../../common/MuiCustomButton';

const Widget = lazy(() => import('../../../components/Widget'));
const BusinessLable = lazy(() => import('../../BusinessLable/BusinessLable'));

const StyledRating = withStyles((theme) => ({
  iconFilled: {
    color: localStorage.getItem('theme') === 'dark' ? '#74b6f7' : '#0259a8',
  },
  iconHover: {
    color: localStorage.getItem('theme') === 'dark' ? '#74b6f7' : '#0259a8',
  },
  iconEmpty: {
    color: localStorage.getItem('theme') === 'dark' ? 'white' : '#74b6f7',
  },
  sizeLarge: {
    fontSize: '1.25rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
  },
}))(Rating);
export default function ReviewBoard({
  history,
  isMasterUser,
  publicPage,
  locationid,
  sendReplyToReview,
  filterReviews,
  viewCustomerInfo,
  addNote,
  shareReview,
  changeReviewAction,
  report,
  autoReply,
  whiteLabelStatus,
  logoUrl,
  setCustomerReviews,
}) {
  const classes = useStyles();
  const next = useHistory();
  const [activeTabId, setActiveTabId] = useState(0);
  const [activeTab, setActiveTab] = useState();
  const [reviewData, setReviewData] = useState([]);
  const [reviewNotes, setReviewNotes] = useState([]);
  const [addNotes, setAddNotes] = useState();
  const [error, setError] = useState();
  const [reviewChangeLog, setReviewChangeLog] = useState([]);
  const [fieldStatus, setFieldStatus] = useState(true);
  const [buttonStatus, setButtonStatus] = useState('edit Reply');
  const [existingReply, setExistingReply] = useState();
  const [reviewReplyId, setReviewreplyId] = useState();
  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment(Date.now()).format(config.dateFilter),
  );
  const [selectedEndDate, setSelectedEndDate] = React.useState(Date.now());
  const globalContext = useGlobalState();
  const [googlecomment, setGoogleComment] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [reviewDataHolder, setReviewDataHoler] = useState([]);
  const [visibleSearchField, setVisibleSearchField] = useState(false);
  const [page, setPage] = useState(1);

  const dateFilters = [
    'Period',
    'Today',
    '1 Week',
    '1 Month',
    'Current Year',
  ];
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const ReviewstatusbyReplied = {
    1: 'Reply',
    2: 'Replied',
  };

  // Filter
  const ReviewSourceFilter = {
    0: [1, 2],
    bixpand: [1],
    google: [2],
  };

  const ReviewStatusFilter = {
    0: [1, 2, 3, 4],
    Feedback: [1, 4],
    Review: [2],
    Rating: [3],
  };

  const ReviewActionFilter = {
    0: [1, 2, 3, 4],
    None: [1],
    'In-Progress': [2],
    'To-do': [3],
    Completed: [4],
  };

  const ReviewStatusbyRepliedFilter = {
    0: [1, 2],
    'Send Reply': [1],
    Replied: [2],
  };

  const [status, setStatus] = useState(ReviewStatusFilter[0]);
  const [minRating, setMinRating] = useState(0);
  const [maxRating, setMaxRating] = useState(10);
  const [reviewAction, setReviewAction] = useState(ReviewActionFilter[0]);
  const [reviewStatusByReplied, setReviewStatusByReplied] = useState(
    ReviewStatusbyRepliedFilter[0],
  );
  const [isReset, setIsReset] = useState(false);
  const [sourceId, setSourceId] = useState(ReviewSourceFilter[0]);
  const [sortOrder, setSortOrder] = useState('DESC');
  const [redirectOptions, setRedirectOptions] = useState();
  const [automationData, setAutomationData] = useState({
    checked: '',
  });

  function reviewDataUpdate() {
    postDataRequestPublic(config.reviewApi, {
      query: `{
        getReviewsWithCustomer(locationid: "${locationid || globalContext.globalState.currentLocation.entityid
        }") {
          review
          placeid
          campaignid
          customerid
          title
          status
          reviewdatetime
          reviewreplyid
          providedby
          rating
          contactavatar
          locationid
          reviewid
          isreported
          sourcereviewid
          replied
          sharedcount
          likecount
          deleted
          sourceid
          createdby
          displayinwidget
          firstname
          communicationtype
          reviewstatusbyreplied
          reviewtype
          lastname
          reviewaction
          email
          tags
          reviewer{ displayName, profilePhotoUrl }
          phonenumber
        }
      }`,
    }).then((json) => {
      if (json?.data?.getReviewsWithCustomer
        && json?.data?.getReviewsWithCustomer?.length > 0) {
        setReviewDataHoler(json?.data?.getReviewsWithCustomer);
        setReviewData(json?.data?.getReviewsWithCustomer);
        setCustomerReviews(json?.data?.getReviewsWithCustomer);
      }
    });
    setSelectedEndDate(Date.now());
    setSelectedStartDate(moment(Date.now()).format(config.dateFilter));
  }

  const handleChange = (event) => {
    history.push('/app/setting-process-automation');
    setAutomationData({ ...automationData, [event.target.name]: event.target.checked });
  };
  const googleReport = async (reviewId) => {
    setDataLoading(true);
    const CREATE_GOOGLEREPORT_MUTATION = `
        mutation
            reportReview(
                $reviewid:ID!,
               ){
                reportReview(
                    reviewid: $reviewid
                    )
               }`;
    await postDataRequestPublic(config.reviewApi, {
      query: CREATE_GOOGLEREPORT_MUTATION,
      variables: {
        reviewid: reviewId,
      },
    }).then((res) => {
      setDataLoading(false);
      if (res?.data?.reportReview) {
        reviewDataUpdate();
        setDataLoading(false);
      }
    });
  };
  const idToUrl = {
    1: '#',
    2: 'newreviewurl',
    3: 'yelprl',
    4: 'facebookurl',
    5: 'tripadvisorurl',
    6: 'opentableurl',
    7: 'angielisturl',
    8: 'realtorurl',
    9: 'trustpiloturl',
  };
  const AllDataRefresh = () => {
    setDataLoading(true);
    if (publicPage === true) {
      postDataRequestPublic(config.reviewApi, {
        query: `
      query getReviewsWithCustomerPublic(
            $locationid:ID!,
            $minrating:Int,
            $maxrating:Int,
            $startDate: String,
            $endDate: String,
            $sortorder: String,
    ){
    getReviewsWithCustomerPublic(
        locationid:$locationid,
        minrating:$minrating,
        maxrating:$maxrating,
        startDate:$startDate,
        endDate:$endDate,
        sortorder:$sortorder,
    ){
      review
      placeid
      campaignid
      customerid
      title
      status
      reviewdatetime
      reviewreplyid
      providedby
      rating
      isreported
      replydate
      replytext
      locationid
      displayinwidget
      reviewid
      sourcereviewid
      replied
      deleted
      contactavatar
      sourceid
      createdby
      sharedcount
      likecount
      firstname
      communicationtype
      reviewstatusbyreplied
      reviewtype
      lastname
      reviewer{ displayName, profilePhotoUrl }
      email
      jobid
      tags
      phonenumber
    }}
    `,
        variables: {
          locationid: locationid || globalContext?.globalState?.currentLocation?.entityid,
          sortorder: `${('DESC', 'ASC')}`,
          maxrating: 10,
          minrating: 0,
        },
      }).then((json) => {
        setDataLoading(false);
        setReviewDataHoler(json?.data?.getReviewsWithCustomerPublic);
        setReviewData(json?.data?.getReviewsWithCustomerPublic);
        setDataLoading(false);
      });
    } else {
      postDataRequestPublic(config.reviewApi, {
        query: `{
      getReviewsWithCustomer(locationid: "${locationid || globalContext.globalState.currentLocation.entityid
          }") {
        review
        placeid
        campaignid
        customerid
        title
        status
        reviewdatetime
        reviewreplyid
        providedby
        rating
        locationid
        sharedcount
        likecount
        reviewid
        sourcereviewid
        replied
        deleted
        isreported
        sourceid
        createdby
        contactavatar
        firstname
        communicationtype
        displayinwidget
        reviewstatusbyreplied
        reviewtype
        lastname
        reviewer{ displayName, profilePhotoUrl }
        reviewaction
        email
        jobid
        tags
        phonenumber
      }
    }`,
      })
        .then((json) => {
          setDataLoading(false);
          if (json?.data?.getReviewsWithCustomer
            && json?.data?.getReviewsWithCustomer?.length > 0) {
            setReviewDataHoler(json?.data?.getReviewsWithCustomer);
            setReviewData(json?.data?.getReviewsWithCustomer);
          }
        })
        .catch(() => {
          setDataLoading(false);
        });
    }
  };

  function checkboxHandalchange(displayWidgetId, displayinwidget) {
    const DISPLAY_WIDGET = `
      mutation
      editReview(
        $reviewid: ID!,
        $displayinwidget: Boolean,
        $updatedby: ID,
        ){
          editReview(
            reviewid: $reviewid,
            displayinwidget: $displayinwidget,
            updatedby: $updatedby 
        )   
      }`;
    postDataRequestPublic(config.reviewApi, {
      query: DISPLAY_WIDGET,
      variables: {
        reviewid: displayWidgetId,
        displayinwidget,
        updatedby: JSON.parse(localStorage.getItem('userid')),
      },
    }).then(() => {
      AllDataRefresh();
    });
  }
  const ReviewUpdate = async (reviewId) => {
    await postDataRequestPublic(config.reviewApi, {
      query: `{
            review(reviewid:"${reviewId}"){
             reviewid
             reviewaction
             likecount
             deleted
             sourceid
            }
          }
          `,
    }).then(() => { });
  };
  function handlechangeActionbyreview(reviewActions, reviewIdaction) {
    setDataLoading(true);
    const CREATE_EDITREVIEW_MUTATION = `
    mutation
    editReview(
      $reviewid: ID!,
      $reviewaction: Int,
      $updatedby: ID!,
      ){
        editReview(
          reviewid: $reviewid,
          reviewaction: $reviewaction,
          updatedby: $updatedby 
      )   
    }`;
    postDataRequestPublic(config.reviewApi, {
      query: CREATE_EDITREVIEW_MUTATION,
      variables: {
        reviewid: reviewIdaction,
        reviewaction: reviewActions,
        updatedby: JSON.parse(localStorage.getItem('userid')),
      },
    }).then(async (res) => {
      setDataLoading(false);
      if (res?.data?.editReview) {
        await ReviewUpdate();
        setDataLoading(false);
      }
    });
  }

  const handleDateChangeEndDate = (startDate, endDate) => {
    if (publicPage === true) {
      postDataRequestPublic(config.reviewApi, {
        query: `
      query getReviewsWithCustomerPublic(
            $startDate: String,
            $endDate: String
            $locationid:ID!,
    ){
    getReviewsWithCustomerPublic(
        locationid:$locationid,
        startDate:$startDate,
        endDate:$endDate
    ){
      review
      placeid
      campaignid
      customerid
      title
      status
      reviewdatetime
      reviewreplyid
      providedby
      rating
      replydate
      replytext
      isreported
      sharedcount
      likecount
      locationid
      displayinwidget
      reviewid
      sourcereviewid
      replied
      deleted
      contactavatar
      sourceid
      createdby
      firstname
      communicationtype
      reviewstatusbyreplied
      reviewtype
      lastname
      reviewer{ displayName, profilePhotoUrl }
      email
      jobid
      tags
      phonenumber
    }}
    `,
        variables: {
          locationid: locationid || globalContext?.globalState?.currentLocation?.entityid,
          startDate: moment(startDate).format('MM/DD/YYYY'),
          endDate: moment(endDate).format('MM/DD/YYYY'),
        },
      }).then((json) => {
        setReviewData(json?.data?.getReviewsWithCustomerPublic);
      });
    } else {
      setDataLoading(true);
      postDataRequestPublic(config.reviewApi, {
        query: `
  query getReviewsWithCustomer($locationid:ID!,$startDate: String,$endDate: String){
getReviewsWithCustomer(locationid:$locationid,startDate:$startDate,endDate:$endDate){
  review
  placeid
  campaignid
  customerid
  title
  status
  reviewdatetime
  reviewreplyid
  providedby
  rating
  locationid
  reviewid
  sourcereviewid
  replied
  deleted
  contactavatar
  isreported
  sharedcount
  likecount
  sourceid
  createdby
  firstname
  displayinwidget
  communicationtype
  reviewstatusbyreplied
  reviewtype
  lastname
  reviewer{ displayName, profilePhotoUrl }
  reviewaction
  email
  jobid
  tags
  phonenumber
}}
`,
        variables: {
          locationid: locationid || globalContext?.globalState?.currentLocation?.entityid,
          startDate: moment(startDate).format('MM/DD/YYYY'),
          endDate: moment(endDate).format('MM/DD/YYYY'),
        },
      }).then((res) => {
        setDataLoading(false);
        if (res?.data && res?.data?.getReviewsWithCustomer?.length > 0) {
          setReviewData(res?.data?.getReviewsWithCustomer);
          setDataLoading(false);
        }
      });
    }
  };

  const filterByReview = async (props) => {
    setDataLoading(true);
    setPage(1);
    // eslint-disable-next-line no-use-before-define
    reviewDataPagination.jump(1);
    if (publicPage === true) {
      postDataRequestPublic(config.reviewApi, {
        query: `
      query getReviewsWithCustomerPublic(
            $locationid:ID!,
            $minrating:Int,
            $maxrating:Int,
            $sourceid: [Int!],
            $sortorder: String,
    ){
    getReviewsWithCustomerPublic(
        locationid:$locationid,
        minrating:$minrating,
        maxrating:$maxrating,
        sourceid:$sourceid,
        sortorder:$sortorder,
    ){
      review
      placeid
      campaignid
      customerid
      title
      status
      replydate
      replytext
      reviewdatetime
      reviewreplyid
      providedby
      rating
      contactavatar
      sharedcount
      likecount
      locationid
      displayinwidget
      reviewid
      sourcereviewid
      replied
      deleted
      sourceid
      isreported
      createdby
      firstname
      communicationtype
      reviewstatusbyreplied
      reviewtype
      lastname
      reviewer{ displayName, profilePhotoUrl }
      email
      jobid
      tags
      phonenumber
    }}
    `,
        variables: {
          locationid: locationid || globalContext?.globalState?.currentLocation?.entityid,
          minrating: props.minRating,
          maxrating: props.maxRating,
          sourceid: props.sourceId,
          sortorder: props.sortOrder,
        },
      }).then((json) => {
        setDataLoading(false);
        setReviewData(json?.data?.getReviewsWithCustomerPublic);
        setPage(1);
        setDataLoading(false);
      });
    } else {
      await postDataRequestPublic(config.reviewApi, {
        query: `
         query getReviewsWithCustomer(
                $locationid:ID!,
                $status:[Int!]!,
                $minrating:Int,
                $maxrating:Int,
                $reviewaction: [Int!]
                $displayinwidget: Boolean
                $reviewstatusbyreplied: [Int!]
                $sourceid: [Int!]
                $sortorder: String
            ){
            getReviewsWithCustomer(
                locationid:$locationid,
                status:$status,
                minrating:$minrating,
                maxrating:$maxrating,
                reviewaction:$reviewaction,
                displayinwidget:$displayinwidget,
                reviewstatusbyreplied:$reviewstatusbyreplied,
                sourceid:$sourceid,
                sortorder:$sortorder,
              ){
                review
                placeid
                campaignid
                customerid
                title
                status
                reviewdatetime
                reviewreplyid
                providedby
                rating
                locationid
                reviewid
                sourcereviewid
                replied
                deleted
                sourceid
                createdby
                firstname
                sharedcount
                likecount
                isreported
                displayinwidget
                communicationtype
                reviewstatusbyreplied
                reviewtype
                lastname
                reviewer{ displayName, profilePhotoUrl }
                reviewaction
                email
                jobid
                tags
                phonenumber
          }
        }`,
        variables: {
          locationid: locationid || globalContext?.globalState?.currentLocation?.entityid,
          status: props.status,
          minrating: props.minRating,
          maxrating: props.maxRating,
          reviewaction: props.reviewAction,
          reviewstatusbyreplied: props.reviewStatusByReplied,
          sourceid: props.sourceId,
          sortorder: props.sortOrder,
        },
      }).then((res) => {
        setDataLoading(false);
        if (res?.data?.getReviewsWithCustomer
          && res?.data?.getReviewsWithCustomer?.length > 0) {
          setReviewData(res?.data?.getReviewsWithCustomer);
          setPage(1);
        }
        setDataLoading(false);
      });
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      filterByReview({
        status,
        minRating,
        maxRating,
        reviewAction,
        reviewStatusByReplied,
        sourceId,
        sortOrder,
      });
    }
    return () => {
      isMounted = false;
    };
  }, []);
  const handleShareReviewChangeLog = (Text, reviewId) => {
    postDataRequestPublic(config.reviewApi, {
      query: `mutation createReviewChangeLog(
      $actiontext: String!
      $reviewid: ID
      $createdby: ID!
    )
    { createReviewChangeLog(
      actiontext: $actiontext
      reviewid: $reviewid
      createdby:$createdby
    ){
      actiontext
    }}
    `,
      variables: {
        actiontext: Text,
        reviewid: reviewId,
        createdby: JSON.parse(localStorage.getItem('userid')),
      },
    });
  };
  function AddNotesReview(reviewId) {
    postDataRequestPublic(config.reviewApi, {
      query: `{
          reviewsNotesByReview(reviewid:"${reviewId}"){
            reviewid
            reviewnoteid
            notetext
            createdby
            createdAt
           updatedAt 
          }
          }`,
    }).then((json) => {
      setReviewNotes(json?.data?.reviewsNotesByReview);
    });
  }
  const AddReply = async (reviewId, reviewsReplyId) => {
    if (!existingReply) {
      setError(true);
    } else if (!error) {
      if (reviewReplyId) {
        const EDIT_REVIEW_REPLY = `
                    mutation
                    editReviewReply(
                        $reviewreplyid: ID!,
                        $reviewid: ID,
                        $replytext: String,
                        $updatedby: ID!
                    ){
                        editReviewReply(
                            reviewreplyid: $reviewreplyid
                            reviewid: $reviewid
                            replytext: $replytext
                            updatedby: $updatedby
                            )
                    }`;
        postDataRequestPublic(config.reviewApi, {
          query: EDIT_REVIEW_REPLY,
          variables: {
            reviewreplyid: reviewsReplyId,
            reviewid: reviewId,
            replytext: existingReply
              .split()
              .join('\\n')
              .split('"')
              .join('\\"'),
            updatedby: JSON.parse(localStorage.getItem('userid')),
          },
        }).then((res) => {
          if (res?.data?.editReviewReply) {
            showNotification(
              'Your Reply has been successfully Posted',
              'success',
            );
          }
        });
      } else {
        const CREATE_REVIEW_REPLY = `
            mutation
            createReviewReplay(
                $reviewid: ID,
                $replytext: String!,
                $createdby: ID!
            ){
            createReviewReplay(
                reviewid: $reviewid,
                replytext: $replytext,
                createdby: $createdby
                ){replytext}
            }`;
        postDataRequestPublic(config.reviewApi, {
          query: CREATE_REVIEW_REPLY,
          variables: {
            reviewid: reviewId,
            replytext: existingReply
              .split()
              .join('\\n')
              .split('"')
              .join('\\"'),
            createdby: JSON.parse(localStorage.getItem('userid')),
          },
        }).then((res) => {
          setReviewreplyId(
            res?.data?.createReviewReplay?.reviewreplyid,
          );
          showNotification(
            'Your Reply has been successfully Posted',
            'success',
          );
        });
      }
    }
  };
  const Addnot = async (reviewId) => {
    if (!addNotes) {
      setError(true);
    } else if (!error) {
      const CREATE_ADDNOTES_MUTATION = `
      mutation
        createReviewNote(
          $notetext: String!,
          $reviewid: ID,
          $createdby: ID!
        ){
          createReviewNote(
            notetext: $notetext,
            reviewid: $reviewid,
            createdby: $createdby  
        ){notetext} 
      }`;
      postDataRequestPublic(config.reviewApi, {
        query: CREATE_ADDNOTES_MUTATION,
        variables: {
          reviewid: reviewId,
          notetext: `${addNotes.split().join('\\n')}`,
          createdby: JSON.parse(localStorage.getItem('userid')),
        },
      }).then((res) => {
        if (res?.data?.createReviewNote) {
          setAddNotes('');
          AddNotesReview(reviewId);
        }
      });
      postDataRequestPublic(config.reviewApi, {
        query: `{
        reviewsNotesByReview(reviewid: "${reviewId}") {
          reviewid
          reviewnoteid
          notetext
          createdby
          createdAt
          updatedAt
        }
      }`,
      }).then((json) => {
        if (json?.data?.reviewsNotesByReview) {
          setReviewNotes(json?.data?.reviewsNotesByReview);
          postDataRequestPublic(config.reviewApi, {
            query: `{
        reviewChangeLogsByReview(reviewid: "${reviewId}") {
          reviewchangelogid
          actiontext
          createdby
          createdAt
          updatedAt
        }
      }`,
          }).then((changeLog) => {
            if (changeLog?.data?.reviewChangeLogsByReview) {
              setReviewChangeLog(changeLog?.data?.reviewChangeLogsByReview);
            } else {
              setReviewChangeLog([]);
            }
          });
        } else {
          setReviewNotes([]);
        }
        setReviewChangeLog([]);
      });
    }
  };

  const EditGoogleReplyEdit = async (reviewId, sourceReviewId) => {
    const CREATE_GOOGLEREVIEWREPLY = `
      mutation
      saveOrUpdateReviewComment(
        $comment: String!,
        $locationid:ID,
        $reviewid:ID,
        $sourcereviewid:String ,
      ){
        saveOrUpdateReviewComment(
          comment : $comment,
          locationid: $locationid,
          reviewid: $reviewid,
          sourcereviewid: $sourcereviewid,
        )
      }`;
    postDataRequestPublic(config.reviewApi, {
      query: CREATE_GOOGLEREVIEWREPLY,
      variables: {
        comment: googlecomment,
        reviewid: reviewId,
        locationid:
          globalContext?.globalState?.currentLocation?.entityid,
        sourcereviewid: sourceReviewId,
      },
    }).then((res) => {
      if (res?.data?.saveOrUpdateReviewComment) {
        showNotification(
          'Your Reply has been successfully Posted',
          'success',
        );
      } else {
        showNotification('Please Re-Login your GMB account', 'error');
      }
    });
  };
  const renderTodos = reviewData
    && reviewData?.length !== 0
    && reviewData?.map((item, index) => (
      <React.Fragment key={index}>
        <Grid item xs={12} md={4} sm={6}>
          <Box>
            <Card className={classes.cardMedia}>
              <CardHeader
                avatar={(
                  <Avatar
                    className={classes.customerPhoto}
                    aria-label=""
                    src={
                      item.sourceid
                        === ReviewSource.Google ? (
                        (item?.reviewer
                          ?.profilePhotoUrl)?.toString()
                      ) : (
                        item?.contactavatar?.toString() || <AccountCircleIcon />
                      )
                    }
                    color="primary"
                  />
                )}
                action={(
                  <div className={classes.cardHeaderRating}>
                    <div
                      className={
                        classes.cardRatingHeader
                      }
                    >
                      {item?.sourceid
                        === ReviewSource.Google ? (
                          <>
                            <Box
                              className={
                              classes.start
                            }
                            >
                              <Rating
                                className={
                                classes.startSize
                              }
                                readOnly
                                name="customized-empty"
                                precision={0.1}
                                value={
                                item.rating
                              }
                              />
                            </Box>
                          </>
                      ) : (
                        <>
                          {publicPage !== true ? (
                            <>
                              {ReviewStatus[
                                item?.status
                              ] === ReviewStatus[
                                ReviewByStatus.Rating
                                ] || ReviewStatus[
                                item?.status
                                ] === ReviewStatus[
                                ReviewByStatus.NegativeRating
                                ] ? (
                                  <StyledRating
                                    name="customized-color size-large"
                                    value={
                                    item?.rating
                                  }
                                    className={
                                    classes.topbar
                                  }
                                    precision={
                                    1
                                  }
                                    size="large"
                                    readOnly
                                  />
                              ) : (
                                <Typography
                                  className={classes.themeText}
                                  variant="body2"
                                >
                                  <b>
                                    NPS
                                    {' '}
                                  </b>
                                  :
                                  {
                                    item.rating
                                  }
                                  /10
                                </Typography>
                              )}
                            </>
                          ) : (
                            <>
                              {ReviewStatus[
                                item?.status
                              ] === ReviewStatus[
                                ReviewByStatus.Rating
                                ] || ReviewStatus[
                                item?.status
                                ] === ReviewStatus[
                                ReviewByStatus.NegativeRating
                                ]
                                ? (
                                  <Box
                                    className={
                                      classes.start
                                    }
                                  >
                                    <StyledRating
                                      className={
                                        classes.startSize
                                      }
                                      readOnly
                                      name="customized-empty"
                                      precision={
                                        1
                                      }
                                      value={item.rating}
                                    />
                                  </Box>
                                )
                                : (
                                  <Box
                                    className={
                                      classes.start
                                    }
                                  >
                                    <StyledRating
                                      className={
                                        classes.startSize
                                      }
                                      readOnly
                                      name="customized-empty"
                                      precision={
                                        0.1
                                      }
                                      value={
                                        item.rating / 2
                                      }
                                    />
                                  </Box>
                                )}
                            </>
                          )}
                        </>
                      )}
                      <Avatar
                        aria-label=""
                        size="small"
                        variant="square"
                        className={
                          classes.ratingBoxSpace
                        }
                        src={
                          (whiteLabelStatus === 'full' && item.sourceid === 1)
                          ? logoUrl : (ReviewImage[
                            item.sourceid
                          ])?.toString()
                        }
                        color="primary"
                      />
                    </div>
                  </div>
                )}
                title={
                  item.firstname ? (
                    <Typography variant="subtitle1">
                      {handleUndefined(
                        item.firstname,
                      )}
                      &nbsp;
                      {handleUndefined(item.lastname)}
                    </Typography>
                  ) : (
                    <Typography variant="subtitle1">
                      {handleUndefined(
                    item?.reviewer?.displayName,
                  )}
                    </Typography>
)
                }
                subheader={moment(
                  parseInt(item.reviewdatetime, 10),
                )
                  .local()
                  .format('ddd, MMM DD, YYYY')}
              />
              <CardContent className={classes.heightCard}>
                {publicPage !== true
                  && (ReviewStatus[item.status]
                    === 'Rating') ? (
                      <FormControlLabel
                        className={classes.showCase}
                        control={(
                          <Checkbox
                            float="Right"
                            color="primary"
                            size="small"
                            checked={
                          item.displayinwidget
                        }
                            disabled={isMasterUser}
                            onChange={(e) => checkboxHandalchange(
                          item.reviewid,
                          e.target.checked,
                        )}
                            inputProps={{
                          'aria-label':
                            'primary checkbox',
                        }}
                          />
                    )}
                        label={(
                          <Typography variant="subtitle1">
                            Showcase
                          </Typography>
                    )}
                      />
                ) : (
                  ''
                )}
                <Typography
                  color="primary"
                  variant="subtitle1"
                >
                  <b>{handleUndefined(item?.title)}</b>
                </Typography>
                <Typography
                  color="primary"
                  variant="body2"
                >
                  <pre className={classes.reviewtext}>
                    {handleUndefined(item?.review)}
                  </pre>
                </Typography>
              </CardContent>
              <CardActions
                className={classes.cardAction}
                disableSpacing
              >
                {item.sourceid === ReviewSource.Google || item.sourceid === ReviewSource.Bixpand ? (
                  <>
                    {publicPage !== true ? (
                      <MuiCustomButton
                        muiMt="5px"
                        muiSize="small"
                        muiVariant={
                          ReviewstatusbyReplied[
                            item.reviewstatusbyreplied
                          ] === 'Replied'
                            ? 'outlined'
                            : 'contained'
                        }
                        muiDisable={isMasterUser}
                        muiStartIcon={
                          ReviewstatusbyReplied[
                            item.reviewstatusbyreplied
                          ] === 'Replied' ? (
                            ''
                          ) : (
                            <ReplyIcon />
                          )
                        }
                        muiHandleClick={() => {
                          if (
                            sendReplyToReview === true
                          ) {
                            setActiveTabId(0);
                            setActiveTab(item.reviewid);
                          } else {
                            showNotification(
                              Message.accessError,
                              'error',
                              Message.errorDuration,
                            );
                          }
                        }}
                        muiBtnValue={
                          ReviewstatusbyReplied[
                          item.reviewstatusbyreplied
                          ]
                        }
                      />
                    ) : (
                      ''
                    )}
                    {shareReview ? (
                      <>
                        <SocialShare
                          className={classes.BottomBut}
                          reviewBoard
                          inPublicPage={publicPage}
                          reviewId={item?.reviewid}
                          handleShareReviewChangeLog={
                            handleShareReviewChangeLog
                          }
                        />
                      </>
                    ) : (
                      ''
                    )}
                    {publicPage !== true ? (
                      <>
                        <FormControl
                          variant="outlined"
                          size="small"
                          color="primary"
                        >
                          <Tooltip title="Actions">
                            <Select
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              disabled={
                                changeReviewAction
                                !== true || isMasterUser
                              }
                              value={item.reviewaction}
                              className={classes.Actiondrop}
                              color="primary"
                              onChange={(e) => {
                                handlechangeActionbyreview(
                                  e.target.value,
                                  item.reviewid,
                                );
                                handleShareReviewChangeLog(
                                  'Review Action Changed',
                                  item.reviewid,
                                );
                                filterByReview({
                                  status,
                                  minRating,
                                  maxRating,
                                  reviewAction,
                                  reviewStatusByReplied,
                                  sourceId,
                                  sortOrder,
                                });
                              }}
                            >
                              <MenuItem value={1}>
                                None
                              </MenuItem>
                              <MenuItem value={2}>
                                In-progress
                              </MenuItem>
                              <MenuItem value={3}>
                                To-do
                              </MenuItem>
                              <MenuItem value={4}>
                                Completed
                              </MenuItem>
                            </Select>
                          </Tooltip>
                        </FormControl>
                        <Tooltip title={item?.isreported ? 'Reported' : 'Report'}>
                          <IconButton
                            color={item?.isreported ? '' : 'primary'}
                            size="small"
                            disabled={isMasterUser}
                            className={item?.isreported ? classes.disableReport : classes.BottomBut}
                            onClick={() => {
                              if (report) {
                                if (!item?.isreported) {
                                  googleReport(item?.reviewid);
                                }
                              } else {
                                showNotification(
                                  Message.accessError,
                                  'error',
                                  Message.errorDuration,
                                );
                              }
                            }}
                          >
                            <ReportIcon className={classes.themeText} />
                          </IconButton>
                        </Tooltip>
                      </>
                    ) : ''}
                  </>
                ) : (
                  <MuiCustomButton
                    muiSize="small"
                    muiVariant="contained"
                    muiHandleClick={() => {
                      if (handleUndefined(redirectOptions[idToUrl[item?.sourceid]])) {
                        window.open(handleValidWebsite(redirectOptions[idToUrl[item?.sourceid]]) ? redirectOptions[idToUrl[item?.sourceid]] : `https://${redirectOptions[idToUrl[item?.sourceid]]}`);
                      } else {
                        showNotification('This site can not be open, no url found.', 'error');
                      }
                    }}
                    muiBtnValue="View Review Site"
                  />
                )}
              </CardActions>
            </Card>
          </Box>
        </Grid>
        <Dialog
          keepMounted
          maxWidth="md"
          fullWidth
          scroll="body"
          open={!!(item.reviewid === activeTab)}
          TransitionProps={() => {
            AddNotesReview(item.reviewid);
            setExistingReply(false);
            setGoogleComment();
            setAddNotes('');
            setReviewreplyId('');
            setError(false);
            setGoogleComment('');
            postDataRequestPublic(config.reviewApi, {
              query: `{
            reviewRepliesByReview(reviewid: "${item.reviewid}") {
              replytext
              reviewreplyid
            }
          }`,
            }).then((json) => {
              if (
                json?.data?.reviewRepliesByReview
                && json?.data?.reviewRepliesByReview[0]
              ) {
                setReviewreplyId(
                  json?.data?.reviewRepliesByReview[0]
                    ?.reviewreplyid,
                );
                postDataRequestPublic(config.reviewApi, {
                  query: `{
            reviewReply(reviewreplyid: "${json?.data?.reviewRepliesByReview[0]?.reviewreplyid}") {
              replytext
              createdby
              reviewreplyid
            }
          }`,
                }).then((reply) => {
                  if (
                    reply?.data?.reviewReply?.replytext
                  ) {
                    setExistingReply(
                      reply?.data?.reviewReply
                      && reply?.data?.reviewReply
                        ?.replytext,
                    );
                    setFieldStatus(true);
                  }
                });
              } else {
                setButtonStatus('Send Reply');
              }
            });
            postDataRequestPublic(config.reviewApi, {
              query: `{
        reviewsNotesByReview(reviewid: "${item.reviewid}") {
          reviewid
          reviewnoteid
          notetext
          createdby
          createdAt
          updatedAt
        }
      }`,
            }).then((json) => {
              if (json?.data?.reviewsNotesByReview) {
                setReviewNotes(
                  json?.data?.reviewsNotesByReview,
                );
                postDataRequestPublic(config.reviewApi, {
                  query: `{
        reviewChangeLogsByReview(reviewid: "${item.reviewid}") {
          reviewchangelogid
          actiontext
          createdby
          createdAt
          updatedAt
        }
      }`,
                }).then((reviewChangeLogs) => {
                  if (reviewChangeLogs?.data?.reviewChangeLogsByReview) {
                    setReviewChangeLog(reviewChangeLogs?.data?.reviewChangeLogsByReview);
                  } else {
                    setReviewChangeLog([]);
                  }
                });
              } else {
                setReviewNotes([]);
              }
              setReviewChangeLog([]);
            });
          }}
          onClose={() => {
            setAddNotes('');
            setReviewreplyId('');
            setError(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Typography
              color="primary"
              variant="body1"
              className={classes.topbar}
            >
              {ReviewImage[item.sourceid] ? (
                <img
                  className={classes.logo}
                  src={(ReviewImage[item.sourceid])?.toString()}
                  alt="Logo"
                />
              ) : ''}
              &nbsp;&nbsp;&nbsp;&nbsp;
            </Typography>
            <Typography
              variant="body1"
              style={{ float: 'left' }}
            >
              <b>
                {item.firstname
                  && item.firstname !== 'undefined'
                  ? item.firstname
                  : ''}
                {' '}
                {item.lastname
                  && item.lastname !== 'undefined'
                  ? item.lastname
                  : ''}
              </b>
            </Typography>
            {item?.sourceid === ReviewSource.Google ? (
              <>
                <Box
                  style={{ float: 'left' }}
                  className={classes.start}
                >
                  <Rating
                    readOnly
                    name="customized-empty"
                    precision={0.1}
                    value={item.rating}
                  />
                </Box>
              </>
            ) : ReviewStatus[
              item?.status
            ] === ReviewStatus[
              ReviewByStatus.Rating
              ]
              || ReviewStatus[
              item?.status
              ] === ReviewStatus[
              ReviewByStatus.NegativeRating
              ] ? (
                <StyledRating
                  name="customized-color size-large"
                  value={item?.rating}
                  className={classes.topbar}
                  precision={1}
                  size="large"
                  readOnly
                />
            ) : (
              <>
                <Typography
                  color="primary"
                  variant="body1"
                  className={classes.topbar}
                >
                  <b>NPS: </b>
                  {item.rating}
                </Typography>
              </>
            )}
            {item.sourceid === ReviewSource.Google ? (
              <>
                <Typography
                  color="primary"
                  variant="body1"
                  className={classes.topbar}
                >
                  <b>Review</b>
                </Typography>
              </>
            ) : (
              <>
                <Typography
                  color="primary"
                  variant="body1"
                  className={classes.topbar}
                >
                  <b>
                    {ReviewStatus[item?.status]
                      === ReviewStatus[ReviewByStatus.NegativeRating]
                      ? ReviewStatus[ReviewByStatus.Rating]
                      : ReviewStatus[item.status]}
                  </b>
                </Typography>
              </>
            )}
            <Typography
              color="primary"
              variant="body1"
              className={classes.topbar}
            >
              {moment(parseInt(item.reviewdatetime, 10))
                .local()
                .format('ddd, MMM DD, YYYY')}
            </Typography>
            <Typography
              color="primary"
              variant="body1"
              className={classes.topbar}
            >
              {item.communicationtype}
            </Typography>
            <CancelPresentationOutlinedIcon
              onClick={() => {
                setActiveTab(0);
                setExistingReply(false);
                AllDataRefresh();
                setAddNotes('');
                setReviewreplyId('');
                setError(false);
              }}
              className={classes.buttonClose}
              fontSize="large"
              variant="outlined"
            >
              Close
            </CancelPresentationOutlinedIcon>

            <Divider
              style={{ width: '100%', marginTop: '1%' }}
            />
          </DialogTitle>
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            value={activeTabId}
            scrollButtons="auto"
            variant="scrollable"
            onChange={(e, id) => setActiveTabId(id)}
          >
            <Tab
              label="Feedback"
              classes={{ root: classes.tab }}
            />
            <Tab
              label="Customer Info"
              classes={{ root: classes.tab }}
            />
            <Tab
              label="Notes"
              classes={{ root: classes.tab }}
            />
            <Tab
              label="Change Log"
              classes={{ root: classes.tab }}
            />
          </Tabs>
          <Divider style={{ width: '100%', marginTop: '1%' }} />
          {activeTabId === 0 && (
            <>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Box
                    style={{ display: 'flex' }}
                    className={classes.star}
                  >
                    <Typography
                      gutterBottom
                      variant="body1"
                    >
                      {item.title}
                    </Typography>
                  </Box>
                  <Typography variant="body1">
                    <pre className={classes.reviewtext}>
                      {item?.review?.length > 0 ? (
                        <ReadMoreAndLess
                          className="read-more-content"
                          charLimit={250}
                          readMoreText="Read more"
                          readLessText="Read less"
                        >
                          {item.review}
                        </ReadMoreAndLess>
                      ) : (
                        ' '
                      )}
                    </pre>
                  </Typography>
                  <Divider
                    style={{
                      width: '100%',
                      marginTop: '1%',
                    }}
                  />
                  <Typography
                    gutterBottom
                    variant="subtitle1"
                    color="primary"
                  >
                    Reply
                  </Typography>
                  {item.sourceid
                    === ReviewSource.Google ? (
                      <>
                        <Input
                          id="outlined-multiline-static"
                          multiline
                          minRows="5"
                          fullWidth
                          placeholder="Write reply here..."
                          margin="normal"
                          defaultValue={
                          existingReply === false
                            ? ''
                            : existingReply
                        }
                          disabled={
                          handleUndefined(
                            !!reviewReplyId,
                          ) === true
                          && fieldStatus
                        }
                          onChange={(val) => setGoogleComment(
                          val.target.value,
                        )}
                          variant="outlined"
                        />
                        <MuiCustomButton
                          muiVariant="contained"
                          muiSize="small"
                          muiDisable={isMasterUser}
                          muiStartIcon={
                          buttonStatus
                            === 'Send Reply' ? (
                              <SendIcon size="15px" />
                          ) : (
                            <EditIcon />
                          )
                        }
                          muiHandleClick={() => {
                          if (
                            buttonStatus
                            === 'Send Reply'
                          ) {
                            EditGoogleReplyEdit(
                              item.reviewid,
                              item.sourcereviewid,
                            );
                          }
                          setButtonStatus(
                            buttonStatus
                              === 'Edit Reply'
                              ? 'Send Reply'
                              : 'Edit Reply',
                          );
                          setFieldStatus(
                            buttonStatus
                            !== 'Edit Reply',
                          );
                        }}
                          muiBtnValue={buttonStatus}
                        />
                      </>
                  ) : (
                    <>
                      <Input
                        id="outlined-multiline-static"
                        multiline
                        minRows="5"
                        fullWidth
                        error={error}
                        helperText={
                          error ? 'Required' : ''
                        }
                        placeholder="Write reply here..."
                        margin="normal"
                        defaultValue={
                          existingReply === false
                            ? ''
                            : existingReply
                        }
                        disabled={
                          buttonStatus
                          !== 'Send Reply'
                        }
                        onChange={(val) => {
                          if (!val.target.value) {
                            setError(true);
                            setExistingReply(
                              val.target.value,
                            );
                          } else {
                            setError(false);
                            setExistingReply(
                              val.target.value,
                            );
                          }
                        }}
                        variant="outlined"
                      />
                      <Typography
                        gutterBottom
                        variant="h6"
                        style={{ fontSize: '12px' }}
                      >
                        <b>Last Reply : </b>
                        <Moment format="MM/DD/YYYY HH:mm">
                          {item.replydatetime}
                        </Moment>
                      </Typography>
                      <MuiCustomButton
                        muiVariant="contained"
                        muiSize="small"
                        muiDisable={isMasterUser}
                        muiStartIcon={
                          buttonStatus
                            === 'Send Reply' ? (
                              <SendIcon size="15px" />
                          ) : (
                            <EditIcon />
                          )
                        }
                        muiHandleClick={() => {
                          setButtonStatus(
                            buttonStatus
                              === 'Edit Reply'
                              ? 'Send Reply'
                              : 'Edit Reply',
                          );
                          setFieldStatus(
                            buttonStatus
                            !== 'Edit Reply',
                          );
                          if (
                            buttonStatus
                            === 'Send Reply'
                          ) {
                            AddReply(
                              item.reviewid,
                              reviewReplyId,
                              'save',
                            );
                          }
                        }}
                        muiBtnValue={buttonStatus}
                      />
                    </>
                  )}
                </DialogContentText>
              </DialogContent>
            </>
          )}
          {activeTabId === 1 && (
            <>
              {item?.sourceid === ReviewSource.Google ? (
                <>
                  <Suspense fallback={<LazyLoader />}>
                    <Widget>
                      {dataLoading ? (
                        <LoadingPage />
                      ) : (
                        <>
                          {viewCustomerInfo
                            === true ? (
                              <Grid
                                container
                                spacing={3}
                              >
                                <Typography
                                  gutterBottom
                                  variant="h6"
                                  className={
                                  classes.feedback
                                }
                                  style={{
                                  display:
                                    'flex',
                                  alignItems:
                                    'center',
                                }}
                                >
                                  <Avatar
                                    className={
                                    classes.fbsmall
                                  }
                                    src={
                                    (item
                                      ?.reviewer
                                      ?.profilePhotoUrl)?.toString()
                                  }
                                  />
                                </Typography>
                                <Grid
                                  item
                                  md={6}
                                  xs={12}
                                >
                                  <Input
                                    className={
                                    classes.field
                                  }
                                    label="Name"
                                    fullWidth
                                    defaultValue={
                                    item
                                      ?.reviewer
                                      ?.displayName
                                  }
                                    disabled
                                    error={
                                    error
                                  }
                                    helperText={
                                    error
                                      ? 'Required'
                                      : ''
                                  }
                                  />
                                </Grid>
                              </Grid>
                          ) : (
                            <AccessError />
                          )}
                        </>
                      )}
                    </Widget>
                  </Suspense>
                </>
              ) : (
                <>
                  <Suspense fallback={<LazyLoader />}>
                    <Widget>
                      {dataLoading ? (
                        <LoadingPage />
                      ) : (
                        <>
                          <Grid container spacing={1}>
                            {viewCustomerInfo
                              === true ? (
                                <>
                                  <Grid
                                    item
                                    md={6}
                                    xs={12}
                                  >
                                    <Input
                                      className={
                                      classes.field
                                    }
                                      label="First Name"
                                      fullWidth
                                      variant="outlined"
                                      size="small"
                                      defaultValue={
                                      item.firstname
                                        && item.firstname
                                        !== 'undefined'
                                        ? item.firstname
                                        : ''
                                    }
                                      disabled
                                      error={
                                      error
                                    }
                                      helperText={
                                      error
                                        ? 'Required'
                                        : ''
                                    }
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    md={6}
                                    xs={12}
                                  >
                                    <Input
                                      className={
                                      classes.field
                                    }
                                      label="Last Name"
                                      disabled
                                      variant="outlined"
                                      size="small"
                                      defaultValue={
                                      item.lastname
                                        && item.lastname
                                        !== 'undefined'
                                        ? item.lastname
                                        : ''
                                    }
                                      fullWidth
                                      error={
                                      error
                                    }
                                      helperText={
                                      error
                                        ? 'Required'
                                        : ''
                                    }
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    md={6}
                                    xs={12}
                                  >
                                    <Input
                                      className={
                                      classes.field
                                    }
                                      label="Jod Id"
                                      fullWidth
                                      variant="outlined"
                                      size="small"
                                      defaultValue={
                                      item.jobid
                                        && item.jobid
                                        !== 'undefined'
                                        ? item.jobid
                                        : ''
                                    }
                                      disabled
                                      error={
                                      error
                                    }
                                      helperText={
                                      error
                                        ? 'Required'
                                        : ''
                                    }
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    md={6}
                                    xs={12}
                                  >
                                    <Input
                                      className={
                                      classes.field
                                    }
                                      label="Email"
                                      disabled
                                      variant="outlined"
                                      size="small"
                                      defaultValue={
                                      item.email
                                        && item.email
                                        !== 'undefined'
                                        ? item.email
                                        : ''
                                    }
                                      error={
                                      error
                                    }
                                      helperText={
                                      error
                                        ? 'Required'
                                        : ''
                                    }
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    md={6}
                                    xs={12}
                                  >
                                    <Input
                                      className={
                                      classes.field
                                    }
                                      label="Phone Number"
                                      disabled
                                      variant="outlined"
                                      size="small"
                                      defaultValue={
                                      item.phonenumber
                                        && item.phonenumber
                                        !== 'undefined'
                                        ? item.phonenumber
                                        : ''
                                    }
                                      error={
                                      error
                                    }
                                      helperText={
                                      error
                                        ? 'Required'
                                        : ''
                                    }
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    md={6}
                                    xs={12}
                                  >
                                    <Input
                                      className={
                                      classes.field
                                    }
                                      label="Tags"
                                      fullWidth
                                      variant="outlined"
                                      size="small"
                                      defaultValue={
                                      item.tags
                                        && item.tags
                                        !== 'undefined'
                                        ? item.tags
                                        : ''
                                    }
                                      disabled
                                      error={
                                      error
                                    }
                                      helperText={
                                      error
                                        ? 'Required'
                                        : ''
                                    }
                                    />
                                  </Grid>
                                </>
                            ) : (
                              <AccessError />
                            )}
                          </Grid>
                        </>
                      )}
                    </Widget>
                  </Suspense>
                </>
              )}
            </>
          )}
          {activeTabId === 2 && (
            <>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Typography
                    gutterBottom
                    variant="body1"
                  >
                    All Notes
                    <MuiCustomButton
                      muiClass={
                        classes.addNoteButton
                      }
                      muiAriaControls="customized-menu"
                      muiAriaHasPopup="true"
                      muiSize="small"
                      muiVariant="contained"
                      muiDisable={isMasterUser}
                      muiStartIcon={<AddIcon />}
                      muiHandleClick={() => {
                        if (addNote === true) {
                          Addnot(item.reviewid);
                        } else {
                          showNotification(
                            Message.accessError,
                            'error',
                            Message.errorDuration,
                          );
                        }
                      }}
                      muiBtnValue="Add&nbsp;Note"
                    />
                  </Typography>
                  <Divider className={classes.divider} />
                  <Input
                    id="outlined-multiline-static"
                    label="Add Note"
                    multiline
                    value={addNotes}
                    minRows="5"
                    fullWidth
                    error={error}
                    helperText={error ? 'Required' : ''}
                    placeholder="Write note here"
                    margin="normal"
                    variant="outlined"
                    onChange={(val) => {
                      if (!val.target.value) {
                        setError(true);
                        setAddNotes(
                          val.target.value,
                        );
                      } else {
                        setError(false);
                        setAddNotes(
                          val.target.value,
                        );
                      }
                    }}
                  />
                  <Divider
                    style={{
                      width: '100%',
                      marginTop: '1%',
                    }}
                  />
                  {reviewNotes
                    ?.slice(0)
                    ?.reverse()
                    ?.map((note) => (
                      <Alert
                        key={note?.reviewid}
                        severity="success"
                        className={classes.alert}
                        style={{
                          fontFamily:
                            'Times New Roman',
                        }}
                      >
                        <pre
                          className={
                            classes.reviewtext
                          }
                        >
                          <AlertTitle
                            className={
                              classes.reviewnots
                            }
                          >
                            {note.notetext}
                          </AlertTitle>
                        </pre>
                        <Moment
                          style={{
                            fontSize: '12px',
                          }}
                          format="MM/DD/YYYY HH:mm"
                        >
                          {note.updatedAt}
                        </Moment>
                      </Alert>
                    ))}
                </DialogContentText>
              </DialogContent>
            </>
          )}
          {activeTabId === 3 && (
            <>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Typography variant="body1">
                    Change Log
                  </Typography>
                  <Divider
                    style={{
                      width: '100%',
                      marginTop: '1%',
                    }}
                  />
                  {reviewChangeLog?.map((items) => (
                    <Alert
                      key={items?.reviewchangelogid}
                      severity="success"
                      className={classes.alert}
                    >
                      <AlertTitle>
                        <Chip
                          className={
                            classes.changelog
                          }
                          color="primary"
                          label={items.actiontext}
                        />
                      </AlertTitle>
                      <Moment
                        style={{ fontSize: '12px' }}
                        format="MM/DD/YYYY h:mm:ss A"
                      >
                        {items.updatedAt}
                      </Moment>
                    </Alert>
                  ))}
                </DialogContentText>
              </DialogContent>
            </>
          )}
        </Dialog>
      </React.Fragment>
    ));

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setDataLoading(true);
      if (publicPage === true) {
        postDataRequestPublic(config.reviewApi, {
          query: `
        query getReviewsWithCustomerPublic(
              $locationid:ID!,
              $minrating:Int,
              $maxrating:Int,
              $startDate: String,
              $endDate: String,
              $sortorder: String,
      ){
      getReviewsWithCustomerPublic(
          locationid:$locationid,
          minrating:$minrating,
          maxrating:$maxrating,
          startDate:$startDate,
          endDate:$endDate,
          sortorder:$sortorder,
      ){
        review
        placeid
        campaignid
        customerid
        title
        status
        reviewdatetime
        reviewreplyid
        providedby
        replydate
        replytext
        rating
        contactavatar
        sharedcount
        likecount
        isreported
        locationid
        displayinwidget
        reviewid
        sourcereviewid
        replied
        deleted
        sourceid
        createdby
        firstname
        communicationtype
        reviewstatusbyreplied
        reviewtype
        lastname
        reviewer{ displayName, profilePhotoUrl }
        email
        jobid
        tags
        phonenumber
      }}
      `,
          variables: {
            locationid: locationid || globalContext?.globalState?.currentLocation?.entityid,
            sortorder: `${'DESC'}`,
            maxrating: 10,
            minrating: 0,
          },
        }).then((json) => {
          setDataLoading(false);
          setReviewDataHoler(json?.data?.getReviewsWithCustomerPublic);
          setReviewData(json?.data?.getReviewsWithCustomerPublic);
          setDataLoading(false);
        });
      } else {
        setDataLoading(true);
        postDataRequestPublic(config.reviewApi, {
          query: `{
          getReviewsWithCustomer(locationid: "${locationid || globalContext.globalState.currentLocation.entityid
            }") {
            review
            placeid
            campaignid
            customerid
            title
            status
            isreported
            reviewdatetime
            reviewreplyid
            providedby
            rating
            locationid
            reviewid
            contactavatar
            sourcereviewid
            replied
            deleted
            sourceid
            createdby
            firstname
            sharedcount
            likecount
            communicationtype
            displayinwidget
            reviewstatusbyreplied
            reviewtype
            lastname
            reviewer{ displayName, profilePhotoUrl }
            reviewaction
            email
            jobid
            tags
            phonenumber
          }
        }`,
        }).then((json) => {
          setDataLoading(false);
          if (json?.data?.getReviewsWithCustomer?.length > 0) {
            setReviewDataHoler(json?.data?.getReviewsWithCustomer);
            setReviewData(json?.data?.getReviewsWithCustomer);
          }
          setDataLoading(false);
        });
      }
      if (locationid || globalContext.globalState.currentLocation.entityid) {
        postDataRequestPublic(config.reviewApi, {
          query: `query socialprofilesbylocation($locationId:ID!){socialprofilesbylocation(locationid:$locationId){
              newreviewurl, yelprl, facebookurl, tripadvisorurl, opentableurl, angielisturl, realtorurl, trustpiloturl,
            }}`,
          variables: {
            locationId: locationid || globalContext.globalState.currentLocation.entityid,
          },
        }).then(async (url) => {
          if (url?.data?.socialprofilesbylocation) {
            setRedirectOptions(url?.data?.socialprofilesbylocation[0]);
          }
        });
        postDataRequest(config.reviewApi, {
          query: `
                  query automationSettingByLocation($locationid:ID!){
                      automationSettingByLocation(locationid:$locationid)
                  {
                          automationsettingsid,autoreply,locationid,positivereviewreply,negativereviewreply,feedbackreply
                  }
                      }`,
          variables: {
            locationid: locationid || globalContext.globalState.currentLocation.entityid,
          },
        })
          .then((res) => {
            setAutomationData(res?.data?.automationSettingByLocation[0]?.autoreply);
          });
      }
    }
    return () => {
      isMounted = false;
    };
  }, [
    locationid || globalContext.globalState.currentLocation.entityid,
  ]);
  const perPage = 9;
  const [dateFilterValue, setDateFilterValue] = React.useState('Period');
  const reviewDataPagination = usePagination(
    renderTodos?.length > 0 && renderTodos,
    perPage,
  );
  // -> to get the review nos per page from user

  // ReviewBoard data
  const handleChangeDateFilter = (event) => {
    setPage(1);
    reviewDataPagination.jump(1);
    switch (event.target.value) {
      case dateFilters[0]:
        AllDataRefresh();
        break;
      case dateFilters[1]:
        handleDateChangeEndDate(Date.now(), Date.now());
        break;
      case dateFilters[2]:
        handleDateChangeEndDate(subDays(Date.now(), 7), Date.now());
        break;
      case dateFilters[3]:
        handleDateChangeEndDate(subDays(Date.now(), 31), Date.now());
        break;
      case dateFilters[4]:
        handleDateChangeEndDate(
          moment(Date.now()).format(config.dateFilter),
          Date.now(),
        );
        break;
      default:
        AllDataRefresh();
    }
    setDateFilterValue(event?.target?.value);
  };

  const handleChangep = (e, p) => {
    setPage(p);
    reviewDataPagination.jump(p);
    setPage(p);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <div>
      {dataLoading ? <LoadingPage /> : <></>}
      {publicPage !== true ? (
        <Suspense fallback={<LazyLoader />}>
          <Widget
            disablewidgetmenu="true"
            inheritheight="true"
            size="small"
            className={classes.margin}
            bodyclass={classes.navPadding}
          >
            {publicPage !== true && visibleSearchField === true
              ? (
                <FormControl size="small" className={classes.formControl}>
                  <TextField
                    size="small"
                    className={classes.formControltextsize}
                    id="outlined-search"
                    type="search"
                    placeholder="Search..."
                    focused
                    onChange={(e) => {
                      const test = reviewDataHolder?.filter(
                        (team) => (
                          team?.firstname
                            ?.toLowerCase()
                            ?.includes(
                              e?.target?.value?.toLowerCase(),
                            )
                          || team?.lastname
                            ?.toLowerCase()
                            ?.includes(
                              e?.target?.value?.toLowerCase(),
                            )
                          || team?.review
                            ?.toLowerCase()
                            ?.includes(
                              e?.target?.value?.toLowerCase(),
                            )
                          || team?.title
                            ?.toLowerCase()
                            ?.includes(
                              e?.target?.value?.toLowerCase(),
                            )
                        ),
                      );
                      setReviewData(test);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon fontSize="small" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            size="small"
                            onClick={() => setVisibleSearchField(false)}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              )
              : (
                <Box
                  display={{ xs: 'inline-block', md: 'inline-flex' }}
                  alignItems="center"
                >
                  <Typography variant="h1" className={classes.centerDiv}>
                    <IconButton
                      className={classes.arrowBack}
                      color="primary"
                      variant="contained"
                      onClick={() => next?.push('/app/lead-central/review-marketing')}
                    >
                      <ArrowBackIos className={classes.iconTheme} />
                    </IconButton>
                    Reputation Board
                  </Typography>
                  <Suspense fallback={<LazyLoader />}>
                    <BusinessLable history={history} />
                  </Suspense>
                </Box>
              )}
            &nbsp;&nbsp;&nbsp;
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              keepMounted={!isReset}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <div className={classes.popOver}>
                <Typography variant="body1">
                  Filters&nbsp;
                  <MuiCustomButton
                    muiSize="small"
                    muiHandleClick={() => {
                      AllDataRefresh();
                      setAnchorEl(false);
                      setStatus(0);
                      setMinRating(0);
                      setMaxRating(0);
                      setReviewAction(ReviewActionFilter[0]);
                      setMaxRating(10);
                      setReviewStatusByReplied(
                        ReviewStatusbyRepliedFilter[0],
                      );
                      setSourceId(ReviewSourceFilter[0]);
                      setSortOrder('DESC');
                      setIsReset(true);
                    }}
                    muiBtnValue="Reset"
                  />
                </Typography>
                <br />
                <form className={classes.containerPopup}>
                  <FormControl
                    variant="outlined"
                    size="small"
                    color="primary"
                    className={classes.formControl}
                  >
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      color="primary"
                      defaultValue={0}
                      disabled={!filterReviews}
                      className={classes.formControltextsize}
                      onChange={(res) => {
                        setSortOrder(
                          res.target.value === 1
                            ? 'DESC'
                            : 'ASC',
                        );
                        filterByReview({
                          status,
                          minRating,
                          maxRating,
                          reviewAction,
                          reviewStatusByReplied,
                          sourceId,
                          sortOrder:
                            res.target.value === 1
                              ? 'DESC'
                              : 'ASC',
                        });
                      }}
                    >
                      <MenuItem value={0}>Sort</MenuItem>
                      <MenuItem value={1}>
                        Newest First
                      </MenuItem>
                      <MenuItem value={2}>
                        Oldest First
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    size="small"
                    color="primary"
                    className={classes.formControl}
                  >
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      color="primary"
                      defaultValue={0}
                      disabled={!filterReviews}
                      className={classes.formControltextsize}
                      onChange={(res) => {
                        if (res.target.value === 1) {
                          setMinRating(1);
                          setMaxRating(6);
                          filterByReview({
                            status,
                            minRating: 1,
                            maxRating: 6,
                            reviewAction,
                            reviewStatusByReplied,
                            sourceId,
                            sortOrder,
                          });
                        } else if (res.target.value === 2) {
                          setMinRating(7);
                          setMaxRating(10);
                          filterByReview({
                            status,
                            minRating: 7,
                            maxRating: 10,
                            reviewAction,
                            reviewStatusByReplied,
                            sourceId,
                            sortOrder,
                          });
                        } else {
                          setMinRating(0);
                          setMaxRating(10);
                          filterByReview({
                            status,
                            minRating: 0,
                            maxRating: 10,
                            reviewAction,
                            reviewStatusByReplied,
                            sourceId,
                            sortOrder,
                          });
                        }
                      }}
                    >
                      <MenuItem value={0}>
                        Rating All
                      </MenuItem>
                      <MenuItem value={1}>1 to 6 </MenuItem>
                      <MenuItem value={2}>7 to 10</MenuItem>
                    </Select>
                  </FormControl>

                  {publicPage !== true ? (
                    <FormControl
                      variant="outlined"
                      size="small"
                      color="primary"
                      className={classes.formControl}
                    >
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        color="primary"
                        defaultValue={0}
                        disabled={!filterReviews}
                        className={
                          classes.formControltextsize
                        }
                        onChange={(res) => {
                          setReviewAction(
                            ReviewActionFilter[
                            res.target.value
                            ],
                          );
                          filterByReview({
                            status,
                            minRating,
                            maxRating,
                            reviewAction:
                              ReviewActionFilter[
                              res.target.value
                              ],
                            reviewStatusByReplied,
                            sourceId,
                            sortOrder,
                          });
                        }}
                      >
                        <MenuItem value={0}>
                          Action
                        </MenuItem>
                        <MenuItem value="None">
                          ACTION : None
                        </MenuItem>
                        <MenuItem value="In-Progress">
                          ACTION : In-progress
                        </MenuItem>
                        <MenuItem value="To-do">
                          ACTION : To-do
                        </MenuItem>
                        <MenuItem value="Completed">
                          ACTION : Completed
                        </MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    ''
                  )}
                  {publicPage !== true ? (
                    <FormControl
                      variant="outlined"
                      size="small"
                      color="primary"
                      className={classes.formControl}
                    >
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        color="primary"
                        defaultValue={0}
                        disabled={!filterReviews}
                        className={
                          classes.formControltextsize
                        }
                        onChange={(res) => {
                          setReviewStatusByReplied(
                            ReviewStatusbyRepliedFilter[
                            res.target.value
                            ],
                          );
                          filterByReview({
                            status,
                            minRating,
                            maxRating,
                            reviewAction,
                            reviewStatusByReplied:
                              ReviewStatusbyRepliedFilter[
                              res.target.value
                              ],
                            sourceId,
                            sortOrder,
                          });
                        }}
                      >
                        <MenuItem value={0}>
                          Reply Status
                        </MenuItem>
                        <MenuItem value="Send Reply">
                          Send Reply
                        </MenuItem>
                        <MenuItem value="Replied">
                          Replied
                        </MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    ''
                  )}
                  {publicPage !== true ? (
                    <FormControl
                      variant="outlined"
                      size="small"
                      color="primary"
                      className={classes.formControl}
                    >
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        color="primary"
                        defaultValue={0}
                        disabled={!filterReviews}
                        className={
                          classes.formControltextsize
                        }
                        onChange={(res) => {
                          setStatus(
                            ReviewStatusFilter[
                            res.target.value
                            ],
                          );
                          filterByReview({
                            status:
                              ReviewStatusFilter[
                              res.target.value
                              ],
                            minRating,
                            maxRating,
                            reviewAction,
                            reviewStatusByReplied,
                            sourceId,
                            sortOrder,
                          });
                        }}
                      >
                        <MenuItem value={0}>Type</MenuItem>
                        <MenuItem value="Feedback">
                          Feedback
                        </MenuItem>
                        <MenuItem value="Review">
                          NPS
                        </MenuItem>
                        <MenuItem value="Rating">
                          Rating
                        </MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    ''
                  )}
                  <FormControl
                    variant="outlined"
                    size="small"
                    color="primary"
                    className={classes.formControl}
                  >
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      color="primary"
                      defaultValue={0}
                      disabled={!filterReviews}
                      className={classes.formControltextsize}
                      onChange={(res) => {
                        setSourceId(
                          ReviewSourceFilter[
                          res.target.value
                          ],
                        );
                        filterByReview({
                          status,
                          minRating,
                          maxRating,
                          reviewAction,
                          reviewStatusByReplied,
                          sourceId:
                            ReviewSourceFilter[
                            res.target.value
                            ],
                          sortOrder,
                        });
                      }}
                    >
                      <MenuItem value={0}>Platform</MenuItem>
                      <MenuItem value="bixpand">
                        <img
                          className={classes.logo}
                          src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/bix.png"
                          alt="Logo"
                        />
                      </MenuItem>
                      <MenuItem value="google">
                        <img
                          className={classes.logo}
                          src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/google.svg"
                          alt="Logo"
                        />
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    size="small"
                    className={classes.formControl}
                  >
                    <Select
                      value={dateFilterValue}
                      disabled={!filterReviews}
                      renderValue={(selected) => selected}
                      className={classes.formControltextsize}
                      MenuProps={MenuProps}
                      variant="outlined"
                    >
                      <RadioGroup
                        aria-label="date-fiter"
                        name="dateFilter"
                        value={dateFilterValue}
                        onChange={handleChangeDateFilter}
                      >
                        {dateFilters.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                          >
                            <FormControlLabel
                              value={name}
                              control={(
                                <Radio
                                  checked={
                                    name
                                    === dateFilterValue
                                  }
                                  color="primary"
                                />
                              )}
                              label={name}
                            />
                          </MenuItem>
                        ))}
                      </RadioGroup>
                    </Select>
                  </FormControl>
                  {dateFilterValue === dateFilters[0] ? (
                    <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                    >
                      <Grid className={classes.datepicker}>
                        <Box className={classes.daterange}>
                          &nbsp;
                          <MuiCustomButton
                            muiSize="small"
                            muiVariant="outlined"
                            muiHandleClick={() => handleDateChangeEndDate(
                              selectedStartDate,
                              selectedEndDate,
                            )}
                            muiBtnValue="Filter By Creation Date"

                          />
                          &nbsp;&nbsp;
                          <MuiCustomButton
                            muiSize="small"
                            muiColor="secondary"
                            muiHandleClick={reviewDataUpdate}
                            muiBtnValue="Reset"
                          />
                        </Box>
                        <KeyboardDatePicker
                          id="date-picker-dialog"
                          label="Start Date"
                          format="MM/dd/yyyy"
                          value={selectedStartDate}
                          onChange={(date) => setSelectedStartDate(date)}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                        {' '}
                        &nbsp; &nbsp; &nbsp;
                        <KeyboardDatePicker
                          id="date-picker-dialog"
                          label="End Date"
                          format="MM/dd/yyyy"
                          value={selectedEndDate}
                          onChange={(date) => setSelectedEndDate(date)}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  ) : (
                    ''
                  )}
                </form>
              </div>
            </Popover>
            <Box className={classes.btnBox}>
              <Tooltip title="Refresh">
                <IconButton
                  color="primary"
                  size="small"
                  className={classes.refreshIcon}
                  onClick={() => AllDataRefresh()}
                >
                  <CachedIcon className={classes.iconTheme} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Filter">
                <IconButton
                  color="primary"
                  size="small"
                  className={classes.refreshIcon}
                  onClick={handleClick}
                >
                  <FilterListIcon className={classes.iconTheme} />
                </IconButton>
              </Tooltip>
              {visibleSearchField === false ? (
                <Tooltip title="Search">
                  <IconButton
                    size="small"
                    color="primary"
                    className={classes.refreshIcon}
                    onClick={() => setVisibleSearchField(true)}
                  >
                    <SearchIcon className={classes.iconTheme} />
                  </IconButton>
                </Tooltip>
              ) : (
                ''
              )}
            </Box>
          </Widget>
        </Suspense>
      ) : (
        ''
      )}
      <Typography className={classes.displayText} variant="body1">
        {publicPage !== true ? (
          <FormControlLabel
            control={(
              <Switch
                display="flex"
                checked={!!automationData}
                size="small"
                disabled={isMasterUser || !autoReply}
                onChange={handleChange}
                name="checked"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            )}
            className={classes.autoReply}
            label="Auto Reply"
          />
        ) : ''}
      </Typography>
      <div className="pagination">
        {reviewData?.length === 0 ? (
          <>
            <center>
              <Typography className={classes.placeholder}>
                {localStorage.getItem('theme') === 'dark' ? (
                  <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/review_board_dark.svg" alt="review_board" className={classes.reviewIcon} />
                ) : (
                  <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/review_board.svg" alt="review_board" className={classes.reviewIcon} />
                )}
                <br />
                <span className={classes.themeText}>No records to display</span>
              </Typography>
            </center>
          </>
        ) : (
          <>
            <Grid container spacing={2}>
              {reviewDataPagination.currentData().map((v) => v)}
            </Grid>
          </>
        )}
        <br />
        <center>
          {renderTodos?.length > 0 ? (
            <Pagination
              className={classes?.displayNone}
              count={Math.ceil(reviewData?.length / perPage)}
              size="large"
              page={page}
              shape="round"
              color="primary"
              onChange={handleChangep}
            />
          ) : (
            ''
          )}
        </center>
        <Grid container spacing={1} />
      </div>
    </div>
  );
}
