import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import './styles.css';
import {
  Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel,
} from '@material-ui/core';
import {
  Close,
} from '@material-ui/icons';
import moment from 'moment';
import useStyle from './style';
import { showNotification } from '../Notification/ToastifyNotifications';
import { handleUndefined } from '../../common/commonfunctions';

const BixDataTable = ({
  defaultColDef,
  columnDefs,
  rowData,
  rowModelType,
  onGridReady,
  paginationPageSize,
  onSelectionChanged,
  onColumnMoved,
  viewBox,
  setSelectedContactId,
  history,
  isVisitor,
  columnVisibility,
  toggleColumnVisibility,
  showColumnView,
  openColumnView,
  setOpenColumnView,
  isFromContact,
}) => {
  const classes = useStyle();
  return (
    <div
      id="myGrid"
      className={localStorage.getItem('theme') === 'dark'
        ? 'ag-theme-alpine-dark grid-wrapper' : 'ag-theme-alpine grid-wrapper'}
    >
      <Dialog
        open={openColumnView}
        onClose={() => setOpenColumnView(false)}
      >
        <DialogTitle className={classes.dialogTitle}>Column View</DialogTitle>
        <DialogContent>
          <div
            className={classes.filterDialog}
          >
            {showColumnView && columnDefs.filter((c) => c.field !== 'workflowid' && c.field !== 'contact' && c.field !== 'status' && c.field !== 'workflowAction' && c.headerName !== 'Workflow Name' && c.headerName !== 'No of Contacts').map((column) => (
              <div key={column.field}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={columnVisibility[column.field]}
                      onChange={() => toggleColumnVisibility(column)}
                      color="secondary"
                    />
                  )}
                  label={column.headerName}
                />
              </div>
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            endIcon={<Close />}
            onClick={() => setOpenColumnView(false)}
            size="small"
            className={classes.closeBtn}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <div
        className={localStorage.getItem('theme') === 'dark'
          ? 'ag-theme-alpine-dark grid-wrapper' : 'ag-theme-alpine grid-wrapper'}
      >
        <AgGridReact
          rowStyle={{ backgroundColor: '#fff' }}
          defaultColDef={defaultColDef}
          columnDefs={showColumnView ? columnDefs.map((column) => ({
            ...column,
            hide: !columnVisibility[column.field],
          })) : columnDefs}
          rowData={rowData}
          pagination
          headerHeight={60}
          rowHeight={50}
          rowModelType={rowModelType}
          cacheBlockSize={paginationPageSize}
          cacheOverflowSize={2}
          maxConcurrentDatasourceRequests={2}
          infiniteInitialRowCount={1}
          maxBlocksInCache={1}
          paginationPageSize={paginationPageSize}
          onGridReady={onGridReady}
          rowSelection="multiple"
          suppressRowClickSelection
          onSelectionChanged={() => onSelectionChanged()}
          onColumnMoved={onColumnMoved}
          sortingOrder={['desc', 'asc']}
          overlayNoRowsTemplate={
            '<span style="padding: 10px;font-weight: 600;font-size: 15px;">No Records Found</span>'
          }
          onCellClicked={(params) => {
            if (isFromContact) {
              if (viewBox === 2) {
                history.push(`/app/contact-central?id=${params?.data.contactid}`);
                setSelectedContactId(params?.data?.contactid);
              } else if (params.value && params.value !== null) {
                if (typeof (params.value) === 'object') {
                  if (params.value.firstname) {
                    navigator.clipboard.writeText(`${params?.value?.firstname} ${params?.value?.lastname ? params?.value?.lastname : ''}`);
                    showNotification('value Copied', 'success');
                  } else {
                    navigator.clipboard.writeText(params.value[params?.value?.length - 1]);
                    showNotification('value Copied', 'success');
                  }
                } else if (params?.colDef?.headerName === 'Created Date') {
                  navigator.clipboard.writeText(moment(parseInt(params?.value, 10)).local().format('MMM DD, YY'));
                  showNotification('value Copied', 'success');
                } else {
                  navigator.clipboard.writeText(params?.value);
                  showNotification('value Copied', 'success');
                }
              }
            } else if (isVisitor) {
              const colHeaderName = params?.colDef?.headerName;
              const colValue = params?.value;
              if (colValue) {
                if (colHeaderName === 'Contact') {
                  const contactName = `${(colValue?.firstname || '')} ${(colValue?.lastname || '')}`;
                  navigator.clipboard.writeText(contactName);
                  showNotification('value Copied', 'success');
                } else if (colHeaderName === 'Contact Email') {
                  const contactEmail = colValue?.primaryemail;
                  navigator.clipboard.writeText(contactEmail);
                  showNotification('value Copied', 'success');
                } else if (colHeaderName === 'Visited Url') {
                  const visitedUrl = colValue?.join(', ') || '';
                  if (visitedUrl) {
                    navigator.clipboard.writeText(visitedUrl);
                    showNotification('value Copied', 'success');
                  }
                } else if (colHeaderName === 'Location Name') {
                  const jsonData = JSON.parse(colValue);
                  const city = handleUndefined(jsonData?.city) ? jsonData.city : '';
                  const region = handleUndefined(jsonData?.region) ? jsonData.region : '';
                  const country = handleUndefined(jsonData?.country) ? jsonData.country : '';
                  const parts = [city, region, country].filter((part) => part !== '');
                  const locationDetails = parts.length > 0 ? parts.join(', ') : '';
                  if (locationDetails) {
                    navigator.clipboard.writeText(locationDetails);
                    showNotification('value Copied', 'success');
                  }
                } else if (colHeaderName === 'System Details') {
                  const jsonData = JSON.parse(colValue);
                  const browserName = jsonData?.browser?.name;
                  const browserVersion = jsonData?.browser?.version;
                  const osName = jsonData?.os?.name;
                  const osVersion = jsonData?.os?.version;
                  const browserInfo = browserName && browserVersion ? `${browserName}-${browserVersion}` : browserName || '';
                  const osInfo = osName && osVersion ? `${osName}-${osVersion}` : osName || '';
                  const systemDetails = browserInfo || osInfo ? `${browserInfo}, ${osInfo}` : '';
                  if (systemDetails) {
                    navigator.clipboard.writeText(systemDetails);
                    showNotification('value Copied', 'success');
                  }
                } else if (colHeaderName === 'Organization') {
                  const jsonData = colValue?.organization;
                  const organizationName = jsonData?.name;
                  if (organizationName) {
                    navigator.clipboard.writeText(organizationName);
                    showNotification('value Copied', 'success');
                  }
                }
              }
            }
          }}
        />
      </div>
    </div>
  );
};

export default BixDataTable;
