import axios from 'axios';
import { useState, useEffect } from 'react';
import { Roles } from './const';
import config from '../config';

const enablesRolesAndAccessRoleIds = [Roles.BusinessManager, Roles.AgencyAdmin, Roles.AgencyManager,
  Roles.LocationUser, Roles.LocationManager, Roles.BusinessAdmin];
const errorCodes = [401, 403, 400, 500];

const publicheaders = {
  'Content-Type': 'application/json',
  Authorization: config?.staticToken,
  'Apollo-Require-Preflight': 'true',
};

const basicHeaders = {
  'Content-Type': 'application/json',
  Authorization: localStorage.getItem('token') ? `Basic ${localStorage.getItem('token')}` : config?.staticToken,
  'Apollo-Require-Preflight': 'true',
};

export const useFetch = (url, params) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      const fetchData = async () => {
        setLoading(true);
        const response = await axios({
          url,
          method: 'post',
          headers: basicHeaders,
          data: params,
        });
        if (response.status === 200) {
          setData(response.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      };
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [url, params]);
  return { loading, data };
};
export const checkAccess = (userPermissions, menu, moduleName, permission, userRoleId) => {
  if (enablesRolesAndAccessRoleIds?.includes(userRoleId)) {
    return userPermissions?.includes(
      menu && menu?.find((name) => name.name === moduleName)?.permissions.find((name) => (
        name.name === permission
      ))?.permissionid,
    );
  }
  return true;
};
export const dataRequest = async (url, params) => {
  try {
    const response = await axios({
      url,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : config?.staticToken,
        businessid: JSON.parse(localStorage?.getItem('business_Id')) || null,
        locationid: JSON.parse(localStorage?.getItem('location_Id')) || null,
        'Apollo-Require-Preflight': 'true',
      },
      data: params,
    });
    return response.data;
  } catch (error) {
    if (errorCodes?.includes(error?.response?.status) && (localStorage.getItem('token') ? localStorage.getItem('token') : config?.staticToken)) {
      localStorage.clear();
      document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      axios.defaults.headers.common.Authorization = '';
      window.location.reload(false);
    }
    throw error;
  }
};

export const postDataRequestImageUpload = async (url, data) => {
  try {
    const response = await axios({
      url,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : config?.staticToken,
        businessid: JSON.parse(localStorage?.getItem('business_Id')) || null,
        locationid: JSON.parse(localStorage?.getItem('location_Id')) || null,
        'Apollo-Require-Preflight': 'true',
      },
      data,
    });
    return response.data;
  } catch (error) {
    if (error?.response?.status === 403 && (localStorage.getItem('token') ? localStorage.getItem('token') : config?.staticToken)) {
      localStorage.clear();
      document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      axios.defaults.headers.common.Authorization = '';
      window.location.reload(false);
    }
    throw error;
  }
};
export const postDataRequestPublic = async (url, variables) => {
  try {
    const response = await axios({
      url,
      method: 'post',
      headers: publicheaders,
      data: variables,
    });
    return response.data;
  } catch (error) {
    if (error?.response?.status === 403 && (localStorage.getItem('token') ? localStorage.getItem('token') : config?.staticToken)) {
      localStorage.clear();
      axios.defaults.headers.common.Authorization = '';
      window.location.reload(false);
    }
    throw error;
  }
};

export const postRequest = async (url, variables) => {
  try {
    const response = await axios({
      url,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Apollo-Require-Preflight': 'true',
      },
      data: variables,
    });
    return response.data;
  } catch (error) {
    if (error?.response?.status === 403 && (localStorage.getItem('token') ? localStorage.getItem('token') : config?.staticToken)) {
      localStorage.clear();
      axios.defaults.headers.common.Authorization = '';
      window.location.reload(false);
    }
    throw error;
  }
};

export const deleteDataRequest = async (url, variables) => {
  try {
    const response = await axios({
      url,
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : config?.staticToken,
        businessid: JSON.parse(localStorage?.getItem('business_Id')) || null,
        locationid: JSON.parse(localStorage?.getItem('location_Id')) || null,
        'Apollo-Require-Preflight': 'true',
      },
      data: variables,
    });
    return response?.data;
  } catch (error) {
    if (errorCodes?.includes(error?.response?.status) && (localStorage.getItem('token') ? localStorage.getItem('token') : config?.staticToken)) {
      localStorage.clear();
      document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      axios.defaults.headers.common.Authorization = '';
      window.location.reload(false);
    }
    throw error;
  }
};

export const putDataRequest = async (url, variables) => {
  try {
    const response = await axios({
      url,
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : config?.staticToken,
        businessid: JSON.parse(localStorage?.getItem('business_Id')) || null,
        locationid: JSON.parse(localStorage?.getItem('location_Id')) || null,
        'Apollo-Require-Preflight': 'true',
      },
      data: variables,
    });
    return response?.data;
  } catch (error) {
    if (errorCodes?.includes(error?.response?.status) && (localStorage.getItem('token') ? localStorage.getItem('token') : config?.staticToken)) {
      localStorage.clear();
      document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      axios.defaults.headers.common.Authorization = '';
      window.location.reload(false);
    }
    throw error;
  }
};

export const postDataRequest = async (url, variables) => {
  try {
    const response = await axios({
      url,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : config?.staticToken,
        businessid: JSON.parse(localStorage?.getItem('business_Id')) || null,
        locationid: JSON.parse(localStorage?.getItem('location_Id')) || null,
        'Apollo-Require-Preflight': 'true',
      },
      data: variables,
    });
    return response?.data;
  } catch (error) {
    if (errorCodes?.includes(error?.response?.status)
    && (localStorage.getItem('token') ? localStorage.getItem('token') : config?.staticToken)) {
      localStorage.clear();
      document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      axios.defaults.headers.common.Authorization = '';
      window.location.reload(false);
    }
    throw error;
  }
};
export const getDataRequest = async (url, variables) => {
  try {
    const response = await axios({
      url,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : config?.staticToken,
        businessid: JSON.parse(localStorage?.getItem('business_Id')) || null,
        locationid: JSON.parse(localStorage?.getItem('location_Id')) || null,
        'Apollo-Require-Preflight': 'true',
      },
      data: variables,
    });
    return response.data;
  } catch (error) {
    if (error?.response?.status === 403 && (localStorage.getItem('token') ? localStorage.getItem('token') : config?.staticToken)) {
      localStorage.clear();
      document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      axios.defaults.headers.common.Authorization = '';
      window.location.reload(false);
    }
    throw error;
  }
};

export const getDataRequestPublic = async (url, variables) => {
  try {
    const response = await axios({
      url,
      method: 'get',
      headers: publicheaders,
      data: variables,
    });
    return response?.data;
  } catch (error) {
    if (error?.response?.status === 403 && (localStorage.getItem('token') ? localStorage.getItem('token') : config?.staticToken)) {
      localStorage.clear();
      document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      axios.defaults.headers.common.Authorization = '';
      window.location.reload(false);
    }
    throw error;
  }
};

export const handleUndefined = (message) => (
  (message
            && (message !== 'undefined' && message !== 'null' ? message : ''))
        || ''
);
export const handleValidWebsite = (url) => (
  (url
            && (!!(url !== 'undefined'
                && url !== 'null'
                && (url.startsWith('https://') || url.startsWith('http://')))))
        || ''
);
export const handleValidEmail = (email) => (
  (email.trim()
            && (!!(email.trim() !== 'undefined'
                && email.trim() !== 'null'
                && (/^(^[a-zA-Z0-9._%+\-ñí!#$&'*+/=?^_`{|}~()[\]:;"<>,.]+@[a-zA-Z0-9._%+\-ñí!#$&'*+/=?^_`{|}~()[\]:;"<>,.]+(\.[a-zA-Z0-9._%+\-ñí!#$&'*+/=?^_`{|}~()[\]:;"<>,.]{2,})+$)$/i.test(
                  email.trim(),
                )))))
        || ''
);

export const encrypt = (salt, text) => {
  const textToChars = (v) => v.split('').map((c) => c.charCodeAt(0));
  const byteHex = (n) => (`0${Number(n).toString(16)}`).substr(-2);
  // eslint-disable-next-line no-bitwise
  const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);

  return text
    .split('')
    .map(textToChars)
    .map(applySaltToChar)
    .map(byteHex)
    .join('');
};

export const decrypt = (salt, encoded) => {
  const textToChars = (text) => text.split('').map((c) => c.charCodeAt(0));
  // eslint-disable-next-line no-bitwise
  const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
  return encoded
    .match(/.{1,2}/g)
    .map((hex) => parseInt(hex, 16))
    .map(applySaltToChar)
    .map((charCode) => String.fromCharCode(charCode))
    .join('');
};

export function ValidateSubdomain() {
  const domainUrl = new URL(window?.location?.href);
  const parsedDomainUrl = domainUrl?.hostname;
  const domainWithoutWWW = parsedDomainUrl?.replace('www.', '');
  const subdomainValue = domainWithoutWWW?.split('.')[0];

  return subdomainValue === 'app' || subdomainValue === 'dev' || subdomainValue === 'localhost';
}
