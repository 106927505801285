import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, IconButton, InputBase, Link, ListItemText, TextField, Tooltip, Typography } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import useStyles from './Style';
import Dropzone from 'react-dropzone';
import ApolloClient from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import './History.css';
import { Attachment, Close, CloudUpload, Send } from '@material-ui/icons';
import { Autocomplete, Timeline, TimelineConnector, TimelineDot, TimelineItem, TimelineSeparator } from '@material-ui/lab';
import { Conversationlistbyticket, CreateConversation, Raiseticketbyticketid, Ticketstatushistory, UpdateTicket } from '../../Api Mutation/TicketApi';
import { handleUndefined, postDataRequestPublic } from '../../common/commonfunctions';
import config from '../../config';
import { LoadingPage } from '../LoadingPage/LoadingPage';
import { TicketStatus, TicketStatusByValue } from '../../common/const';
import moment from 'moment';
import { BrandSettingByLocation, Entity } from '../../Api Mutation/EntityApi';
import { UploadImage } from '../../Api Mutation/InquiryForm';
import { showNotification } from '../../components/Notification/ToastifyNotifications';
import InfiniteScroll from 'react-infinite-scroll-component';
import BusinessFavicon from '../../common/BusinessFavicon';
import TicketAttchments from './TicketAttchments';
import { Usersbytype } from '../../Api Mutation/Login';

function TicketHistory() {
  const classes = useStyles();
  const messagesEndRef = useRef(null);
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const ticketId = params.get('tid');
  const contactId = params.get('cid');
  const locationId = params.get('lid');
  const businessId = params.get('bid');
  const userType = params.get('from');
  const [conversationList, setCoversationList] = useState([]);
  const [historyList, setHistoryList] = useState([]);
  const [files, setFiles] = useState([]);
  const [ticketData, setTicketData] = useState(null);
  const [entityData, setEntityData] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [dropOpen, setDropOpen] = useState(false);
  const [fileLoader, setFileLoader] = useState(false);
  const [brandImage, setBrandImage] = useState('');
  const [favIcon, setFavIcon] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [locationBrandImage, setLocationBrandImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [associatedConnnectUser, setAssociatedConnnectUser] = useState(null);
  const [userData, setUserData] = useState([]);
  const [editorValue, setEditorValue] = useState('');

  const getEntityData = () => {
    if (locationId) {
      setLoading(true);
      postDataRequestPublic(config.baseURLApi, {
        query: Entity,
        variables: { entityid: locationId },
      })
        .then((res) => {
          if (res?.data?.entity) {
            setLocationBrandImage(res?.data?.entity?.logourl);
            setFavIcon(res?.data?.entity?.favicon);
            setBusinessName(res?.data?.entity?.businessname || res?.data?.entity?.brandname);
            if (handleUndefined(res?.data?.entity?.parententityid)) {
              postDataRequestPublic(config.baseURLApi, {
                query: BrandSettingByLocation,
                variables: {
                  locationid: handleUndefined(res?.data?.entity?.parententityid),
                },
              }).then((r) => setBrandImage(r?.data?.brandSettingByLocation?.logourl));
            }
          }
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getEntityData();
    }
    return () => {
      isMounted = false;
    };
  }, [locationId]);

  const adminData = () => {
    setLoading(true);
    if (businessId) {
      postDataRequestPublic(config.baseURLApi, {
        query: Entity,
        variables: {
          entityid: businessId,
        },
      }).then((res) => {
        const result = res.data.entity;
        if (res?.errors?.[0]?.message) {
          showNotification(res?.errors[0]?.message, 'error');
          setLoading(false);
        }
        if (result) {
          setEntityData(result);
          setLoading(false);
        };
      });
    }
  };

  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const getConversation = (pageNumber) => {
    setLoading(true);
    if (ticketId) {
      postDataRequestPublic(config.baseURLApi, {
        query: Conversationlistbyticket,
        variables: {
          ticketid: ticketId,
          pagenumber: pageNumber,
          pagesize: 6,
        },
      }).then((res) => {
        const result = res.data.conversationlistbyticket;
        if (res?.errors?.[0]?.message) {
          showNotification(res?.errors[0]?.message, 'error');
          setLoading(false);
          return;
        }
        if (result?.count > 0) {

          const mergeConversations = (oldList, newList) => {
            const combinedList = [...oldList];
            newList.forEach(newItem => {
              if (!oldList.some(item =>
                (item.fromadmin === newItem?.fromadmin) &&
                item.conversation.trim() === newItem?.conversation?.trim()
              )) {
                combinedList.push(newItem);
              }
            });
            return combinedList;
          };
          setCoversationList(prevList => mergeConversations(prevList, result?.rows));

          setHasMore(result.rows.length === 6);
          scrollToBottom();
        } else {
          setHasMore(false);
        }
        setLoading(false);
      });
    }
  };

  const renderPreview = (attachmentArr) => {
    const parsedArr = JSON.parse(attachmentArr);

    if (Array.isArray(parsedArr)) {
      return parsedArr.map((url, index) => {
        const fileType = url?.split('.').pop().toLowerCase();
        const fileName = url?.split('/')?.[5];


        if (['jpg', 'jpeg', 'png', 'gif'].includes(fileType)) {
          return (
            <div className={classes.renderTag}>
              <center>
                <Link href={url} target="_blank" className={classes.fileText}>{fileName}</Link>
              </center>
              <img
                key={index}
                src={url}
                alt={fileName || "Preview"}
                className={classes.renderImg}
              />
            </div>
          );
        } else if (fileType === 'pdf') {
          return (
            <div className={classes.renderTag}>
              <center>
                <Link href={url} target="_blank" className={classes.fileText}>{fileName}</Link>
              </center>
              <embed
                key={index}
                src={url}
                type="application/pdf"
                width="90%"
                height="200px"
                className={classes.renderEmbed}
              />
            </div>
          );
        } else if (fileType === 'txt') {
          return (
            <a
              key={index}
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.renderA}
            >
              {fileName}
            </a>
          );
        } else {
          return (
            <a
              key={index}
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.renderA}
            >
              {fileName}
            </a>
          );
        }
      });
    }

    return null;
  };

  const getTicketData = () => {
    setLoading(true);
    if (ticketId) {
      postDataRequestPublic(config.baseURLApi, {
        query: Raiseticketbyticketid,
        variables: {
          ticketid: ticketId,
        },
      }).then((res) => {
        const result = res.data.raiseticketbyticketid;
        if (res?.errors?.[0]?.message) {
          showNotification(res?.errors[0]?.message, 'error');
          setLoading(false);
        }
        if (result) {
          setTicketData(result);
          setAssociatedConnnectUser(result?.assignee);
          setLoading(false);
        };
      });
    }
  };

  const getHistory = () => {
    setLoading(true);
    if (ticketId) {
      postDataRequestPublic(config.baseURLApi, {
        query: Ticketstatushistory,
        variables: {
          ticketid: ticketId,
        },
      }).then((res) => {
        const result = res?.data?.ticketstatushistory;
        if (result) {
          setHistoryList(result);
          setLoading(false);
        };
      });
    }
  };

  const createCoversation = () => {
    if (editorValue) {
      setLoading(true);
      postDataRequestPublic(config.baseURLApi, {
        query: CreateConversation,
        variables: {
          ticketid: ticketId,
          contactid: contactId,
          locationid: locationId,
          businessid: businessId,
          conversation: editorValue,
          fromadmin: userType === 'true' ? true : false,
          attachments: files?.length > 0 ? JSON.stringify(files) : '',
        },
      }).then((res) => {
        const result = res?.data?.createConversation;
        if (res?.errors?.[0]?.message) {
          showNotification(res.errors[0].message, 'error');
          setLoading(false);
          setEditorValue('');
          setFiles([]);
        }
        if (result?.ticketid) {
          showNotification("Message Sent Successfully", 'success');
          setLoading(false);
          getConversation(page);
          scrollToBottom();
          setEditorValue('');
          setFiles([])
        };
      });
    } else {
      showNotification('Description is required', 'error');
    }
  };

  const httpLink = createUploadLink({
    uri: config.contactApi,
    headers: {
      authorization: config.staticToken || null,
      'Apollo-Require-Preflight': 'true',
    },
  });

  const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        authorization: config.staticToken || null,
        'Apollo-Require-Preflight': 'true',
      },
    });

    return forward(operation);
  });

  const client = new ApolloClient({
    link: authMiddleware.concat(httpLink),
    cache: new InMemoryCache(),
  });

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (ticketId) {
        getConversation(1);
        getHistory();
        getTicketData();
      }
    }
    return () => {
      isMounted = false;
    };
  }, [ticketId]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (locationId) {
        setLoading(true);
        postDataRequestPublic(config.baseURLApi, {
          query: Usersbytype,
          variables: {
            locationid: locationId,
            businessid: businessId,
          },
        }).then((json) => {
          if (json?.errors && json?.errors[0]?.message) {
            showNotification(json.errors[0].message, 'error');
          } else if (json?.data?.usersbytype) {
            setUserData(json.data.usersbytype);
          }
          setLoading(false);
        });
      }
    }
    return () => {
      isMounted = false;
    };
  }, [locationId, businessId]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (businessId) {
        adminData();
      }
    }
    return () => {
      isMounted = false;
    };
  }, [businessId]);

  return (
    <>
      {loading ? < LoadingPage /> : ''}
      <div>
        <Box className={classes.ticketHead}>
          <Typography className={classes.ticketHeadText}># {ticketData?.subject}</Typography>
        </Box>
        <Box className={classes.mainGrid}>
          <Box className={classes.grid1}>
            <div ref={messagesEndRef} className={classes.msgBox} id="scrollableDiv">
              <InfiniteScroll
                dataLength={conversationList.length}
                useWindow={false}
                next={() => {
                  if (!loading && hasMore) {
                    getConversation(page + 1);
                    setPage(page + 1);
                  }
                }}
                hasMore={hasMore}
                loader={conversationList.length > 0 ? <p>Loading...</p> : ''}
                scrollableTarget="scrollableDiv"
              >
                {conversationList.map((detail, index) => (
                  <Box key={index} className={classes.msgWrapper}>
                    {(detail?.fromadmin === true && userType === 'true') ? (
                      <Box className={classes.replyWrapper}>
                        <Box className={classes.reply}>
                          <Typography
                            className={classes.replyText}
                            dangerouslySetInnerHTML={{ __html: detail?.conversation }}
                          />
                          <Box className={classes.attachmentTextBox}>
                            {renderPreview(detail?.attachments)}
                          </Box>
                        </Box>
                        <Avatar className={classes.adminAvatar}>{`${handleUndefined(entityData?.firstname?.charAt(0) || '')}${handleUndefined(entityData?.lastname?.charAt(0) || '')}`}</Avatar>
                      </Box>
                    ) : (detail?.fromadmin === false && userType === 'false') ? (
                      <>
                        <Box className={classes.replyWrapper}>
                          <Box className={classes.reply}>
                            <Typography
                              className={classes.replyText}
                              dangerouslySetInnerHTML={{ __html: detail?.conversation }}
                            />
                            <Box className={classes.attachmentTextBox}>
                              {renderPreview(detail?.attachments)}
                            </Box>
                          </Box>
                          <Avatar className={classes.adminAvatar}>{`${handleUndefined(ticketData?.firstname?.charAt(0) || '')}${handleUndefined(ticketData?.lastname?.charAt(0) || '')}`}</Avatar>
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box className={classes.msgStartWrapper}>
                          <Avatar className={classes.userAvatar}>{(userType === 'true') ? (`${handleUndefined(ticketData?.firstname?.charAt(0) || '')}${handleUndefined(ticketData?.lastname?.charAt(0) || '')}`) : (`${handleUndefined(entityData?.firstname?.charAt(0) || '')}${handleUndefined(entityData?.lastname?.charAt(0) || '')}`)}</Avatar>
                          <Box className={classes.msg}>
                            <Typography
                              className={classes.msgText}
                              dangerouslySetInnerHTML={{ __html: detail?.conversation }}
                            />
                            {handleUndefined(detail?.attachments) ? (
                              <Box className={classes.attachmentTextBox}>
                                {renderPreview(detail?.attachments)}
                              </Box>
                            ) : ''}
                          </Box>
                        </Box>
                      </>
                    )}
                  </Box>
                ))}
              </InfiniteScroll>
            </div>
            <Box className={classes.replyBox}>
              <InputBase
                value={editorValue}
                disabled={historyList.at(-1)?.status === TicketStatusByValue.Resolved && userType === 'false'}
                placeholder="Write a Email.."
                multiline
                fullWidth
                rows={9}
                onChange={(e) => {
                  setEditorValue(e.target.value);
                }}
                className={classes.multilineField}
              />
              <Box className={classes.featureBox} style={{ justifyContent: 'space-between' }}>
                <div className={classes.alignContant}>
                  <Tooltip title="Add Attachment">
                    <IconButton
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => {
                        setDropOpen(true);
                        setFiles([]);
                      }}
                    >
                      <Attachment className={classes.themeIcon} />
                    </IconButton>
                  </Tooltip>
                </div>
                <div>
                  <Tooltip title="Send">
                    <IconButton className={classes.sendBtn} onClick={() => createCoversation()}>
                      <Send className={classes.sendIcon} />
                    </IconButton>
                  </Tooltip>
                </div>
              </Box>
              {files?.length > 0 ? (
                <TicketAttchments
                  attchedFiles={files}
                  setAttachedFiles={setFiles}
                  isConversation
                />
              ) : ''}
              <Dialog open={dropOpen} onClose={() => setDropOpen(false)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Add Attachment</DialogTitle>
                <DialogContent>
                  <Box className={classes.Dropzone}>
                    <Dropzone
                      disabled={fileLoader}
                      onDrop={async (acceptedFiles) => {
                        if (acceptedFiles.length > 0) {
                          const uploadedFileUrls = [];
                          for (const file of acceptedFiles) {
                            try {
                              setFileLoader(true);
                              const res = await client.mutate({
                                mutation: UploadImage,
                                variables: { images: file },
                              });
                              const fileUrl = res?.data?.uploadimg?.images;
                              if (fileUrl) {
                                uploadedFileUrls.push(fileUrl);
                                setFileLoader(false);
                              }
                            } catch (error) {
                              setFileLoader(false);
                              showNotification('Error uploading file', 'error');
                            }
                          }
                          if (uploadedFileUrls.length > 0) {
                            setFiles((prev) => [...prev, ...uploadedFileUrls]);
                            showNotification('Files uploaded successfully', 'success');
                            setDropOpen(false);
                          }
                        }
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: 'dropzone' })}>
                          <input {...getInputProps()} />
                          <CloudUpload className={classes.placeholderIcon} />
                          <br />
                          {fileLoader ? (
                            <p style={{ marginTop: 10 }}>
                              Uploading...
                            </p>
                          ) : (<p style={{ marginTop: 10 }}>
                            Upload Picture
                          </p>)}
                        </div>
                      )}
                    </Dropzone>
                    <FormHelperText>
                      <Typography variant="body2">
                        <center>
                          {' '}
                          *File size should not
                          greater than 5MB!
                        </center>
                      </Typography>
                    </FormHelperText>
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={<Close />}
                    onClick={() => {
                      setDropOpen(false);
                    }}
                    color="primary"
                  >
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          </Box>
          <Box className={classes.grid2}>
            <Typography className={classes.requester}><strong>Requester</strong></Typography>
            <hr />
            <Box className={classes.detailWrapper}>
              <Box>
                <Box className={classes.assignName}>
                  <Avatar className={classes.avatar}>{handleUndefined(ticketData?.firstname)?.charAt(0)}</Avatar>
                  <Typography className={classes.userName}>{`${handleUndefined(ticketData?.firstname) || ''} ${handleUndefined(ticketData?.lastname) || ''}`}</Typography>
                </Box>
                <hr className={classes.nameHr} />
                <Box className={classes.detailBox}>
                  <Typography className={classes.detailHead}>
                    Email:
                  </Typography>
                  <Typography className={classes.detailText}>
                    {ticketData?.primaryemail}
                  </Typography>
                </Box>
                <Box className={classes.detailBox}>
                  <Typography className={classes.detailHead}>
                    Requested At:
                  </Typography>
                  <Typography className={classes.detailText}>
                    {moment(parseInt(ticketData?.createdAt, 10)).format('DD, MMMM, YYYY')}
                  </Typography>
                </Box>
                <Box className={classes.assigneeBox}>
                  <Typography color="primary">Assignee</Typography>
                  <hr className={classes.assigneeHr} />
                  <br />
                  <Autocomplete
                    fullWidth
                    disabled={userType === 'false'}
                    size="small"
                    id="assign-to-autocomplete"
                    value={userData?.length > 0 ? (userData?.find((u) => u?.userid === associatedConnnectUser)) : {}}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setAssociatedConnnectUser(newValue?.userid || '');
                        if (locationId && businessId) {
                          setLoading(true);
                          const assigneeJson = [{ id: newValue?.userid, name: `${newValue.firstname} ${newValue.lastname || ''}` }];
                          postDataRequestPublic(config.baseURLApi, {
                            query: UpdateTicket,
                            variables: {
                              locationid: locationId,
                              businessid: businessId,
                              updatedby: businessId,
                              ticketid: ticketId,
                              assignee: newValue ? JSON.stringify(assigneeJson) : '',
                            },
                          }).then((response) => {
                            if (response?.errors?.[0]?.message) {
                              showNotification(response.errors[0].message, 'error');
                              setLoading(false);
                            }
                            if (response?.data?.updateTicket) {
                              showNotification('Status Updated Successfully', 'success');
                              setLoading(false);
                            }
                          });
                        }
                      }
                    }}
                    options={userData}
                    getOptionLabel={(option) => `${handleUndefined(option?.firstname)} ${handleUndefined(option?.lastname)}`}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Assign to"
                        variant="outlined"
                      />
                    )}
                  />
                </Box>
              </Box>
              <br />
              <Box className={classes.historyBox}>
                <Timeline>
                  {historyList?.map((h) => (
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <ListItemText
                        className={classes.listItemText}
                        primary={TicketStatus[h?.status]}
                        secondary={moment(h?.updatedAt?.split(' +')[0], 'YYYY-MM-DD HH:mm:ss').format('DD, MMMM, YYYY')}
                      />
                    </TimelineItem>
                  ))}
                </Timeline>
              </Box>
            </Box>
          </Box>
        </Box>
      </div>
      <BusinessFavicon
        name={businessName || "Bixpand"}
        logo={favIcon || locationBrandImage || brandImage || "https://storage.googleapis.com/bixpand-staging/WebsiteImages/favicon-16x16.png"}
      />
    </>
  )
}

export default TicketHistory