/* eslint-disable react/jsx-indent */
import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { ConfirmationNumber, ReceiptOutlined } from '@material-ui/icons';
import { getDataRequest } from '../../common/commonfunctions';
import config from '../../config';
import { showNotification } from '../Notification/ToastifyNotifications';

const businessId = JSON.parse(localStorage.getItem('business_Id'));
const checkPlans = async () => {
  if (businessId) {
    await getDataRequest(
      `${config.payment}v1/payments/send-plan-inquiry-email/${businessId}`,
    ).then((res) => {
      if (res?.data === true) {
        showNotification('Check your email for exclusive offer', 'success');
      }
    });
  }
};

const SvgStyle = {
  marginRight: '8%',
  verticalAlign: 'bottom',
  fontSize: '20px',
  '@media only screen and(maxWidth: 1420px)': {
    fontSize: 20,
  },
};
const EmailStyle = {
  marginRight: '8%',
  verticalAlign: 'bottom',
  fontSize: '26px',
  '@media only screen and(maxWidth: 1420px)': {
    fontSize: 26,
  },
};
const SvgVisitorStyle = {
  marginRight: '9%',
  verticalAlign: 'bottom',
  fontSize: '20px',
  '@media only screen and(maxWidth: 1420px)': {
    fontSize: 20,
  },
};

const OrderStyle = {
  marginRight: '8%',
  verticalAlign: 'bottom',
  fontSize: '20px',
  color: '#ffffff',
  marginTop: 0,
  width: 20,
  height: 20,
};

const structure = [
  {
    id: 1,
    label: 'Home',
    link: '/app/home/business',
    icon: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_101.svg" alt="Sidebar" />,
    iconFill: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_2.svg" alt="Sidebar" />,
    children: [
      // {
      //   label: (
      //     <>
      //       <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_3.svg" alt="Sidebar" style={SvgStyle} />
      //       Activity
      //     </>
      //   ),
      //   link: '/app/home/myworkspace',
      // },
      // {
      //   label: (
      //     <>
      //       <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_4.svg" alt="Sidebar" style={SvgStyle} />
      //       Team
      //     </>
      //   ),
      //   link: '/app/home/team',
      //   menuid: 3,
      //   roles: [1, 2, 3, 4, 5],
      // },
      {
        label: (
          <>
            <div onClick={() => checkPlans()}>
              <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_65.svg" alt="Sidebar" style={SvgStyle} />
              <span onClick={() => checkPlans()}>Plans</span>
            </div>
          </>
        ),
        link: '/app/plans',
        menuid: 4,
        roles: [1, 2, 3, 4, 5],
      },
      {
        link: '/app/setting',
        label: (<>
          <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_66.svg" alt="Sidebar" style={SvgStyle} />
          Settings
        </>),
      },
    ],
  },
  {
    id: 2,
    label: 'Leads',
    link: '/app/lead-central/workflow-automation',
    icon: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_7.svg" alt="Sidebar" />,
    iconFill: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_8.svg" alt="Sidebar" />,
    children: [
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_69.svg" alt="Sidebar" style={SvgStyle} />
            Import Leads
          </>
        ),
        link: '/app/lead-central/import-lead',
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_610.svg" alt="Sidebar" style={SvgStyle} />
            Workflows
          </>
        ),
        link: '/app/lead-central/workflow-automation',
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_611.svg" alt="Sidebar" style={SvgStyle} />
            Appointments
          </>
        ),
        link: '/app/lead-central/appointment',
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_612.svg" alt="Sidebar" style={SvgStyle} />
            LeadGen Forms
          </>
        ),
        link: '/app/lead-central/forms',
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_614.svg" alt="Sidebar" style={SvgStyle} />
            Inquiry Forms
          </>
        ),
        link: '/app/lead-central/inquiry-forms',
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/visitor-dashboard.png" width='22px' alt="Sidebar" style={SvgVisitorStyle} />
            Visitor Dashboard
          </>
        ),
        link: '/app/lead-central/visitors',
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_615.svg" alt="Sidebar" style={SvgStyle} />
            Events
          </>
        ),
        link: '/app/lead-central/event',
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_613.svg" alt="Sidebar" style={SvgStyle} />
            Live Chat
          </>
        ),
        link: '/app/lead-central/live-chat',
      },
    ],
  },
  {
    id: 3,
    label: 'CRM',
    link: '/app/contact-central',
    icon: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_16.svg" alt="Sidebar" />,
    iconFill: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_17.svg" alt="Sidebar" />,
    children: [
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_618.svg" alt="Sidebar" style={SvgStyle} />
            Contacts
          </>
        ),
        link: '/app/contact-central',
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_619.svg" alt="Sidebar" style={SvgStyle} />
            Accounts
          </>
        ),
        link: '/app/organizations',
      },
      {
        label: (
          <>
            <SearchIcon style={EmailStyle} />
            Email Finder
          </>
        ),
        link: '/app/email-find',
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_620.svg" alt="Sidebar" style={SvgStyle} />
            Documents
          </>
        ),
        link: '/app/documents',
      },
      {
        label: (<>
          <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_36.svg" alt="Sidebar" style={SvgStyle} />
          Deals
        </>),
        link: '/app/deal-central',
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_34.svg" alt="Sidebar" style={SvgStyle} />
            Tasks
          </>
        ),
        link: '/app/task',
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_621.svg" alt="Sidebar" style={SvgStyle} />
            Inbox
          </>
        ),
        link: '/app/home/Inbox',
        menuid: 3,
        roles: [1, 2, 3, 4, 5],
      },
      {
        label: (
          <>
            <ConfirmationNumber style={OrderStyle} />
            Service Desk
          </>
        ),
        link: '/app/tickets',
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_35.svg" alt="Sidebar" style={SvgStyle} />
            Calls
          </>
        ),
        link: '/app/call',
      },
    ],
  },
  {
    id: 4,
    label: 'Marketing',
    link: '/app/lead-central/email-marketing',
    icon: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_222.svg" alt="Sidebar" />,
    iconFill: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_223.svg" alt="Sidebar" />,
    children: [
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_624.svg" alt="Sidebar" style={SvgStyle} />
            News Letter
          </>
        ),
        link: '/app/marketing-central/news-letter',
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_625.svg" alt="Sidebar" style={SvgStyle} />
            Social Media
          </>
        ),
        link: '/app/marketing-central/social-media-list',
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_626.svg" alt="Sidebar" style={SvgStyle} />
            Email Marketing
          </>
        ),
        link: '/app/lead-central/email-marketing',
      },
      // {
      //   label: (
      //     <>
      //       <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_627.svg" alt="Sidebar" style={SvgStyle} />
      //       Template Catalog
      //     </>
      //   ),
      //   link: '/app/lead-central/email-Templates',
      // },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_628.svg" alt="Sidebar" style={SvgStyle} />
            Text Marketing
          </>
        ),
        link: '/app/lead-central/text-marketing',
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_629.svg" alt="Sidebar" style={SvgStyle} />
            Pages
          </>
        ),
        link: '/app/lead-central/content-marketing',
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_630.svg" alt="Sidebar" style={SvgStyle} />
            Review Marketing
          </>
        ),
        link: '/app/lead-central/review-marketing',
      },
    ],
  },
  {
    id: 5,
    label: 'Sales',
    link: '/app/deal-central',
    icon: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_31.svg" alt="Sidebar" />,
    iconFill: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_32.svg" alt="Sidebar" />,
    children: [
      // {
      //   label: (
      //     <>
      //       <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_33.svg" alt="Sidebar" style={SvgStyle} />
      //       BixBox
      //     </>
      //   ),
      //   link: '/app/chat',
      // },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_637.svg.svg" alt="Sidebar" style={SvgStyle} />
            Product & Service
          </>
        ),
        link: '/app/product-service',
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_638.svg.svg" alt="Sidebar" style={SvgStyle} />
            Payment & Invoice
          </>
        ),
        link: '/app/paymentinvoice',
      },
      {
        label: (
          <>
            <ReceiptOutlined style={OrderStyle} />
            Sales Orders
          </>
        ),
        link: '/app/sales-order',
      },
    ],
  },
  {
    id: 6,
    label: 'Reputation',
    link: '/app/reputation-central/monitor-reputation',
    icon: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_39.svg" alt="Sidebar" />,
    iconFill: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_40.svg" alt="Sidebar" />,
    children: [
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_641.svg" alt="Sidebar" style={SvgStyle} />
            Monitor Reputation
          </>
        ),
        link: '/app/reputation-central/monitor-reputation',
      },
      // {
      //   label: (
      //     <>
      //       <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_42.svg" alt="Sidebar" style={SvgStyle} />
      //       Manage Customers
      //     </>
      //   ),
      //   link: '/app/reputation-central/manage-customer',
      // },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_43.svg" alt="Sidebar" style={SvgStyle} />
            Review Campaigns
          </>
        ),
        link: '/app/reputation-central/review-campaigns',
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_44.svg" alt="Sidebar" style={SvgStyle} />
            Launch Campaign
          </>
        ),
        link: '/app/reputation-central/launch-campaign',
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_46.svg" alt="Sidebar" style={SvgStyle} />
            Quick Reviews
          </>
        ),
        link: '/app/reputation-central/quick-reviews',
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_47.svg" alt="Sidebar" style={SvgStyle} />
            Touchless Reviews
          </>
        ),
        link: '/app/reputation-central/touchless-reviews',
      },
    ],
  },
  {
    id: 7,
    label: 'Insights',
    link: '/app/insights-central',
    icon: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_448.svg" alt="Sidebar" />,
    iconFill: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_449.svg" alt="Sidebar" />,
    children: [
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_651.svg" alt="Sidebar" style={SvgStyle} />
            Marketing Dashboard
          </>
        ),
        link: '/app/insights-central/marketing-dashboard',
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_650.svg" alt="Sidebar" style={SvgStyle} />
            Contact Dashboard
          </>
        ),
        link: '/app/insights-central/contact-dashboard',
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_652.svg" alt="Sidebar" style={SvgStyle} />
            Email Report
          </>
        ),
        link: '/app/insights-central/email-report',
      },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_653.svg" alt="Sidebar" style={SvgStyle} />
            Sales Dashboard
          </>
        ),
        link: '/app/insights-central/sales-dashboard',
      },
      // {
      //   label: (
      //     <>
      //       <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_654.svg" alt="Sidebar" style={SvgStyle} />
      //       Reputation Dashboard
      //     </>
      //   ),
      //   link: '/app/insights-central/reputation-dashboard',
      // },
      {
        label: (
          <>
            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_655.svg" alt="Sidebar" style={SvgStyle} />
            Payment Dashboard
          </>
        ),
        link: '/app/insights-central/payment-dashboard',
      },
    ],
  },
  { id: 8, type: 'divider' },
  {
    id: 9,
    label: 'Professional Services',
    link: '/app/HelpAdvisors',
    icon: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_56.svg" alt="Sidebar" />,
    iconFill: <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/sidebar_57.svg" alt="Sidebar" />,
  },
];

export default structure;
