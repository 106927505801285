import { makeStyles } from '@material-ui/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';

export default makeStyles((theme) => ({
  snackbarStyleViaContentProps: {
    backgroundColor: '#42ba96',
    cursor: 'pointer',
  },
  notification: {
    transform: 'rotate(30deg)',
    fontSize: 26,
    color: '#005eb2',
    marginRight: '12px',
  },
  addMenuItem: {
    background: '#8c8e93',
  },
  openBox: {
    background: '#071828',
    padding: '18px',
  },
  closeBox: {
    padding: '18px',
    textAlign: 'left',
    justifyContent: 'center',
  },
  lightIcon: {
    color: '#005eb2',
    fontSize: 26,
  },
  darkIcon: {
    color: 'white',
    fontSize: 26,
  },
  thmCls: {
    backgroundColor: localStorage.getItem('theme') === 'dark' ? 'red' : 'green',
    width: '100%',
    display: 'inline-block',
  },
  iconTheme: {
    color: localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2',
  },
  call: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  videoResponsive: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 'auto',
    },
  },
  howToLocation: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
    },
  },
  help: {
    marginTop: '7px',
  },
  headerLogo: {
    fontSize: '12px',
    color: '#c0c6ff',
    textDecoration: 'none',
  },
  selectLocationLabel: {
    cursor: 'pointer',
    fontSize: 14,
    color: localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2',
    [theme.breakpoints.down('xs')]: {
      fontSize: 0,
    },
  },
  menu: {
    marginTop: '55px',
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  userIcon: {
    color: '#005eb2 !important',
  },
  addIcon: {
    marginLeft: '15px',
    paddingBottom: '0px',
  },
  menuItem: {
    paddingBottom: '12px',
    '&:focus': {
      backgroundColor: localStorage.getItem('theme') === 'dark' ? '#b5bad1' : '#dcdfe5',
    },
  },
  linkText: {
    display: 'flex',
    alignItems: 'center',
    gap: 15,
    color: localStorage.getItem('theme') === 'dark' ? '#ffff' : '#005eb2',
  },
  profileMenuText: {
    color: localStorage.getItem('theme') === 'dark' ? '#ffff' : '#005eb2',
  },
  marginText: {
    marginTop: '0.275rem',
    marginRight: '10px',
    color: localStorage.getItem('theme') === 'dark' ? '#ffff' : '#005eb2',
  },
  dialog: {
    backgroundColor: '#f6f7ff',
  },
  margin: {
    backgroundColor: '#fff',
    padding: `${theme.spacing(1)}px !important`,
  },
  navPadding: {
    paddingTop: `${theme.spacing(2)}px !important`,
    paddingBottom: '12px !important',
  },
  root: {
    width: '100%',
    maxWidth: 360,
  },
  taskTitle: {
    fontSize: '18px',
  },
  task: {
    padding: '10px',
  },
  form: {
    flexGrow: 1,
    width: '80%',
  },
  field: {
    width: '100%',
  },
  divider: {
    fontSize: '2rem',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  headerMenuLink: {
    color: 'white',
    marginTop: 8,
    textDecoration: 'none',
    '&:hover': {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 0,
      minWidth: 30,
      marginLeft: 0,
    },
  },
  table: {
    minWidth: 80,
    color: 'white',
    marginLeft: 40,
    [theme.breakpoints.down('xs')]: {
      fontSize: 0,
      minWidth: 30,
      marginLeft: 0,
    },
  },
  logo: {
    height: 40,
    [theme.breakpoints.down('sm')]: {
      height: 30,
      width: 30,
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  logotype: {
    color:
      theme.palette.type === 'dark'
        ? theme.palette.primary.main
        : 'white',
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5),
    fontWeight: 500,
    fontSize: 18,
    overflowX: 'auto',

    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      minWidth: 50,
    },
    transition: theme.transitions.create(['margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  brandLogo: {
    textDecoration: 'none',
    width: 'auto',
    height: '2.5rem',
    aspectRatio: 'auto',
    paddingTop: '1px',
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
      height: '2rem',
    },
  },
  appBar: {
    width: '100%',
    zIndex: theme.zIndex.drawer + 100,
    borderTop: '2px solid',
    borderColor: '#ccc',
    transition: theme.transitions.create(['margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  toolbar: {
    paddingLeft: 0,
    paddingRight: theme.spacing(2.5),
    minHeight: '46px',
    backgroundColor: localStorage.getItem('theme') === 'dark' ? '#333540' : '#ffff',
  },
  hide: {
    display: 'none',
  },
  grow: {
    flexGrow: 1,
  },
  search: {
    position: 'relative',
    borderRadius: 25,
    paddingLeft: theme.spacing(2.5),
    width: 36,
    backgroundColor: alpha(theme.palette.common.black, 0),
    transition: theme.transitions.create(['background-color', 'width']),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: alpha(theme.palette.common.black, 0.08),
    },
  },
  searchFocused: {
    backgroundColor: alpha(theme.palette.common.black, 0.08),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 250,
    },
  },
  searchIcon: {
    width: 36,
    right: 0,
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: theme.transitions.create('right'),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  searchIconOpened: {
    right: theme.spacing(1.25),
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    height: 36,
    padding: 0,
    paddingRight: 36 + theme.spacing(1.25),
    width: '100%',
  },
  messageContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerMenu: {
    marginTop: theme.spacing(7),
  },
  headerMenuNotifications: {
    marginTop: theme.spacing(6),
    padding: 20,
  },
  headerMenuList: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerMenuItem: {
    '&:hover, &:focus': {
      backgroundColor: '#cbdceb',
      color: 'white',
    },
  },
  headerMenuButton: {
    paddingLeft: '0px',
    padding: `0px ${theme.spacing(0.5)}px 0px ${theme.spacing(0.5)}px`,
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(0),
      padding: `0px ${theme.spacing(0.5)}px 0px ${theme.spacing(0.5)}px`,
    },
    [theme.breakpoints.down(400)]: {
      marginLeft: '-4px',
    },
  },
  headerMenuButtonCollapse: {
    marginRight: theme.spacing(2),
  },
  userName: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 8,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  capitalText: {
    textTransform: 'capitalize',
    [theme.breakpoints.down(400)]: {
      display: 'none',
    },
  },
  flexItems: {
    display: 'flex',
    alignItems: 'center',
  },
  headerProfile: {
    fontSize: 22,
    color: '#005eb2',
    fontWeight: 'bold',
  },
  headerIcon: {
    textDecoration: 'none',
    fontSize: 25,
    color: 'rgba(255, 255, 255, 0.35)',
  },
  count: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    backgroundColor: 'red',
    fontSize: 10,
    marginTop: 8,
    marginLeft: 31,
  },
  notificationCount: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: 'red',
    fontSize: 10,
    marginTop: 30,
    marginLeft: 8,
    color: 'white',
    cursor: 'pointer',
  },
  headerIconCollapse: {
    color:
      theme.palette.type === 'dark'
        ? theme.palette.primary.main
        : theme.palette.primary.main,
  },
  profileMenu: {
    minWidth: 265,
  },
  profileMenuNotification: {
    width: '50ch',
    maxHeight: 120 * 4.5,
  },
  notificationTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  notificationClose: {
    marginRight: 15,
    cursor: 'pointer',
  },
  notificationList: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textItems: {
    display: 'flex',
    alignItems: 'center',
  },
  profileMenuUserNotification: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  profileMenuUser: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  profileMenuItem: {
    color: 'black',
  },
  profileMenuIcon: {
    marginRight: theme.spacing(2),
    color: 'black',
  },
  profileIcon: {
    marginRight: theme.spacing(2),
    color: localStorage.getItem('theme') === 'dark' ? '#ffff' : '#005eb2',
  },
  pro: {
    color: localStorage.getItem('theme') === 'dark' ? '#ffff' : 'black',
  },
  proSignout: {
    color: localStorage.getItem('theme') === 'dark' ? '#ffff' : '#005eb2',
  },
  profileMenuLink: {
    fontSize: 16,
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  messageNotification: {
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    '&:hover, &:focus': {
      backgroundColor: theme.palette.background.light,
    },
  },
  messageNotificationSide: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: theme.spacing(2),
  },
  messageNotificationBodySide: {
    alignItems: 'flex-start',
    marginRight: 0,
  },
  sendMessageButton: {
    margin: theme.spacing(4),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textTransform: 'none',
  },

  sendButtonIcon: {
    marginLeft: theme.spacing(2),
  },
  LocationIcon: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 0,
    },
  },
  popuptext: {
    fontWeight: 400,
    fontSize: 13,
  },
  popupHeader: {
    fontWeight: 700,
    fontSize: 17,
  },
  popupHeader1: {
    fontWeight: 700,
    fontSize: 17,
    paddingLeft: 6,
  },
  consultantText: {
    [theme.breakpoints.down(400)]: {
      fontSize: '12px',
      marginTop: '5px',
    },
  },
  consultantIcon: {
    fontSize: '33px',
    [theme.breakpoints.down(400)]: {
      fontSize: '33px',
    },
  },
  calendericon: {
    width: 33,
    height: 33,
    [theme.breakpoints.down(400)]: {
      width: 33,
      height: 33,
    },
  },
  callerImage: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    marginTop: theme.spacing(1),
    fontSize: 32,
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      padding: '5px',
      width: theme.spacing(14),
      height: theme.spacing(14),
    },
  },
  close: {
    cursor: 'pointer',
  },
  dangerButton: {
    backgroundColor: '#d9534f',
  },
  chatIcon: {
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      marginRight: 10,
    },
  },
  helpIcon: {
    marginRight: '7px',
    [theme.breakpoints.down('xs')]: {
      marginRight: 10,
    },
  },
  moreVertIcon: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  chatIcons: {
    marginRight: 23,
    [theme.breakpoints.down(400)]: {
    },
  },
  favIcon: {
    margin: 'auto',
    marginTop: 5,
  },
  removeSpace: {
    paddingTop: '0px',
  },
  muiIcon: {
    marginBottom: '-4px',
    fontSize: '32px',
  },
  Refercode: {
    padding: 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    border: '2px solid #005eb2',
    borderRadius: 5,
    width: 200,
    backgroundColor: 'white',
  },
  Refer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  textRefer: {
    textAlign: 'center',
    width: 310,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  referDialog: {
    border: '5px solid #005eb2',
    borderRadius: 8,
    padding: 20,
  },
  referImg: {
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      marginRight: 10,
      display: 'none',
    },
  },
  refeerAnimation: {
    width: '230px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  userNote: {
    fontWeight: 500,
    fontSize: 15,
    padding: 0,
  },
}));
