/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-cycle */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import React, {
  useState, useEffect, Fragment, lazy, Suspense,
  useRef,
} from 'react';
import ApolloClient from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client';
import FacebookIcon from '@material-ui/icons/Facebook';
import SaveIcon from '@material-ui/icons/Save';
import {
  EditOutlined,
  ArrowBack as ArrowBackIcon,
  ExpandMore as ExpandMoreIcon,
  NavigateBefore,
  NavigateNext,
} from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import PublicIcon from '@material-ui/icons/Public';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import TwitterIcon from '@material-ui/icons/Twitter';
import RoomIcon from '@material-ui/icons/Room';
import YouTubeIcon from '@material-ui/icons/YouTube';
import { Prompt, Link } from 'react-router-dom';
import { InMemoryCache } from 'apollo-cache-inmemory';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { useStopwatch } from 'react-timer-hook';
import {
  Grid, Box, Paper, Tabs, Tab, Avatar as AvatarMui, Button, Tooltip, Fab,
  Accordion, AccordionSummary, AccordionDetails, Badge,
  DialogActions, DialogContent, Dialog, DialogTitle, DialogContentText,
  AppBar, Toolbar, IconButton, Link as Linked, List, TextField,
} from '@material-ui/core';
import {
  useSubscription, gql,
} from '@apollo/client';
import Avatar from 'react-avatar-edit';
import PhoneIcon from '@material-ui/icons/Phone';
import CallEndIcon from '@material-ui/icons/CallEnd';
import NotesIcon from '@material-ui/icons/Notes';
import AddAPhotoOutlinedIcon from '@material-ui/icons/AddAPhotoOutlined';
import { Route } from 'react-router';
import { Device } from '@twilio/voice-sdk';
import { toast } from 'react-toastify';
import { EntityType } from '../../common/const';
import {
  getBusinessData,
  useEntitiesState,
} from '../../context/EntityContext';
import { getToken, CheckCall } from '../../Api Mutation/TwilioApi';
import { showNotification } from '../../components/Notification/ToastifyNotifications';
import { handleUndefined, postDataRequest } from '../../common/commonfunctions';
import config from '../../config';
import { Planavailablebalance } from '../../Api Mutation/Payment';
import { LoadingPage } from '../LoadingPage/LoadingPage';
import Widget from '../../components/Widget/Widget';
import {
  SectionsByBusinessWithProperty, CreateContactNote, CallListByCallSid,
  UpdateContact,
  CallStatus,
  CheckStatus,
  CRMcontactByData,
  ContactAppointment,
} from '../../Api Mutation/Contact';
import { Typography } from '../../components/Wrappers/Wrappers';
import useStyles from './styles';
import { useUserState } from '../../context/UserContext';
import { Userprofilebyuser } from '../../Api Mutation/Login';
import LazyLoader from '../LoadingPage/LazyLoader';
import './style.css';
import Registerphone from '../Consult US/RegisterPhone';
import BixJoditEditor from '../../common/BixJoditEditor';
import { findEmail } from '../../Api Mutation/Campaign';

const EditOrganization = lazy(() => import('../Organizations/Edit-Organizations'));
const CustomFieldContact = lazy(() => import('./Components/CustomFieldsContact'));
const Deal = lazy(() => import('./Components/CRMDeal'));
const Avtivity = lazy(() => import('./Components/Activity'));
const Document = lazy(() => import('./Components/Document'));
const CRMnotes = lazy(() => import('./Components/CRMNotes'));
const CRMTask = lazy(() => import('./Components/CRMTask'));
const CRMMarketing = lazy(() => import('./Components/CRMMarketing'));
const CRMReview = lazy(() => import('./Components/CRMReview'));
const CRMAppointments = lazy(() => import('./Components/CRMAppointments'));
const CRMEmail = lazy(() => import('./Components/CRMContactEmail'));
const AddOrg = lazy(() => import('./AddOrg'));
const LeadInformation = lazy(() => import('./Components/LeadInformation'));
const AssignWorkflowContact = lazy(() => import('../../common/AssignWorkflowContact'));
const AppointmentChat = lazy(() => import('../ChatUI/Component/chatContent/AppointmentChat'));

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const apiHeader = {
  Authorization: `Bearer ${localStorage?.getItem('token')}`,
  businessid: JSON.parse(localStorage?.getItem('business_Id')),
  locationid: JSON.parse(localStorage?.getItem('location_Id')),
  'Apollo-Require-Preflight': 'true',
};

const httpLink = createUploadLink({
  uri: config.contactApi,
  headers: apiHeader,
});

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: apiHeader,
  });
  return forward(operation);
});

const client = new ApolloClient({
  link: authMiddleware.concat(httpLink),
  cache: new InMemoryCache(),
});

function MyStopwatch(onPhone) {
  const context = useEntitiesState();
  const { entities } = context;
  const {
    seconds,
    minutes,
    hours,
  } = useStopwatch({ autoStart: onPhone });

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getBusinessData(EntityType.Business, context.setEntities);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div>
      <div style={{
        display: 'flex', justifyContent: 'center', fontSize: '20px', fontWeight: 'normal', alignItems: 'center',
      }}
      >
        {entities?.businessData
          && entities?.businessData[0]?.iscallrecording === true ? (
          <FiberManualRecordIcon style={{ color: 'red' }} />
        ) : ''}
        &nbsp;
        <div>
          <span>{hours}</span>
          :
          <span>{minutes}</span>
          :
          <span>{seconds}</span>
        </div>
      </div>
    </div>
  );
}

export default function ContactCRMedit({
  history, editDeal, deleteDeal, addDeal, usageContactId, CRM,
  viewBox, isVisitor,
}) {
  const classes = useStyles();
  const userContext = useUserState();
  const { userInfo } = userContext;
  const { search } = history?.location;
  const joditRef = useRef(null);
  const params = new URLSearchParams(search);
  const contactId = params.get('id');
  const Id = params.get('state');
  const activityTab = params.get('activity');
  const [appointmentOpen, setAppointmentOpen] = useState(false);
  const [contact, setContactDetail] = useState([]);
  const locationId = JSON.parse(localStorage.getItem('location_Id'));
  const businessId = JSON.parse(localStorage.getItem('business_Id'));
  const [dataLoading, setDataLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [formDataChanged, setFormDataChanged] = useState(false);
  const [reportContact, setReportContact] = useState();
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [appointmentCount, setApointmentCount] = useState(0);
  const [state, setState] = useState({ preview: null });
  const [files, setFiles] = useState([]);
  const [callStatus, setCallStatus] = useState();
  const [disable, setDisable] = useState();
  const [sid, setSid] = useState();
  const [callNote, setCallNote] = useState();
  const [contactNumber, setContact] = useState('');
  const [openOrg, setOpenOrg] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [noteOpen, setNoteModal] = useState(false);
  const [editorValue, setEditorValue] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [onPhone, setOnPhone] = useState(false);
  const [twillioDevice, setTwillioDevice] = useState(null);
  const [sectionDataContact, setSectionDataContact] = useState([]);
  const [contactOpen, setContactOpen] = useState(false);
  const [noteSubject, setNoteSubject] = useState();

  const CustomScrollButton = (props) => {
    const { direction, className, ...other } = props;

    return (
      <IconButton {...other} className={className}>
        {direction === 'left' ? <NavigateBefore /> : <NavigateNext />}
      </IconButton>
    );
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleOpenOrg = () => {
    setOpenOrg(true);
  };
  const handleCloseOrg = () => {
    setOpenOrg(false);
  };
  const handleClickContact = () => {
    if (CRM) {
      history?.push(`/app/organization-edit?oid=${reportContact?.organization?.organizationid}`);
    } else {
      history?.push(`/app/contact-central-edit?id=${contactId}&&oid=${reportContact?.organization?.organizationid}`);
    }
    setContactOpen(true);
  };
  const handleOpenNote = async () => {
    if (sid?.data?.CallSid) {
      await postDataRequest(config.callApi, {
        query: CallListByCallSid,
        variables: {
          callsid: sid?.data?.CallSid,
        },
      }).then((res) => {
        setCallNote(res?.data?.calllistbycallsid);
      });
    }
    await setNoteModal(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getPropertiesByBusiness = async () => {
    if (businessId) {
      await postDataRequest(config.baseURLApi, {
        query: SectionsByBusinessWithProperty,
        variables: {
          businessid: businessId,
        },
      }).then((res) => {
        if (res?.data?.sectionsByBusinessWithProperty) {
          setSectionDataContact(res.data.sectionsByBusinessWithProperty.filter((item) => item.objecttype === 'contact'));
        }
      });
    }
  };

  function handleChange(event, newValue) {
    if (isVisitor) {
      history.push(`/app/lead-central/visitors?id=${contactId}`)
    } else {
      history.push(`/app/contact-central-edit?id=${contactId}`)
    }
    setActiveTab(newValue);
  }

  const contactReport = () => {
    setDataLoading(true);
    if (handleUndefined(contactId || Id || usageContactId)) {
      postDataRequest(config.baseURLApi, {
        query: CRMcontactByData,
        variables: {
          contactid: handleUndefined(contactId || Id || usageContactId),
        },
      }).then((res) => {
        const result = res?.data?.contact;
        if (res?.errors && res?.errors[0]?.message) {
          showNotification(res.errors[0].message, 'error');
        } else if (result) {
          const { contactid, phonenumber, contactavatar } = result;
          setContactDetail(contactid);
          setReportContact(result);
          setDisable(phonenumber);
          setState({ preview: contactavatar });
        }
        setDataLoading(false);
      });
    }
  };

  const getAppointmentData = async () => {
    await postDataRequest(config.baseURLApi, {
      query: ContactAppointment,
      variables: {
        locationid: locationId,
      },
    }).then((res) => {
      if (res?.data?.eventsbylocation) {
        setApointmentCount(res?.data?.eventsbylocation?.length);
      }
    });
  };

  const deleteOrg = async () => {
    await postDataRequest(config.baseURLApi, {
      query: UpdateContact,
      variables: {
        contactid: contactId || usageContactId,
        organizationid: null,
        updatedby: JSON.parse(localStorage.getItem('userid')),
      },
    }).then((res) => {
      if (res?.data?.updateContact) {
        showNotification('Association of contact & organization removed.', 'success');
        setOpenDelete(false);
        contactReport();
      }
    });
  };

  const onBeforeFileLoad = (image) => {
    const { target } = image;
    setFiles(target?.files[0]);
  };

  const updateContact = async (image) => {
    if (files.length === 0) {
      showNotification('Image is required', 'error');
    } else {
      const { target } = image;
      if (files?.size > 512000) {
        showNotification(' *Image size should not greater than 500KB!', 'error');
        target.value = '';
      } else {
        const i = state?.preview.indexOf('base64,');
        const buffer = Buffer.from(state?.preview.slice(i + 7), 'base64');
        const name = `${Math.random().toString(36).slice(-5)}.png`;
        const file = new File([buffer], name, { type: 'image/png' });
        setDataLoading(true);
        const result = await client.mutate({
          mutation: gql`${UpdateContact}`,
          variables: {
            contactid: contactId || Id || usageContactId,
            contactavatar: file,
            updatedby: JSON.parse(localStorage.getItem('userid')),
          },
        });
        if (result?.data?.updateContact) {
          showNotification('Image uploaded successfully', 'success');
          contactReport();
          setOpen(false);
        } else {
          showNotification('Error generated, please try again', 'error');
        }
        setDataLoading(false);
      }
    }
  };

  const fetchToken = () => {
    const deviceOptions = {
      edge: 'ashburn',
      debug: true,
    };
    postDataRequest(config.callApi, {
      query: getToken,
      variables: {
        locationid: locationId,
      },
    })
      .then((res) => {
        if (res) {
          const device = new Device(res?.data?.getToken, deviceOptions);
          setTwillioDevice(device);
          device.disconnectAll(() => {
            setOnPhone(false);
            setModalOpen(false);
          });
        }
      });
  };

  const handleOpenAppointment = () => {
    setAppointmentOpen(true);
  };

  const handleCloseAppointment = () => {
    setAppointmentOpen(false);
  };

  const handleClick = async () => {
    if (callStatus !== true) {
      showNotification('you don\'t have enough credits. Upgrade plan or contact us.', 'error');
    } else {
      const fromNo = contactNumber ? `+${contactNumber}` : '';
      const toNo = `+${reportContact?.phonenumber}`;
      if (fromNo) {
        await postDataRequest(config.callApi, {
          query: CheckCall,
          variables: {
            number: fromNo,
          },
        })
          .then(async (res) => {
            setModalOpen(true);
            if (res.data.checkCall) {
              if (onPhone === false) {
                await setOnPhone(true);
                // eslint-disable-next-line no-shadow
                const params = {
                  number: toNo, fromNumber: fromNo, locationid: locationId, businessid: businessId, createdby: JSON.parse(localStorage.getItem('userid')),
                };
                const twilioData = await twillioDevice.connect({ params });
                setSid({ data: twilioData.parameters });
              }
            } else {
              setVerificationCode('');
            }
          });
      } else {
        setModalOpen(true);
      }
    }
  };

  const dataCall = useSubscription(
    CallStatus,
    { variables: { from: `+${contactNumber}`, to: `+${reportContact?.phonenumber}` } },
  );

  const createNotes = async () => {
    await setDataLoading(true);
    await postDataRequest(config.baseURLApi, {
      query: CreateContactNote,
      variables: {
        contactid: contactId || Id || usageContactId,
        notesubject: noteSubject || 'Note',
        notedescription: editorValue,
        entitytype: 2,
        parententityid: callNote?.callstatusid,
        assingedtocontact: [contact],
        createdby: JSON.parse(localStorage.getItem('userid')),
      },
    }).then((res) => {
      if (res?.errors && res?.errors[0]?.message) {
        showNotification(res.errors[0].message, 'error');
      } else if (res?.data?.createContactNote) {
        showNotification('Note added successfully', 'success');
        setNoteModal(false);
        setEditorValue('');
      }
      setDataLoading(false);
    });
  };

  const EndCall = () => {
    setOnPhone(false);
    setModalOpen(false);
    if (noteOpen) {
      createNotes();
      contactReport();
    }
    twillioDevice && twillioDevice.disconnectAll();
  };

  const checkVerificationStatus = () => {
    const fromNo = `+${contactNumber}`;
    if (fromNo) {
      postDataRequest(config.callApi, {
        query: CheckStatus,
        variables: {
          number: fromNo,
        },
      })
        .then((res) => {
          setModalOpen(false);
          if (res.data.checkStatus === 'success') {
            toast.success('PhoneNo verification successfully...');
          } else {
            toast.error('Phone number is not verified, Try again.');
          }
        });
    }
  };

  const onVerify = () => {
    checkVerificationStatus();
  };

  const handleChangeNote = (values) => {
    setEditorValue(values);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getPropertiesByBusiness();
      if (businessId) {
        postDataRequest(config.baseURLApi, {
          query: Planavailablebalance,
          variables: {
            businessid: businessId,
          },
        }).then((res) => {
          if (res?.data?.planavailablebalance) {
            const data = (
              res?.data?.planavailablebalance?.calltimelimit
              - res?.data?.planavailablebalance?.calltime
            ) > 20;
            setCallStatus(data);
          }
        });
      }
    }
    return () => {
      isMounted = false;
    };
  }, [businessId]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (!Id && !contactId && !usageContactId) {
        <Route href={history?.push('/app/contact-central')} />;
      } else {
        contactReport();
      }
    }
    return () => {
      isMounted = false;
    };
  }, [Id, contactId, usageContactId]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (userInfo?.userid) {
        postDataRequest(config.baseURLApi, {
          query: Userprofilebyuser,
          variables: {
            userid: userInfo?.userid,
          },
        })
          .then((res) => {
            setContact(res?.data?.userprofilebyuser?.twiliophonenumber);
          });
      }
      fetchToken();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (dataCall?.data?.callStatus && isMounted) {
      EndCall();
    }
    return () => {
      isMounted = false;
    };
  }, [dataCall?.data?.callStatus]);

  useEffect(() => {
    let isMounted = true;
    if (locationId && isMounted) {
      getAppointmentData();
    } return () => {
      isMounted = false;
    };
  }, [locationId]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (activityTab && (activityTab === '1' || activityTab === '4')) {
        setActiveTab(Number(activityTab));
      }
    } return () => {
      isMounted = false;
    };
  }, [activityTab]);

  return (
    <div>
      {formDataChanged === true && (
        <Prompt message="Are you sure you want to leave? Unsaved Data may be lost..." />
      )}
      {dataLoading ? <LoadingPage /> : <></>}
      <Dialog fullScreen open={contactOpen} onClose={() => setContactOpen(false)}>
        {dataLoading ? <LoadingPage /> : <></>}
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.titles}>
              Organization
            </Typography>
            <IconButton edge="end" color="inherit" onClick={() => setContactOpen(false)} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <br />
        <div className={classes.responsiveOrg}>
          <Widget disablewidgetmenu="true">
            <List>
              <Suspense fallback={<LazyLoader />}>
                <EditOrganization
                  history={history}
                  organization
                  oid={reportContact?.organization?.organizationid}
                />
              </Suspense>
            </List>
          </Widget>
        </div>
      </Dialog>
      <Grid container spacing={1}>
        {viewBox !== 2 ? (
          <>
            <Grid item md={3} xs={12}>
              <Widget
                disablewidgetmenu="true"
                size="small"
                bodyclass={classes.navPaddingLocation}
              >
                {!CRM ? (
                  <Button
                    size="small"
                    className={classes.backArrow}
                    startIcon={<ArrowBackIcon fontSize="small" />}
                    onClick={() => history.push('/app/contact-central')}
                  >
                    Contacts
                  </Button>
                ) : ''}
                <center>
                  <Badge
                    className={classes.avatarImg}
                    overlap="circular"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    badgeContent={(
                      <AddAPhotoOutlinedIcon
                        className={classes.imgIcon}
                        onClick={() => handleClickOpen()}
                      />
                    )}
                  >
                    <AvatarMui
                      className={classes.avatar}
                      src={state?.preview}
                    >
                      {handleUndefined(reportContact?.firstname?.charAt(0))}
                    </AvatarMui>
                  </Badge>
                  <br />
                  <br />
                  <Typography variant="h6" className={classes.themeText}>
                    {handleUndefined(reportContact?.firstname)}
                    {' '}
                    {handleUndefined(reportContact?.lastname)}
                  </Typography>
                  {(reportContact?.primaryemail === undefined || reportContact?.primaryemail === null || reportContact?.primaryemail === '') ? (
                    <IconButton
                      color="primary"
                      size="medium"
                      className={classes.IconButton}
                      component="span"
                      onClick={() => {
                        setDataLoading(true);
                        postDataRequest(config.emailFinderApi, {
                          query: findEmail,
                          variables: {
                            contactids: [contactId || Id || usageContactId],
                            businessid: businessId,
                            locationid: locationId,
                            createdby: userInfo?.userid,
                          },
                        }).then((res) => {
                          if (res?.errors && res?.errors[0]?.message) {
                            showNotification(res.errors[0].message, 'error');
                          } else if (res?.data?.findEmail) {
                            showNotification('Please wait sometime we are processing your request. 1 credit has been debited for finding this email', 'success');
                          }
                          setDataLoading(false);
                        }).catch((err) => {
                          setDataLoading(false);
                          showNotification(err, 'error');
                        });
                      }}
                    >
                      <SearchIcon fontSize="small" style={{ color: '#005eb2' }} />
                      <Typography className={classes.fieldName}>Find Email</Typography>
                    </IconButton>
                  ) : (
                    <Typography variant="body2">{handleUndefined(reportContact?.primaryemail)}</Typography>
                  )}
                  <Typography variant="body2">{handleUndefined(reportContact?.jobid)}</Typography>
                  <div className={classes.redirectButtonLayout}>
                    <Grid container justifyContent="center">
                      <Grid>
                        <Tooltip title={handleUndefined(reportContact?.phonenumber)} aria-label="add">
                          {disable ? (
                            <>
                              <Fab
                                size="small"
                                color={handleUndefined(reportContact?.phonenumber) ? 'primary' : ''}
                                onClick={() => handleClick()}
                                className={classes.editIcon}
                              >
                                <PhoneIcon />
                              </Fab>
                            </>
                          ) : (
                            <Fab
                              size="small"
                              href={`${`tel:${handleUndefined(reportContact?.phonenumber)}`}`}
                              disabled
                              className={classes.editIcon}
                            >
                              <PhoneIcon />
                            </Fab>
                          )}
                        </Tooltip>
                      </Grid>
                      <Grid>
                        <Tooltip title="Email" aria-label="add">
                          <Fab
                            size="small"
                            color="primary"
                            onClick={() => {
                              if (isVisitor) {
                                history.push(`/app/lead-central/visitors?id=${contactId}&&activity=${4}`);
                              } else {
                                history.push(`/app/contact-central-edit?id=${contactId}&&activity=${4}`);
                              }
                            }
                            }
                            className={classes.editIcon}
                          >
                            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/email_1.svg" alt="crm" />
                          </Fab>
                        </Tooltip>
                      </Grid>
                      {handleUndefined(reportContact?.linkedin) ? (
                        <Grid>
                          <Tooltip title="LinkedIn" aria-label="add">
                            <Fab
                              color="primary"
                              size="small"
                              target="_blank"
                              href={handleUndefined(reportContact?.linkedin)}
                              className={classes.editIcon}
                            >
                              <LinkedInIcon />
                            </Fab>
                          </Tooltip>
                        </Grid>
                      ) : ''}
                      {handleUndefined(reportContact?.facebook) ? (
                        <Grid>
                          <Tooltip
                            title="Facebook"
                            aria-label="add"
                          >
                            <Fab target="_blank" color="primary" href={handleUndefined(reportContact?.facebook)} size="small" className={classes.editIcon}>
                              <FacebookIcon />
                            </Fab>
                          </Tooltip>
                        </Grid>
                      ) : ''}
                      {handleUndefined(reportContact?.twitter) ? (
                        <Grid>
                          <Tooltip
                            title="Twitter"
                            aria-label="add"
                          >
                            <Fab target="_blank" color="primary" href={handleUndefined(reportContact?.twitter)} size="small" className={classes.editIcon}>
                              <TwitterIcon />
                            </Fab>
                          </Tooltip>
                        </Grid>
                      ) : ''}
                    </Grid>
                  </div>
                  <hr className={classes.crmHr} />
                </center>
                <Box className={classes.orgbtn}>
                  <Typography variant="body2">Organization:</Typography>
                  <Box>
                    {reportContact?.organization?.name ? (
                      <>
                        <IconButton onClick={handleOpenOrg} size="small">
                          <EditOutlined size="small" className={classes.themeText} />
                        </IconButton>
                        <IconButton onClick={() => setOpenDelete(true)} size="small">
                          <DeleteIcon size="small" color="secondary" />
                        </IconButton>
                      </>
                    ) : (
                      <IconButton onClick={handleOpenOrg} size="small">
                        <AddIcon size="small" className={classes.themeText} />
                      </IconButton>
                    )}
                  </Box>
                </Box>
                <br className={reportContact?.organization?.name
                  ? '' : classes.orgBr}
                />
                {reportContact?.organization?.name ? (
                  <Box>
                    <Paper className={classes.sidebarPaper}>
                      <center>
                        <Linked
                          href
                          onClick={() => {
                            handleClickContact();
                          }}
                        >
                          <Typography
                            variant="body2"
                          >
                            {reportContact?.organization?.name}
                          </Typography>
                        </Linked>
                      </center>
                      <div className={classes.redirectButtonLayout}>
                        <Grid container justifyContent="center">
                          {handleUndefined(reportContact?.organization?.orgwebsite) ? (
                            <Grid>
                              <Tooltip title="Website" aria-label="add">
                                <Fab
                                  color="primary"
                                  size="small"
                                  target="_blank"
                                  href={handleUndefined(reportContact?.organization?.orgwebsite)}
                                  className={classes.margin}
                                >
                                  <PublicIcon />
                                </Fab>
                              </Tooltip>
                            </Grid>
                          ) : ''}
                          {handleUndefined(reportContact?.organization?.orgmap) ? (
                            <Grid>
                              <Tooltip title="Map" aria-label="add">
                                <Fab
                                  color="primary"
                                  size="small"
                                  target="_blank"
                                  href={handleUndefined(reportContact?.organization?.orgmap)}
                                  className={classes.margin}
                                >
                                  <RoomIcon />
                                </Fab>
                              </Tooltip>
                            </Grid>
                          ) : ''}
                          {handleUndefined(reportContact?.organization?.orgyoutubeurl) ? (
                            <Grid>
                              <Tooltip title="Youtube" aria-label="add">
                                <Fab
                                  color="primary"
                                  size="small"
                                  target="_blank"
                                  href={handleUndefined(reportContact?.organization?.orgyoutubeurl)}
                                  className={classes.margin}
                                >
                                  <YouTubeIcon />
                                </Fab>
                              </Tooltip>
                            </Grid>
                          ) : ''}
                          {handleUndefined(reportContact?.organization?.orglinkedin) ? (
                            <Grid>
                              <Tooltip title="LinkedIn" aria-label="add">
                                <Fab
                                  color="primary"
                                  size="small"
                                  target="_blank"
                                  href={handleUndefined(reportContact?.organization?.orglinkedin)}
                                  className={classes.margin}
                                >
                                  <LinkedInIcon />
                                </Fab>
                              </Tooltip>
                            </Grid>
                          ) : ''}
                          {handleUndefined(reportContact?.organization?.orgfacebook) ? (
                            <Grid>
                              <Tooltip
                                title="Facebook"
                                aria-label="add"
                              >
                                <Fab target="_blank" color="primary" href={handleUndefined(reportContact?.organization?.orgfacebook)} size="small" className={classes.margin}>
                                  <FacebookIcon />
                                </Fab>
                              </Tooltip>
                            </Grid>
                          ) : ''}
                          {handleUndefined(reportContact?.organization?.orgtwitter) ? (
                            <Grid>
                              <Tooltip
                                title="Twitter"
                                aria-label="add"
                              >
                                <Fab target="_blank" color="primary" href={handleUndefined(reportContact?.organization?.orgtwitter)} size="small" className={classes.margin}>
                                  <TwitterIcon />
                                </Fab>
                              </Tooltip>
                            </Grid>
                          ) : ''}
                          {handleUndefined(reportContact?.organization?.orggmb) ? (
                            <Grid>
                              <Tooltip
                                title="GMB"
                                aria-label="add"
                              >
                                <Fab target="_blank" href={handleUndefined(reportContact?.organization?.orggmb)} size="small" className={classes.margin}>
                                  <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/contactcrmimport_2.svg" alt="crm" />
                                </Fab>
                              </Tooltip>
                            </Grid>
                          ) : ''}
                        </Grid>
                      </div>
                    </Paper>
                  </Box>
                ) : ''}
                <hr className={reportContact?.organization?.name
                  ? classes.wfDivider : classes.wfDividerR}
                />
                <Suspense fallback={<LazyLoader />}>
                  <AssignWorkflowContact
                    contactId={contactId}
                    Id={Id}
                    usageContactId={usageContactId}
                    history={history}
                  />
                </Suspense>
                <br />
              </Widget>
            </Grid>
          </>
        ) : (
          <Grid item md={viewBox !== 2 ? 9 : 12} xs={12}>
            <Paper className={classes.editHeader}>
              <Box className={classes.headicon}>
                <Box className={classes.headerDetails}>
                  <Box className={classes.headerDetail}>
                    <AvatarMui className={classes.split} src={state?.preview}>
                      {handleUndefined(reportContact?.firstname?.charAt(0))}
                    </AvatarMui>
                    &nbsp;&nbsp;&nbsp;
                    <Box>
                      <Typography variant="body2" className={classes.themeText}>
                        {handleUndefined(reportContact?.firstname)}
                        {' '}
                        {handleUndefined(reportContact?.lastname)}
                      </Typography>
                      <Typography variant="subtitle2" className={classes.themeText}>
                        {handleUndefined(reportContact?.jobid)}
                      </Typography>
                    </Box>
                  </Box>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Box
                    style={{ marginLeft: '21px' }}
                    className={classes.reportOrg}
                  >
                    <Typography variant="body2" className={classes.themeText}>
                      Organization :
                    </Typography>
                    <Box className={classes.actionbtn}>
                      &nbsp;&nbsp;
                      <Tooltip title={handleUndefined(reportContact?.organization?.name)}>
                        <Link
                          to={`/app/contact-central?id=${contactId}&&oid=${reportContact?.organization?.organizationid}`}
                          onClick={() => {
                            handleClickContact();
                          }}
                        >
                          <Typography
                            className={classes.organizationView}
                            variant="subtitle2"
                            color="primary"
                          >
                            {handleUndefined(reportContact?.organization?.name)}
                          </Typography>
                        </Link>
                      </Tooltip>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {handleUndefined(reportContact?.organization?.name) ? (
                        <>
                          <Tooltip title="Edit Organization">
                            <EditOutlined style={{ fontSize: 15 }} onClick={handleOpenOrg} size="small" color="primary" />
                          </Tooltip>
                          <Tooltip title="Remove contact & organization association">
                            <IconButton color="primary" onClick={() => setOpenDelete(true)} size="small">
                              <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/contactcrm_3.svg" alt="crm" style={{ fontSize: '15px' }} />
                            </IconButton>
                          </Tooltip>
                        </>
                      ) : (
                        <Tooltip title="Add Organization">
                          <AddIcon style={{ fontSize: 18 }} onClick={handleOpenOrg} size="small" className={classes.themeText} />
                        </Tooltip>
                      )}
                    </Box>
                  </Box>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {handleUndefined(reportContact?.phonenumber)
                    && handleUndefined(reportContact?.phonenumber?.length > 7) ? (
                    <Box>
                      <Typography variant="body2" className={classes.themeText}>
                        Phone :
                      </Typography>
                      <Typography variant="subtitle2">
                        +
                        {handleUndefined(reportContact?.phonenumber)}
                      </Typography>
                    </Box>
                  ) : ''}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {(reportContact?.primaryemail === undefined || reportContact?.primaryemail === null || reportContact?.primaryemail === '') ? (
                    <IconButton
                      color="primary"
                      size="medium"
                      className={classes.IconButton}
                      component="span"
                      onClick={() => {
                        setDataLoading(true);
                        postDataRequest(config.emailFinderApi, {
                          query: findEmail,
                          variables: {
                            contactids: [contactId || Id || usageContactId],
                            businessid: globalContext.globalState.currentLocation.parententityid,
                            locationid: locationId,
                            createdby: userInfo?.userid,
                          },
                        }).then((res) => {
                          if (res?.errors && res?.errors[0]?.message) {
                            showNotification(res.errors[0].message, 'error');
                          } else if (res?.data?.findEmail === 0) {
                            showNotification('Please wait sometime we are processing your request.', 'success');
                          }
                          setDataLoading(false);
                        }).catch((err) => {
                          setDataLoading(false);
                          showNotification(err, 'error');
                        });
                      }}
                    >
                      <SearchIcon fontSize="small" style={{ color: '#005eb2' }} />
                      <Typography className={classes.fieldName}>Find Email</Typography>
                    </IconButton>
                  ) : (
                    <Tooltip title={handleUndefined(reportContact?.primaryemail)}>
                      <Box className={classes.emailOrg}>
                        <Typography variant="body2" className={classes.themeText}>
                          Email :
                        </Typography>
                        &nbsp;&nbsp;
                        <Typography
                          style={{
                            textOverflow: 'ellipsis',
                            width: '220px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                          }}
                          variant="subtitle2"
                        >
                          {handleUndefined(reportContact?.primaryemail)}
                        </Typography>
                      </Box>
                    </Tooltip>
                  )}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Box>
              </Box>
              <Box>
                <div className={classes.redirectButtonLayout}>
                  <Grid container>
                    <Grid item lg={6} style={{ paddingTop: '20px', paddingLeft: '10px' }}>
                      {handleUndefined(reportContact?.leadscore)
                        ? (
                          <Box className={classes.emailOrg}>
                            <Typography variant="body2" className={classes.themeText}>
                              Engagement Score :
                            </Typography>
                            &nbsp;&nbsp;
                            <Typography variant="subtitle2">
                              {handleUndefined(reportContact?.leadscore)}
                            </Typography>
                          </Box>
                        )
                        : ''}
                    </Grid>
                    <Grid lg={6}>
                      <Grid container style={{ justifyContent: 'end' }}>
                        <Grid>
                          <Fab
                            size="small"
                            color="primary"
                            onClick={handleOpenAppointment}
                            className={classes.editIcon}
                          >
                            <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/contactcrm_4.svg" alt="crm" className={classes.menus} />
                          </Fab>
                        </Grid>
                        <Dialog
                          fullWidth
                          open={appointmentOpen}
                          onClose={handleCloseAppointment}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">Appointment</DialogTitle>
                          <DialogContent>
                            <Suspense fallback={<LazyLoader />}>
                              <AppointmentChat id={reportContact?.contactid} />
                            </Suspense>
                          </DialogContent>
                          <DialogActions>
                            <Button size="small" variant="contained" startIcon={<CloseIcon />} onClick={handleCloseAppointment} color="primary">
                              Cancel
                            </Button>
                          </DialogActions>
                        </Dialog>
                        <Grid>
                          <Tooltip title={handleUndefined(reportContact?.phonenumber)} aria-label="add">
                            {disable ? (
                              <>
                                <Fab
                                  size="small"
                                  color={handleUndefined(reportContact?.phonenumber) ? 'primary' : ''}
                                  onClick={() => handleClick()}
                                  className={classes.editIcon}
                                >
                                  <PhoneIcon />
                                </Fab>
                              </>
                            ) : (
                              <Fab
                                size="small"
                                href={`${`tel:${handleUndefined(reportContact?.phonenumber)}`}`}
                                disabled
                                className={classes.editIcon}
                              >
                                <PhoneIcon />
                              </Fab>
                            )}
                          </Tooltip>
                        </Grid>
                        <Grid>
                          <Tooltip title="Email" aria-label="add">
                            <Fab
                              size="small"
                              color="primary"
                              className={classes.editIcon}
                              onClick={() => {
                                if (isVisitor) {
                                  history.push(`/app/lead-central/visitors?id=${contactId}&&activity=${4}`);
                                } else {
                                  history.push(`/app/contact-central-edit?id=${contactId}&&activity=${4}`);
                                }
                              }}
                            >
                              <img src="https://storage.googleapis.com/bixpand-staging/WebsiteImages/email_1.svg" alt="crm" />
                            </Fab>
                          </Tooltip>
                        </Grid>
                        {handleUndefined(reportContact?.linkedin) ? (
                          <Grid>
                            <Tooltip title="LinkedIn" aria-label="add">
                              <Fab
                                color="primary"
                                size="small"
                                target="_blank"
                                href={handleUndefined(reportContact?.linkedin)}
                                className={classes.editIcon}
                              >
                                <LinkedInIcon style={{ color: 'white' }} />
                              </Fab>
                            </Tooltip>
                          </Grid>
                        ) : ''}
                        {handleUndefined(reportContact?.facebook) ? (
                          <Grid>
                            <Tooltip
                              title="Facebook"
                              aria-label="add"
                            >
                              <Fab target="_blank" color="primary" href={handleUndefined(reportContact?.facebook)} size="small" className={classes.editIcon}>
                                <FacebookIcon style={{ color: 'white' }} />
                              </Fab>
                            </Tooltip>
                          </Grid>
                        ) : ''}
                        {handleUndefined(reportContact?.twitter) ? (
                          <Grid>
                            <Tooltip
                              title="Twitter"
                              aria-label="add"
                            >
                              <Fab target="_blank" color="primary" href={handleUndefined(reportContact?.twitter)} size="small" className={classes.editIcon}>
                                <TwitterIcon style={{ color: 'white' }} />
                              </Fab>
                            </Tooltip>
                          </Grid>
                        ) : ''}
                      </Grid>
                    </Grid>
                  </Grid>

                </div>
              </Box>
            </Paper>
          </Grid>
        )}
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm" aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Image Upload</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <center>
                <Avatar
                  width={180}
                  className={
                    classes.field
                  }
                  height={180}
                  imageWidth={250}
                  onCrop={(preview) => setState({
                    preview,
                  })}
                  onClose={() => setState({
                    preview: null,
                  })}
                  src={state.src}
                  onBeforeFileLoad={
                    onBeforeFileLoad
                  }
                  label="Image Upload"
                />
              </center>
              <br />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              startIcon={<CloseIcon />}
              size="small"
              variant="contained"
              onClick={() => {
                handleClose();
                contactReport();
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button startIcon={<SaveIcon />} size="small" variant="contained" onClick={updateContact} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openOrg}
          onClose={handleCloseOrg}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Box className={classes.action}>
            <DialogTitle id="alert-dialog-title">Organization</DialogTitle>
            <IconButton onClick={handleCloseOrg} variant="contained">
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <AddOrg
                history={history}
                organizationid={reportContact?.organizationid}
                handleCloseOrg={handleCloseOrg}
                contactReport={contactReport}
              />
            </DialogContentText>
          </DialogContent>
        </Dialog>
        <Grid item md={viewBox !== 2 ? 9 : 12} xs={12}>
          <Widget disablewidgetmenu="true">
            <Tabs
              value={activeTab}
              className={classes.tabs}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              ScrollButtonComponent={(props) => (
                <CustomScrollButton
                  {...props}
                  className={props.visible ? '' : classes.scrollButtonHidden}
                />
              )}
            >
              <Tab className={classes.tab} label="Details" {...a11yProps(0)} />
              <Tab className={classes.tab} label="Activities" {...a11yProps(1)} />
              <Tab className={classes.tab} label={`Notes (${reportContact?.notecount})`} {...a11yProps(2)} />
              <Tab className={classes.tab} label={`Tasks (${reportContact?.taskcount})`} {...a11yProps(3)} />
              <Tab className={classes.tab} label={`Emails (${reportContact?.emailcount})`} {...a11yProps(4)} />
              <Tab className={classes.tab} label={`Deals (${reportContact?.dealscount})`} {...a11yProps(5)} />
              <Tab className={classes.tab} label={`Docs (${reportContact?.documentcount})`} {...a11yProps(6)} />
              <Tab className={classes.tab} label={`Campaigns (${reportContact?.emailmarketingcount?.count})`} {...a11yProps(7)} />
              <Tab className={classes.tab} label={`Review (${reportContact?.reviewcount?.count})`} {...a11yProps(8)} />
              <Tab className={classes.tab} label={`Appointments (${appointmentCount})`} {...a11yProps(9)} />
            </Tabs>
            <br />
            {activeTab === 0 ? (
              <div className={classes.sectionsizeCRM}>
                {sectionDataContact && sectionDataContact.map((item, index) => (
                  <Fragment key={index}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.expandTitle}>{item?.name}</Typography>
                      </AccordionSummary>
                      {item.objecttype === 'contact' ? (
                        <AccordionDetails className={classes.ExpansionPanelDetails}>
                          {item?.name !== 'Lead Information' ? (
                            <Suspense fallback={<LazyLoader />}>
                              <CustomFieldContact
                                usageContactId={usageContactId}
                                setFormDataChanged={setFormDataChanged}
                                contactReport={contactReport}
                                properties={item?.properties}
                                sectionName={item?.name}
                                history={history}
                              />
                            </Suspense>
                          )
                            : (
                              <LeadInformation
                                usageContactId={usageContactId}
                                setFormDataChanged={setFormDataChanged}
                                properties={item?.properties}
                                sectionName={item?.name}
                                history={history}
                              />
                            )}
                        </AccordionDetails>
                      ) : ''}
                    </Accordion>
                  </Fragment>
                ))}
                <br />
                <center>
                  <Link href to="/app/properties">
                    <Box className={classes.addProperties}>
                      <AddIcon fontSize="small" className={classes.themeText} />
                      <Typography variant="body2" className={classes.themeText}>
                        Add More Contact Properties
                      </Typography>
                    </Box>
                  </Link>
                </center>
              </div>
            ) : ''}
            {activeTab === 1 ? (
              <Suspense fallback={<LazyLoader />}>
                <Avtivity
                  usageContactId={usageContactId}
                  setValue={setActiveTab}
                  history={history}
                />
              </Suspense>
            ) : ''}
            {activeTab === 2 ? (
              <Suspense fallback={<LazyLoader />}>
                <CRMnotes
                  usageContactId={usageContactId}
                  contactReport={contactReport}
                  history={history}
                />
              </Suspense>
            ) : ''}
            {activeTab === 3 ? (
              <Suspense fallback={<LazyLoader />}>
                <CRMTask
                  usageContactId={usageContactId}
                  contactReport={contactReport}
                  history={history}
                />
              </Suspense>
            ) : ''}
            {activeTab === 4 ? (
              <Suspense fallback={<LazyLoader />}>
                <CRMEmail
                  usageContactId={usageContactId}
                  contactReport={contactReport}
                  history={history}
                />
              </Suspense>
            ) : ''}
            {activeTab === 5 ? (
              <Suspense fallback={<LazyLoader />}>
                <Deal
                  usageContactId={usageContactId}
                  contactReport={contactReport}
                  addDeal={addDeal}
                  edit={editDeal}
                  delet={deleteDeal}
                  history={history}
                />
              </Suspense>
            ) : ''}
            {activeTab === 6 ? (
              <Suspense fallback={<LazyLoader />}>
                <Document
                  usageContactId={usageContactId}
                  contactReport={contactReport}
                  history={history}
                />
              </Suspense>
            ) : ''}
            {activeTab === 7 ? (
              <Suspense fallback={<LazyLoader />}>
                <CRMMarketing usageContactId={usageContactId} history={history} />
                {' '}
              </Suspense>
            ) : ''}
            {activeTab === 8 ? <Suspense fallback={<LazyLoader />}><CRMReview usageContactId={usageContactId} history={history} /></Suspense> : ''}
            {activeTab === 9 ? <Suspense fallback={<LazyLoader />}><CRMAppointments usageContactId={contactId} history={history} /></Suspense> : ''}
          </Widget>
        </Grid>
      </Grid>
      <Dialog
        open={modalOpen}
        fullWidth
        maxWidth="sm"
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          {(!contactNumber && !onPhone) && (
            <span>
              Please
              <a href="/app/setting-my-profile">
                Click here
              </a>
              for update your contact information.
            </span>
          )}
          {(verificationCode && !onPhone) && (
            <>
              <span>
                Your verification code is :- &nbsp;
                <span style={{ color: 'green' }}>
                  {verificationCode}
                </span>
              </span>
              <Button
                color="green"
                variant="contained"
                type="submit"
                onClick={() => onVerify()}
                style={{
                  backgroundColor: 'green',
                  color: 'white',
                  marginLeft: '10px',
                }}
              >
                Verify
              </Button>
            </>
          )}
          <Registerphone
            onPhone={onPhone}
            setModalOpen={setModalOpen}
            verificationCode={verificationCode}
            setVerificationCode={setVerificationCode}
          />
          {onPhone && (
            <Box className={classes.call}>
              <AvatarMui
                className={classes.callerImage}
                src={state?.preview}
              />
              <br />
              <Typography variant="h6" color="primary">
                Calling with
                {' '}
                {handleUndefined(reportContact?.firstname)}
                {' '}
                {handleUndefined(reportContact?.lastname)}
              </Typography>
              <MyStopwatch onPhone={onPhone} />
              <br />
              <Button
                className={classes.dangerButton}
                variant="contained"
                type="submit"
                onClick={() => EndCall()}
              >
                <CallEndIcon />
              </Button>
              <Button
                color="primary"
                variant="contained"
                style={{ marginLeft: '15px' }}
                onClick={() => handleOpenNote()}
              >
                <NotesIcon />
              </Button>
            </Box>
          )}
          {noteOpen === true ? (
            <>
              <br />
              <hr />
              <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                  <TextField
                    className={classes.field}
                    fullWidth
                    size="small"
                    name="notesubject"
                    value={noteSubject}
                    onChange={(e) => setNoteSubject(e.target.value)}
                    variant="outlined"
                    type="text"
                    label="Subject"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <div className="richTextEditorWrapper">
                    <BixJoditEditor
                      joditRef={joditRef}
                      joditValue={editorValue}
                      handleEditorChange={handleChangeNote}
                    />
                  </div>
                </Grid>
              </Grid>
              <br />
            </>
          ) : ''}
        </DialogContent>
      </Dialog>
      <Dialog
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="responsive-dialog-title">
          Want to remove the organization from this contact?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Association of contact and organization will be removed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={deleteOrg}
            size="small"
            color="primary"
            variant="contained"
            autoFocus
          >
            Agree
          </Button>
          <Button
            autoFocus
            onClick={() => setOpenDelete(false)}
            size="small"
            color="secondary"
            variant="contained"
          >
            Disagree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
