import React, { useState, useEffect } from 'react';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import {
  Drawer, IconButton,
  List, Link, Divider, Box,
} from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import useStyles from './styles';
import SidebarLink from './components/SidebarLink/SidebarLink';
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from '../../context/LayoutContext';
import { handleUndefined, postDataRequest } from '../../common/commonfunctions';
import config from '../../config';
import { WhiteLabelSettingByBusiness } from '../../Api Mutation/WhiteLabelSettingApi';

function Sidebar({ location, structure, getToogleBodyStatus }) {
  const classes = useStyles();
  const theme = useTheme();
  const [whiteLabelData, setWhiteLabelData] = useState(null);
  const [openOnHover, setOpenOnHover] = useState(false);
  // global
  const {
    isSidebarOpened,
  } = useLayoutState();
  const businessId = handleUndefined(JSON.parse(localStorage?.getItem('business_Id')));
  const layoutDispatch = useLayoutDispatch();
  const logo = 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/demoCircle.jpg';
  const sideBarLogo = 'https://storage.googleapis.com/bixpand-staging/WebsiteImages/demoSquare.png';
  // local
  const [isPermanent, setPermanent] = useState(true);
  function handleWindowWidthChange() {
    const windowWidth = window.innerWidth;
    const breakpointWidth = theme.breakpoints.values.md;
    const isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
  const toggleDrawer = (value) => (event) => {
    if (getToogleBodyStatus) { getToogleBodyStatus(false); }
    if (
      event.type === 'keydown'
      && (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    if (value && !isPermanent) toggleSidebar(layoutDispatch);
  };
  const [openSideBar, setOpenSideBar] = useState();

  const getWhiteLabeleData = () => {
    if (businessId) {
      postDataRequest(config?.baseURLApi, {
        query: WhiteLabelSettingByBusiness,
        variables: {
          businessid: businessId,
        },
      }).then((response) => {
        if (response?.errors && response?.errors[0]?.message) {
          showNotification(response.errors[0].message, 'error');
        } else if (response?.data?.whiteLabelSettingsByBusiness) {
          setWhiteLabelData(response?.data?.whiteLabelSettingsByBusiness);
        }
      });
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (businessId) {
        getWhiteLabeleData();
      }
    }
    return () => {
      isMounted = false;
    };
  }, [businessId]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener('resize', handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? 'permanent' : 'temporary'}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: !isPermanent
          ? !isSidebarOpened
          : isSidebarOpened,
        [classes.drawerClose]: !isPermanent
          ? isSidebarOpened
          : !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpenPaper]: !isPermanent
            ? !isSidebarOpened
            : isSidebarOpened,
          [classes.drawerClose]: !isPermanent
            ? isSidebarOpened
            : !isSidebarOpened,
        }),
      }}
      open={!isPermanent ? !isSidebarOpened : isSidebarOpened}
      onClose={toggleDrawer(true)}
      onMouseEnter={() => (!isSidebarOpened ? (toggleSidebar(layoutDispatch), setOpenOnHover(true)) : '')}
      onMouseLeave={() => ((isSidebarOpened && openOnHover) ? (toggleSidebar(layoutDispatch), setOpenOnHover(false)) : '')}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(
                classes.headerIcon,
                classes.headerIconCollapse,
              ),
            }}
          />
        </IconButton>
      </div>
      <List
        className={classes.sidebarList}
        classes={{ padding: classes.padding }}
      >
        {structure.map((link) => {
          return !(link?.label === 'Professional Services' && whiteLabelData?.isdisabledexpertservices === true) ? (
            <SidebarLink
              setOpenSideBar={setOpenSideBar}
              openSideBar={openSideBar}
              getToogleBodyStatus={getToogleBodyStatus}
              key={link.id}
              location={location}
              isSidebarOpened={!isPermanent ? !isSidebarOpened : isSidebarOpened}
              {...link}
              toggleDrawer={toggleDrawer(true)}
            />
          ) : null;
        })}
        <Divider style={{ marginTop: 20 }} />
        {(whiteLabelData?.isdisabledgetdemo === false) ? <div className={classes.sidebarBottom}>
          <center>
            <Link href={handleUndefined(whiteLabelData?.appointmenturl) || "https://app.bixpand.com/appointment/sd/meeting-with-sd@bixpand.com"} target="_blank">
              <Box display="flex" flexWrap="wrap" flexDirection="row" alignItems="center">
                {logo ? (
                  <img
                    src={logo}
                    className={classes.bixpandLogo}
                    alt="bixpand"
                  />
                ) : ''}
                {sideBarLogo ? (
                  <img
                    src={sideBarLogo}
                    className={classNames({
                      [classes.sidebarLogo]: !isPermanent
                        ? !isSidebarOpened
                        : isSidebarOpened,
                      [classes.sidebarLogoUnvisible]: !isPermanent
                        ? isSidebarOpened
                        : !isSidebarOpened,
                    })}
                    alt="bixpand"
                  />
                ) : ''}
              </Box>
            </Link>
          </center>
        </div> : ''}
      </List>
    </Drawer>
  );
}
export default withRouter(Sidebar);
