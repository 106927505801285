import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  Paper: {
    backgroundColor: '#e8e8ee',
    color: '#000000',
    fontWeight: 500,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '20px',
    [theme.breakpoints.down('xs')]: {
      backgroundColor: '#e8e8ee',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  actionIcon: {
    color: '#005EB2',
  },
  themeText: {
    color: localStorage.getItem('theme') === 'dark' ? '#c9c9df' : '#005eb2',
  },
  themeTextWhite: {
    color: localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2',
  },
  videoResponsive: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 'auto',
    },
  },
  Dropzone: {
    height: '150px',
    textAlign: 'center',
    padding: '17px',
    backgroundColor: localStorage.getItem('theme') === 'dark' ? 'transparent' : 'lightGray',
    color: localStorage.getItem('theme') === 'dark' ? 'white' : 'gray',
    border: localStorage.getItem('theme') === 'dark' ? '1px dashed white' : '',
  },
  placeholderIcon: {
    fontSize: 50,
    [theme.breakpoints.down('xs')]: {
      fontSize: 45,
    },
  },
  previewImage: {
    width: 150,
    aspectRatio: 'auto',
  },
  pointer: {
    cursor: 'pointer',
    color: localStorage.getItem('theme') === 'dark' ? '#ffff' : '#005eb2',
  },
  root: {
    maxWidth: 280,
    margin: 'auto',
    transition: '0.3s',
    borderRadius: '1rem',
    borderColor: theme.palette.primary.main,
    boxShadow: theme.customShadows.widget,
    '&:hover': {
      cursor: 'pointer',
      transform: 'translateY(-10px)',
      boxShadow: theme.customShadows.widgetDark,
      transition: '0.3s',
      border: '1px solid',
    },
  },
  media: {
    height: 200,
  },
  mediaDetails: {
    maxWidth: 400,
    maxHeight: 400,
    minWidth: 400,
    minHeight: 400,
    margin: 'auto',
    aspectRatio: 'auto',
    objectFit: 'contain',
    borderRadius: '4px',
    padding: 20,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 245,
      maxHeight: 'auto',
      minWidth: 245,
      minHeight: 'auto',
      margin: 'auto',
      aspectRatio: 'auto',
      objectFit: 'contain',
    },
  },
  productCard: {
    padding: theme.spacing(5),
  },
  action: {
    padding: '5px 10px 10px 16px',
  },
  rootDetails: {
    maxWidth: '80%',
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      margin: 'auto',
    },
  },
  productCardDetails: {
    padding: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0),
    },
  },
  price: {
    display: 'flex',
    alignItems: 'center',
    padding: 6,
  },
  Thankyou: {
    textAlign: 'center',
    padding: 20,
    marginTop: 20,
    fontSize: 40,
    fontWeight: 800,
    textShadow: '2px 2px #FF0000',
  },
  paper1: {
    padding: theme.spacing(3),
    marginTop: 10,
    maxWidth: 1000,
    maxHeight: 500,
  },
  margin: {
    marginBottom: 24,
  },
  navPadding: {
    paddingTop: `${theme.spacing(2)}px !important`,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  reportCount: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  appBar: {
    position: 'relative',
    height: 60,
  },
  businessLogo: {
    minWidth: 60,
    maxWidth: 60,
    height: 'auto',
    aspectRatio: 'auto',
    objectFit: 'contain',
  },
  details: {
    padding: 15,
    border: '1px solid black',
  },
  background: {
    border: '1px solid black',
  },
  prod: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  nextButton: {
    marginLeft: 'auto',
    height: 'fit-content',
    alignSelf: 'center',
  },
  backButton: {
    height: 'fit-content',
    alignSelf: 'center',
  },
  frame: {
    width: '100%',
    border: '3px solid',
    borderColor: '#6e6e6e',
    borderRadius: '10px',
    overflow: 'auto',
  },
  dotRed: {
    background: 'rgb(237, 89, 74)',
    height: '12px',
    width: '12px',
    borderRadius: '50%',
    display: 'inline-block',
  },
  dotYellow: {
    background: 'rgb(253, 216, 0)',
    height: '12px',
    width: '12px',
    borderRadius: '50%',
    display: 'inline-block',
  },
  dotGreen: {
    background: 'rgb(90, 192, 90)',
    height: '12px',
    width: '12px',
    borderRadius: '50%',
    display: 'inline-block',
  },
  dotsContainer: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '15px 15px 15px',
    zIndex: '10',
    alignItems: 'center',
    backgroundColor: '#6e6e6e',
    padding: '10px',
    paddingLeft: '1.5rem',
  },
  paper: {
    position: 'relative',
    zIndex: 1,
    marginTop: '2%',
  },
}));
