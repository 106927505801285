import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    padding: '1rem',
    boxShadow: '1px 1px 3px 0px #8e8e8e',
    marginTop: '6rem',
    [theme.breakpoints.down('md')]: {
      marginTop: '8rem',
    },
    position: 'relative',
  },
  fieldWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  subjectWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  commonWidth: {
    width: 150,
  },
  cnclBtn: {
    color: 'white',
  },
  btnWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    gap: 8,
  },
  searchFieldAdvance: {
    height: 50,
  },
  newdiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  newdivPage: {
    [theme.breakpoints.up('lg')]: {
      width: 360,
    },
  },
  dateText: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '1rem',
  },
  svgStyle: {
    marginBottom: 3,
  },
  frwdSvgStyle: {
    marginTop: 3,
  },
  featureWrapper: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'start',
    },
  },
  featureBox: {
    display: 'flex',
    alignItems: 'center',
    gap: 3,
    marginLeft: 2,
  },
  muiAvatar: {
    color: '#071828',
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  emailBody: {
    fontWeight: 'bold',
  },
  primaryText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'start',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
      alignItems: 'start',
    },
  },
  fieldBox: {
    display: 'flex',
    backgroundColor: '#ffff',
    width: '93.6%',
    position: 'fixed',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: '1px 1px 3px 0px #8e8e8e',
    padding: 10,
    [theme.breakpoints.down('md')]: {
      width: '95.5%',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'start',
      gap: 10,
    },
    zIndex: 12,
    top: 0,
    marginTop: '4.5rem',
  },
  reEmailBody: {
    fontWeight: 'bold',
  },
  sidebar: {
    width: '300px',
    backgroundColor: theme.palette.background.paper,
    borderRight: `1px solid ${theme.palette.divider}`,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '-335px',
    zIndex: 1,
    padding: '10px',
    transition: 'left 0.3s ease',
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      left: 0,
      width: '300px',
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  },
  sidebarOpen: {
    left: 0,
  },
  content: {
    overflow: 'auto',
    padding: 20,
    width: '490px',
    height: '100%',
    borderRight: '1px solid #e0e0e0',
    marginRight: '20px',
    [theme.breakpoints.up('lg')]: {
      height: '72vh !important',
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      border: 'none',
      marginLeft: 0,
      padding: 0,
      width: '100%',
    },
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  menuBtn: {
    paddingLeft: 0,
  },
  clsBtn: {
    paddingTop: 0,
  },
  emailDetails: {
    backgroundColor: theme.palette.background.paper,
    borderLeft: `1px solid ${theme.palette.divider}`,
    width: '100%',
    height: '100%',
    padding: theme.spacing(3),
    overflow: 'auto',
    boxShadow: '1px 1px 3px 0px #8e8e8e',
    [theme.breakpoints.up('lg')]: {
      height: '72vh !important',
    },
  },
  noDetails: {
    backgroundColor: theme.palette.background.paper,
    borderLeft: `1px solid ${theme.palette.divider}`,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 600,
    padding: theme.spacing(3),
    overflow: 'auto',
    boxShadow: '1px 1px 3px 0px #8e8e8e',
  },
  noDetailText: {
    fontSize: 18,
  },
  searchField: {
    width: 300,
  },
  senderDetails: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  emailBox: {
    margin: '1rem 0',
  },
  senderText: {
    fontWeight: 'bold',
    fontSize: 18,
  },
  toSpan: {
    fontWeight: '700',
    color: '#343434',
  },
  subjectText: {
    fontWeight: 'bold',
    fontSize: 18,
    [theme.breakpoints.down('xs')]: {
      fontSize: 15,
    },
  },
  personIcon: {
    fontSize: 30,
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  date: {
    fontWeight: '500',
  },
  repliedBox: {
    marginLeft: '10px',
    borderLeft: '2px dotted gray',
    paddingLeft: '10px',
  },
  showEmailList: {
    display: 'block',
  },
  showDeatils: {
    display: 'block',
  },
  arrowBtn: {
    paddingTop: 0,
  },
  card: {
    minHeight: 'auto',
    display: 'flex',
    padding: '8px',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
      padiing: '8px',
    },
  },
  infoBox: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  logo: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  Sub: {
    display: 'block',
    color: localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2',
    fontWeight: 500,
    margin: '5px 0px',
  },
  draftGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  iconTheme: {
    color: `${localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2'} !important`,
  },
  hrEmail: {
    width: '100%',
    color: localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2',
  },
  filterSelect: {
    fontSize: '18px',
    fontWeight: '600',
    '&:before': {
      borderBottom: 'none',
    },
    '&:after': {
      borderBottom: 'none',
    },
    '&:hover': {
      borderBottom: 'none',
    },
  },
  hrEmailBody: {
    width: '100%',
    backgroundColor: '#005eb2',
    height: 1,
    [theme.breakpoints.down('xs')]: {
      width: '78%',
    },
  },
  email: {
    marginLeft: theme.spacing(6),
    marginTop: theme.spacing(2),
    width: '91%',
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(0),
    },
  },
  bodyEmail: {
    width: '91%',
  },
  docTitle: {
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    color: localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
    },
  },
  docs: {
    fontSize: 40,
    color: localStorage.getItem('theme') === 'dark' ? 'white' : '#005eb2',
  },
  endAdornmentBox: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
}));
