import gql from 'graphql-tag';

export const ProductServicesbylocation = `query productServicesbylocation($locationid: ID!) {
    productServicesbylocation(locationid: $locationid) {
      productserviceid
      locationid
      businessid
      name
      type
      category
      productid
      imgurl
      ispublish
      lifecycle
      shortdiscription
      longdiscription
      tag
      actualprice
      salesprice
      createdby
      updatedby
    }
  }`;

export const ProductServicesPagination = `query ProductServicesPagination($locationid: ID!, $pagenumber: Int!, $pagesize: Int!, $searchtext: String) {
    productServicesPagination(locationid: $locationid, pagenumber: $pagenumber, pagesize: $pagesize, searchtext: $searchtext) {
      count
      rows {
        productserviceid
        locationid
        businessid
        name
        type
        imgurl
        ispublish
        lifecycle
        shortdiscription
        longdiscription
        tag
        actualprice
        salesprice
        createdby
        updatedby
      }
    }
  }`

export const ProductServicesbyid = `query productServicesbyid($productserviceid: ID!) {
    productServicesbyid(productserviceid: $productserviceid) {
      productserviceid
      locationid
      businessid
      name
      type
      category
      productid
      imgurl
      lifecycle
      ispublish
      shortdiscription
      longdiscription
      tag
      actualprice
      salesprice
      thanksmessage
      isshowthanksbutton
      thanksbuttontext
      thanksbuttontype
      thanksbuttonlink
      thanksbuttonfileurl
      workflowid
      termenable
      termdesc
      captchaenable
      isshowfooterlogo
      createdby
      updatedby
    }
  }`;

export const ContactInformation = `mutation createContact($firstname: String!, $locationid: ID!, $contactid: ID, $primaryemail: String, $lastname: String, $phonenumber: String, $lifecyclestage: Int!, $status: Int!) {
  createContact(firstname: $firstname, locationid: $locationid, contactid: $contactid, primaryemail: $primaryemail, lastname: $lastname, phonenumber: $phonenumber, lifecyclestage: $lifecyclestage, status: $status) {
    contactid
    primaryemail
    secondaryemail
    firstname
    lastname
    contactavatar
    phonenumber
    locationid
    createdby
    updatedby
    ownerid
    createdAt
    updatedAt
}}`;

export const CreateProductServices = gql`
mutation CreateProductServices($locationid: ID, $businessid: ID, $name: String, $type: String, $category: String, $productid: String, $lifecycle: Action, $imgurl: [Upload], $shortdiscription: String, $longdiscription: String, $tag: [String], $actualprice: Float, $salesprice: Float, $createdby: ID, $ispublish: Boolean, $thanksmessage: String, $isshowthanksbutton: Boolean, $thanksbuttontext: String, $thanksbuttontype: String, $thanksbuttonlink: String, $thanksbuttonfileurl: String, $workflowid: ID, $termenable: Boolean,$isshowfooterlogo: Boolean, $termdesc: String, $captchaenable: Boolean) {
  createProductServices(locationid: $locationid, businessid: $businessid, name: $name, type: $type, category: $category, productid: $productid, lifecycle: $lifecycle, imgurl: $imgurl, shortdiscription: $shortdiscription, longdiscription: $longdiscription, tag: $tag, actualprice: $actualprice, salesprice: $salesprice, createdby: $createdby, ispublish: $ispublish, thanksmessage: $thanksmessage, isshowthanksbutton: $isshowthanksbutton, thanksbuttontext: $thanksbuttontext, thanksbuttontype: $thanksbuttontype, thanksbuttonlink: $thanksbuttonlink, thanksbuttonfileurl: $thanksbuttonfileurl, workflowid: $workflowid, termenable: $termenable, termdesc: $termdesc, captchaenable: $captchaenable, isshowfooterlogo:$isshowfooterlogo) {
    productserviceid
    locationid
    businessid
    name
    type
    category
    productid
    imgurl
    lifecycle
    shortdiscription
    longdiscription
    tag
    actualprice
    salesprice
    createdby
    updatedby
    ispublish
    thanksmessage
    isshowthanksbutton
    thanksbuttontext
    thanksbuttontype
    thanksbuttonlink
    thanksbuttonfileurl
    workflowid
    termenable
    termdesc
    captchaenable
    isshowfooterlogo
  }
}`;

export const ProductServicestag = `
query productServicestag($locationid:ID!){
  productServicestag(locationid:$locationid)
}
`;

export const EditProductServices = gql`
mutation EditProductServices($productserviceid: ID, $locationid: ID, $businessid: ID, $name: String, $type: String, $category: String, $productid: String, $lifecycle: Action, $imgurl: [Upload], $shortdiscription: String, $longdiscription: String, $tag: [String], $actualprice: Float, $salesprice: Float, $ispublish: Boolean, $updatedby: ID, $thanksmessage: String, $isshowthanksbutton: Boolean, $thanksbuttontext: String, $thanksbuttontype: String, $thanksbuttonlink: String, $thanksbuttonfileurl: String, $workflowid: ID, $termenable: Boolean, $termdesc: String, $captchaenable: Boolean,$isshowfooterlogo: Boolean) {
  editProductServices(productserviceid: $productserviceid, locationid: $locationid, businessid: $businessid, name: $name, type: $type, category: $category, productid: $productid, lifecycle: $lifecycle, imgurl: $imgurl, shortdiscription: $shortdiscription, longdiscription: $longdiscription, tag: $tag, actualprice: $actualprice, salesprice: $salesprice, ispublish: $ispublish, updatedby: $updatedby, thanksmessage: $thanksmessage, isshowthanksbutton: $isshowthanksbutton, thanksbuttontext: $thanksbuttontext, thanksbuttontype: $thanksbuttontype, thanksbuttonlink: $thanksbuttonlink, thanksbuttonfileurl: $thanksbuttonfileurl, workflowid: $workflowid, termenable: $termenable, termdesc: $termdesc, captchaenable: $captchaenable,isshowfooterlogo:$isshowfooterlogo)
}`;

export const DeleteProductServices = `
mutation deleteProductServices($productserviceid:ID!){
  deleteProductServices(productserviceid:$productserviceid)
}`;

export const ProductList = `
query productServicesbylocation($locationid: ID!) {
  productServicesbylocation(locationid: $locationid) {
    productserviceid,
    name,
    shortdiscription,
    salesprice,
  }
}`;
