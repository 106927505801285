import { makeStyles } from '@material-ui/styles';

export default makeStyles(() => ({
  field: {
    width: '100%',
  },
  disclaimer: {
    fontSize: 10,
    marginTop: 3,
  },
}));
