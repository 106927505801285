/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
import {
  Box, Button, Checkbox, createTheme, Divider, FormControlLabel, Grid, IconButton,
  InputAdornment,
  MenuItem, MuiThemeProvider, Paper, Radio, RadioGroup, Select, TextField, Typography,
} from '@material-ui/core';
import { ArrowBackIos, Event, Receipt } from '@material-ui/icons';
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { Rating } from '@material-ui/lab';
import ReactPhoneInput from 'react-phone-input-2';
import usestyle from './Style';
import Widget from '../../components/Widget/Widget';
import ContactlistDropDown from '../CRM Sales/ContactlistDropDown';
import OrganizationsName from '../Organizations/OrganizationsName';
import DynamicTable from './DynamicTable';
import { showNotification } from '../../components/Notification/ToastifyNotifications';
import { useGlobalState } from '../../context/GlobalContext';
import config from '../../config';
import { handleUndefined, postDataRequest } from '../../common/commonfunctions';
import { CreateSalesOrder, EditSalesOrder, SalesOrder } from '../../Api Mutation/OrderModuleApi';
import { LoadingPage } from '../LoadingPage/LoadingPage';
import {
  CurrencySymbol,
  SalesOrderStatus,
  SalesOrderStatusTitle,
  getFieldType,
} from '../../common/const';
import { SectionsByBusinessWithProperty } from '../../Api Mutation/Contact';
import { Entity } from '../../Api Mutation/EntityApi';
import CurrencySelect from '../../common/CurrencySelect';

function CreateOrderModule({ history, orderDialogView }) {
  const classes = usestyle();
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const salesorderid = params.get('sid');
  const orgId = params.get('oid');
  const globalContext = useGlobalState();
  const [orderStatus, setOrderStatus] = useState(1);
  const [orderId, setOrderId] = useState('');
  const [person, setPerson] = useState('');
  const [delivery, setDelivery] = useState('');
  const [address, setAddress] = useState('');
  const [dataLoading, setDataLoading] = useState(false);
  const [inputField, setInputField] = useState([]);
  const [inputFieldOrder, setInputFieldOrder] = useState([]);
  const [contact, setContact] = useState(null);
  const [contactId, setContactId] = useState(null);
  const [organizationData, setOrganizationData] = useState([]);
  const [organizationId, setOrganizationId] = useState(null);
  const [discount, setDiscount] = useState(0);
  const [currency, setCurrency] = useState('USD');
  const [defaultCountry, setDefaultCountry] = useState('');
  const [defaultCountryOrder, setDefaultCountryOrder] = useState('');
  const [sectionData, setSectionData] = useState([]);
  const [sectionOrderData, setSectionOrderData] = useState([]);
  const [multipleCheckbox, setMultipleCheckbox] = useState({});
  const [defaultRow, setDefaultRow] = useState({});
  const [tax, setTax] = useState(0);
  const [orderDate, setOrderDate] = React.useState(
    moment(new Date()),
  );
  const [shipmentDate, setShipmentDate] = React.useState(new Date());
  const [rows, setRows] = useState([]);
  const locationId = JSON.parse(localStorage.getItem('location_Id'));
  const businessId = JSON.parse(localStorage.getItem('business_Id'));
  const userId = JSON.parse(localStorage.getItem('userid'));

  console.log('inputField', inputField);

  const totalAmount = rows.reduce((total, item) => {
    const amount = parseFloat(item.totalprice);
    return Number.isNaN(amount) ? total : total + amount;
  }, 0);

  const getMuiTheme = () => createTheme({
    overrides: {
      MuiOutlinedInput: {
        notchedOutline: {
          border: 'none !important',
        },
      },
      MuiSelect: {
        icon: {
          display: 'none !important',
        },
      },
      MuiAutocomplete: {
        endAdornment: {
          display: 'none !important',
        },
      },
      MuiInputLabel: {
        shrink: {
          display: 'none important',
        },
        outlined: {
          display: 'none  !important'
        }
      },
      MuiInputBase: {
        input: {
          color: localStorage.getItem('theme') === 'dark' ? 'white !important' : '#5d5d5d !important',
        },
      },
    },
  });

  const getTableMuiTheme = () => createTheme({
    overrides: {
      MuiOutlinedInput: {
        notchedOutline: {
          border: 'none !important',
        },
      },
      MuiSelect: {
        icon: {
          display: 'none !important',
        },
      },
      MuiAutocomplete: {
        endAdornment: {
          display: 'none !important',
        },
      },
      MuiInputLabel: {
        shrink: {
          color: localStorage.getItem('theme') === 'dark' ? 'white !important' : '#5d5d5d !important',
          border: '1px solid grey',
        },
        outlined: {
          color: localStorage.getItem('theme') === 'dark' ? 'white !important' : '#5d5d5d !important',
          border: '1px solid grey',
        }
      },
      MuiInputBase: {
        input: {
          color: localStorage.getItem('theme') === 'dark' ? 'white !important' : '#5d5d5d !important',
        },
      },
    },
  });

  const getInitialData = () => {
    setDataLoading(true);
    if (globalContext?.globalState?.currentLocation?.parententityid
      || JSON.parse(localStorage.getItem('business_Id'))) {
      postDataRequest(config.baseURLApi, {
        query: SectionsByBusinessWithProperty,
        variables: {
          businessid: globalContext?.globalState?.currentLocation?.parententityid
            || JSON.parse(localStorage.getItem('business_Id')),
        },
      }).then((res) => {
        if (res?.data?.sectionsByBusinessWithProperty) {
          setSectionData(res.data.sectionsByBusinessWithProperty.find((item) => item?.objecttype === 'order')?.properties);
          setSectionOrderData(res.data.sectionsByBusinessWithProperty.find((item) => item?.objecttype === 'orderItem')?.properties);
          const customProperties = res.data.sectionsByBusinessWithProperty.find((item) => item?.objecttype === 'orderItem')?.properties;
          const constObj = {
            title: '', quantity: 1, description: '', singleitemprice: 0, totalprice: '', productid: null, status: orderStatus, isCustom: false,
          };
          customProperties?.map((m) => {
            constObj[m.dbfieldname] = null;
          });
          if (!salesorderid) {
            setRows([constObj]);
          }
          setDefaultRow(constObj);
        }
      });
      setDataLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const formDataObject = Object.fromEntries(formData.entries());
    const discountParse = parseFloat(formDataObject?.discount) || 0;
    const taxParse = parseFloat(formDataObject?.tax) || 0;
    rows.map((c) => {
      delete c.isCustom;
      return c;
    });

    const finalAmount = Number(totalAmount) + Number(tax) - Number(discount);

    if (orderId) {
      if (!rows.find((c) => c?.title === '')) {
        if (!rows.find((c) => c?.quantity === '')) {
          if (globalContext?.globalState?.currentLocation?.entityid) {
            setDataLoading(true);
            if (salesorderid) {
              delete inputField?.createdAt;
              delete inputField?.updatedAt;
              delete inputField?.orderdate;
              delete inputField?.expectedshipmentdate;
              inputField.updatedby = userId;
              inputField.locationid = globalContext?.globalState?.currentLocation?.entityid;
              inputField.businessid = businessId;
              inputField.orderno = orderId || formDataObject?.orderid;
              inputField.orderdate = orderDate || formDataObject?.dateoforder;
              inputField.salesperson = person || formDataObject?.salesperson;
              inputField.contactid = contact?.id || contactId;
              inputField.organizationid = organizationData?.organizationid || organizationId;
              inputField.status = orderStatus;
              inputField.expectedshipmentdate = shipmentDate || formDataObject?.shipmentdate;
              inputField.deliverymethod = delivery || formDataObject?.delivery;
              inputField.totalamount = finalAmount;
              inputField.totaldiscount = discountParse;
              inputField.totaltax = taxParse;
              inputField.physicaladdress = address || formDataObject?.address;
              inputField.orderitems = rows;
              inputField.currency = currency;
              postDataRequest(config.baseURLApi, {
                query: EditSalesOrder,
                variables: {
                  salesorderid,
                  ...inputField,
                },
              }).then((res) => {
                if (res?.errors && res?.errors[0]?.message) {
                  showNotification(res?.errors[0]?.message, 'error');
                } else if (res?.data?.editSalesOrder) {
                  showNotification('Order Updated Successfully', 'success');
                  if (orgId) {
                    history.push(`/app/organization-edit?oid=${orgId}&tab=4`);
                  } else {
                    history.push('/app/sales-order');
                  }
                }
                setDataLoading(false);
              });
            } else {
              postDataRequest(config.baseURLApi, {
                query: CreateSalesOrder,
                variables: {
                  locationid: globalContext?.globalState?.currentLocation?.entityid,
                  businessid: businessId,
                  createdby: userId,
                  orderno: orderId || formDataObject?.orderid,
                  orderdate: orderDate || formDataObject?.dateoforder,
                  salesperson: person || formDataObject?.salesperson,
                  contactid: contact?.id || contactId,
                  organizationid: organizationData?.organizationid || organizationId,
                  status: orderStatus,
                  expectedshipmentdate: shipmentDate || formDataObject?.shipmentdate,
                  deliverymethod: delivery || formDataObject?.delivery,
                  totalamount: finalAmount,
                  totaldiscount: discountParse,
                  totaltax: taxParse,
                  physicaladdress: address || formDataObject?.address,
                  orderitems: rows,
                  currency,
                  ...inputField,
                },
              }).then((res) => {
                if (res?.errors && res?.errors[0]?.message) {
                  showNotification(res?.errors[0]?.message, 'error');
                } else if (res?.data?.createSalesOrder) {
                  showNotification('Create Order Successfully', 'success');
                  if (orgId) {
                    history.push(`/app/organization-edit?oid=${orgId}&tab=4`);
                  } else {
                    history.push('/app/sales-order');
                  }
                }
                setDataLoading(false);
              });
            }
          }
        } else {
          showNotification('Please select atleast 1 quantity', 'error');
        }
      } else {
        showNotification('Please Select Product', 'error');
      }
    } else {
      showNotification('Orderid is required', 'error');
    }
  };

  const getSalesOrder = () => {
    if (salesorderid) {
      setDataLoading(true);
      postDataRequest(config.baseURLApi, {
        query: SalesOrder,
        variables: {
          salesorderid,
        },
      }).then((res) => {
        if (res?.errors && res?.errors[0]?.message) {
          showNotification(res?.errors[0]?.message, 'error');
        } else if (res?.data?.salesOrder) {
          const data = res?.data?.salesOrder;
          data.orderitems.map((c) => {
            if (c?.productid) {
              c.isCustom = false;
            } else {
              c.isCustom = true;
            }
            return c;
          });
          setRows(data?.orderitems);
          setTax(Number(data?.totaltax));
          setDiscount(Number(data?.totaldiscount));
          setCurrency(data?.currency);
          setOrderStatus(data?.status);
          setShipmentDate(data?.expectedshipmentdate ? moment(parseInt(data?.expectedshipmentdate, 10)).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'));
          setOrderDate(data?.orderdate ? moment(parseInt(data?.orderdate, 10)).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'));
          setOrderId(data?.orderno);
          setPerson(data?.salesperson);
          setAddress(data?.physicaladdress);
          setDelivery(data?.deliverymethod);
          setContactId(data?.contactid);
          setOrganizationId(data?.organizationid);
          setInputField(data);
        }
        setDataLoading(false);
      });
    }
  };

  const handleChangeValue = (e, db) => {
    inputField[db] = e.target.value;
    setInputField({ ...inputField });
  };

  const handleChangePhone = (isPhone, db) => {
    inputField[db] = isPhone;
    setInputField({ ...inputField });
  };

  const handleChangeDate = (e, db) => {
    if (e === null) {
      inputField[db] = null;
      setInputField({ ...inputField });
    } else {
      inputField[db] = moment(e).format('YYYY-MM-DDTHH:mm');
      setInputField({ ...inputField });
    }
  };

  const handleChangeDropDown = (newValue, db) => {
    inputField[db] = newValue;
    setInputField({ ...inputField });
  };

  const handleChangeCheck = (e, db) => {
    inputField[db] = JSON.stringify(e.target.checked);
    setInputField({ ...inputField });
  };

  const handleChangeCheckMulti = async (e, db, label, index) => {
    if (multipleCheckbox[db] === undefined) {
      multipleCheckbox[db] = [];
    }
    if (e.target.checked === false) {
      if (index !== -1) {
        inputField[db] = JSON.stringify(Object.values(multipleCheckbox[db]));
        multipleCheckbox[db].splice(index, 1);
        await setInputField({ ...inputField });
      }
    } else {
      multipleCheckbox[db].push(label);
      await setMultipleCheckbox({ ...multipleCheckbox });
      inputField[db] = JSON.stringify(Object.values(multipleCheckbox[db]));
      await setInputField({ ...inputField });
    }
  };

  const handleChangeRadio = (e, db) => {
    inputField[db] = e.target.value;
    setInputField({ ...inputField });
  };

  useEffect(() => {
    let isMount = true;
    if (isMount) {
      if (salesorderid) {
        getSalesOrder();
      }
      if (globalContext?.globalState?.currentLocation?.entityid) {
        getInitialData();
        postDataRequest(config.baseURLApi, {
          query: Entity,
          variables: {
            entityid: locationId || globalContext?.globalState?.currentLocation?.entityid,
          },
        }).then((country) => {
          if (country?.data?.entity?.defaultcountrycode) {
            setDefaultCountry(country?.data?.entity?.defaultcountrycode);
            setDefaultCountryOrder(country?.data?.entity?.defaultcountrycode);
          }
        });
      }
    }
    return () => {
      isMount = false;
    };
  }, [salesorderid, globalContext?.globalState?.currentLocation?.entityid]);

  return (
    <>
      {dataLoading ? <LoadingPage /> : ''}
      <Paper
        className={handleUndefined(orderDialogView) ? classes.orderViewPaper
          : classes.orderPaper}
        elevation={3}
        id='orderPrint'
      >
        {(orderDialogView && orderId) ? <Typography className={classes.orderHeading}>{`# ${orderId}`}</Typography> : ''}
        {handleUndefined(orderDialogView) ? '' : (
          <Widget>
            <Box className={classes.centerDivWrap}>
              <Box className={classes.centerDiv}>
                <IconButton
                  onClick={() => {
                    if (orgId) {
                      history.push(`/app/organization-edit?oid=${orgId}&tab=4`);
                    } else {
                      history.push('/app/sales-order');
                    }
                  }}
                  style={{ paddingRight: 2 }}
                >
                  <ArrowBackIos />
                </IconButton>
                <Typography variant="h6">
                  Sales Order
                </Typography>
              </Box>
              <Box className={classes.centerDiv}>
                <CurrencySelect currency={currency} setCurrency={setCurrency} />
              </Box>
            </Box>
          </Widget>
        )}
        <br />
        <div>
          <form onSubmit={(e) => handleSubmit(e)}>
            <Grid container style={{ width: '100%' }}>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <Box className={classes.fieldBox}>
                  <Typography className={orderDialogView ? classes.headingDialog : classes.heading}>Order Id#*</Typography>
                  {orderDialogView ? (
                    <Typography className={classes.fieldValue}>{orderId}</Typography>
                  ) : (
                    <TextField
                      variant="outlined"
                      InputProps={{ readOnly: handleUndefined(orderDialogView) }}
                      className={classes.commonField}
                      name="orderid"
                      value={orderId}
                      onChange={(e) => setOrderId(e.target.value)}
                      size="small"
                    />)}
                </Box>
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <Box className={classes.fieldBox}>
                  <Typography className={orderDialogView ? classes.headingContactDialog : classes.headingContact}>Customer Name</Typography>
                  {orderDialogView ? (<MuiThemeProvider theme={getMuiTheme()}>
                    <ContactlistDropDown
                      setContact={setContact}
                      contact={contact}
                      contactId={contactId}
                      setContactId={setContactId}
                      isOrderModule
                      orderDialogView={!!handleUndefined(orderDialogView)}
                    />
                  </MuiThemeProvider>) : (
                    <ContactlistDropDown
                      setContact={setContact}
                      contact={contact}
                      contactId={contactId}
                      setContactId={setContactId}
                      isOrderModule
                      orderDialogView={!!handleUndefined(orderDialogView)}
                    />
                  )}
                </Box>
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <Box className={classes.fieldBox}>
                  <Typography className={orderDialogView ? classes.headingDialog : classes.heading}>Date of order</Typography>
                  {orderDialogView ? (
                    <Typography className={classes.fieldValue}>{moment(parseInt(orderDate ? orderDate : (new Date()), 10)).format('dd/MM/yyyy')}</Typography>
                  ) : (<MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                  >
                    <DatePicker
                      id="date-picker-dialog"
                      inputVariant="outlined"
                      className={classes.commonField}
                      name="shipmentdate"
                      size="small"
                      readOnly={handleUndefined(orderDialogView)}
                      fullWidth
                      ampm={false}
                      clearable
                      value={handleUndefined(orderDate)}
                      onChange={(date) => setOrderDate(date)}
                      format="dd/MM/yyyy"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton>
                              <Event />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </MuiPickersUtilsProvider>)}
                </Box>
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <Box className={classes.fieldBox}>
                  <Typography className={orderDialogView ? classes.headingDialog : classes.heading}>Organization Name</Typography>
                  {orderDialogView ? (<MuiThemeProvider theme={getMuiTheme()}>
                    <OrganizationsName
                      setOrganizationData={setOrganizationData}
                      organizationData={organizationData}
                      organizationId={organizationId}
                      setOrganizationId={setOrganizationId}
                      locationid={locationId}
                      isOrderModule
                      orderDialogView={!!handleUndefined(orderDialogView)}
                    />
                  </MuiThemeProvider>) : (
                    <OrganizationsName
                      setOrganizationData={setOrganizationData}
                      organizationData={organizationData}
                      organizationId={organizationId}
                      setOrganizationId={setOrganizationId}
                      locationid={locationId}
                      isOrderModule
                      orderDialogView={!!handleUndefined(orderDialogView)}
                    />
                  )}
                </Box>
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <Box className={classes.fieldBox}>
                  <Typography className={orderDialogView ? classes.headingDialog : classes.heading}>Sales Person</Typography>
                  {orderDialogView ? (
                    <Typography className={classes.fieldValue}>{person || '-'}</Typography>
                  ) : (<TextField
                    variant="outlined"
                    className={classes.commonField}
                    name="salesperson"
                    InputProps={{ readOnly: handleUndefined(orderDialogView) }}
                    value={person}
                    onChange={(e) => setPerson(e.target.value)}
                    size="small"
                  />)}
                </Box>
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <Box className={classes.fieldBox}>
                  <Typography className={orderDialogView ? classes.headingDialog : classes.heading}>Order Status</Typography>
                  {orderDialogView ? (
                    <Typography className={classes.fieldValue}>{SalesOrderStatusTitle[orderStatus] || '-'}</Typography>
                  ) : (<Select
                    id="demo-simple-select-outlined"
                    variant="outlined"
                    className={classes.commonField}
                    value={orderStatus}
                    readOnly={handleUndefined(orderDialogView)}
                    name="orderstatus"
                    onChange={(e) => setOrderStatus(e?.target?.value)}
                  >
                    {SalesOrderStatus.map((s, i) => (
                      <MenuItem value={s?.value} key={i}>
                        {s?.label}
                      </MenuItem>
                    ))}
                  </Select>)}
                </Box>
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <Box className={classes.fieldBox}>
                  <Typography className={orderDialogView ? classes.headingDialog : classes.heading}>Physical Address</Typography>
                  {orderDialogView ? (
                    <Typography className={classes.fieldValue}>{address || '-'}</Typography>
                  ) : (<TextField
                    variant="outlined"
                    name="address"
                    className={classes.commonField}
                    value={address}
                    InputProps={{ readOnly: handleUndefined(orderDialogView) }}
                    onChange={(e) => setAddress(e.target.value)}
                    size="small"
                  />)}
                </Box>
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <Box className={classes.fieldBox}>
                  <Typography className={orderDialogView ? classes.headingDialog : classes.heading}>Expected Shipment Date</Typography>
                  {orderDialogView ? (
                    <Typography className={classes.fieldValue}>{moment(parseInt(shipmentDate ? shipmentDate : (new Date()), 10)).format('dd/MM/yyyy')}</Typography>
                  ) : (<MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                  >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        id="date-picker-dialog"
                        inputVariant="outlined"
                        className={classes.commonField}
                        name="shipmentdate"
                        size="small"
                        readOnly={handleUndefined(orderDialogView)}
                        fullWidth
                        ampm={false}
                        timeIcon={false}
                        clearable
                        value={handleUndefined(shipmentDate)}
                        onChange={(date) => setShipmentDate(date)}
                        format="dd/MM/yyyy"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton>
                                <Event />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </MuiPickersUtilsProvider>)}
                </Box>
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <Box className={classes.fieldBox}>
                  <Typography className={orderDialogView ? classes.headingDialog : classes.heading}>Delivery Method</Typography>
                  {orderDialogView ? (
                    <Typography className={classes.fieldValue}>{delivery || '-'}</Typography>
                  ) : (<TextField
                    variant="outlined"
                    name="delivery"
                    className={classes.commonField}
                    value={delivery}
                    InputProps={{ readOnly: handleUndefined(orderDialogView) }}
                    onChange={(e) => setDelivery(e.target.value)}
                    size="small"
                  />)}
                </Box>
              </Grid>
              {sectionData && sectionData.map((item, index) => (
                <Fragment key={index}>
                  <br />
                  {item?.fieldtype === getFieldType.Singlelinetext ? (
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                      <Box className={classes.fieldBox}>
                        <Typography className={orderDialogView ? classes.headingDialog : classes.heading}>{item?.label}</Typography>
                        {orderDialogView ? (
                          <Typography className={classes.fieldValue}>{inputField?.[item.dbfieldname] || '-'}</Typography>
                        ) : (<TextField
                          fullWidth
                          className={classes.commonField}
                          size="small"
                          variant="outlined"
                          InputProps={{ readOnly: handleUndefined(orderDialogView) }}
                          InputLabelProps={{
                            shrink: inputField?.[item.dbfieldname],
                          }}
                          value={inputField?.[item.dbfieldname] || ''}
                          onChange={(e) => handleChangeValue(e, item.dbfieldname)}
                          name={item.dbfieldname}
                          type="text"
                          label={item?.label}
                        />)}
                      </Box>
                    </Grid>
                  ) : ('')}
                  {item?.fieldtype === getFieldType.Multilinetext ? (
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                      <Box className={classes.fieldBox}>
                        <Typography className={orderDialogView ? classes.headingDialog : classes.heading}>{item?.label}</Typography>
                        {orderDialogView ? (
                          <Typography className={classes.fieldValue}>{inputField?.[item.dbfieldname] === null ? '-' : inputField?.[item.dbfieldname]}</Typography>
                        ) : (<TextField
                          fullWidth
                          id={item?.label?.toString()}
                          className={classes.commonField}
                          size="small"
                          variant="outlined"
                          value={inputField?.[item.dbfieldname] === null ? '' : inputField?.[item.dbfieldname]}
                          onChange={(e) => handleChangeValue(e, item.dbfieldname)}
                          multiline
                          InputProps={{ readOnly: handleUndefined(orderDialogView) }}
                          name={item.dbfieldname}
                          InputLabelProps={{
                            shrink: inputField?.[item.dbfieldname],
                          }}
                          type="text"
                          label={item?.label}
                        />)}
                      </Box>
                    </Grid>
                  ) : ('')}
                  {item?.fieldtype === getFieldType.Singlecheckbox ? (
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                      <Box className={classes.fieldBox}>
                        <Typography className={orderDialogView ? classes.headingDialog : classes.heading}>{item?.label}</Typography>
                        {orderDialogView ? (
                          <Typography className={classes.fieldValue}>{inputField[item?.dbfieldname] || '-'}</Typography>
                        ) : (<FormControlLabel
                          className={classes.commonField}
                          control={(
                            <Checkbox
                              readOnly={handleUndefined(orderDialogView)}
                              disabled={handleUndefined(orderDialogView)}
                              checked={inputField[item?.dbfieldname]
                                ? Boolean(inputField[item?.dbfieldname]
                                  && JSON.parse(inputField[item?.dbfieldname]))
                                : false}
                              onChange={(e) => handleChangeCheck(e, item.dbfieldname)}
                              name={item.dbfieldname}
                              color="primary"
                            />
                          )}
                          label={item?.label}
                        />)}
                      </Box>
                    </Grid>
                  ) : ('')}
                  {item?.fieldtype === getFieldType.Multiplecheckbox ? (
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                      <Box className={classes.fieldBox}>
                        <Typography className={orderDialogView ? classes.headingDialog : classes.heading}>{item?.label}</Typography>
                        {orderDialogView ? (
                          <Typography className={classes.fieldValue}>{inputField[item?.dbfieldname] ? JSON.parse(inputField[item?.dbfieldname]).toString().replace(',', ' , ') : '-'}</Typography>
                        ) : (JSON.parse(item?.options) && JSON.parse(item?.options).map((i, ind) => (
                          <FormControlLabel
                            key={ind}
                            className={classes.field}
                            control={(
                              <Checkbox
                                id={ind?.toString()}
                                readOnly={handleUndefined(orderDialogView)}
                                disabled={handleUndefined(orderDialogView)}
                                checked={
                                  inputField[item.dbfieldname]
                                  && JSON.parse(inputField[item.dbfieldname]).includes(i.label)
                                }
                                onChange={(e) => handleChangeCheckMulti(e,
                                  item.dbfieldname, i.label)}
                                name={i.label}
                                color="primary"
                              />
                            )}
                            label={i.label}
                          />
                        )))}
                      </Box>
                    </Grid>
                  ) : ('')}

                  {item?.fieldtype === getFieldType.Dropdownselect ? (
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                      <Box className={classes.fieldBox}>
                        <Typography className={orderDialogView ? classes.headingDialog : classes.heading}>{item?.label}</Typography>
                        {orderDialogView ? (<MuiThemeProvider theme={getMuiTheme()}>
                          <Select
                            fullWidth
                            className={orderDialogView ? classes.commonFieldDialog : classes.commonField}
                            variant="outlined"
                            id={`value-${index}`}
                            readOnly={handleUndefined(orderDialogView)}
                            labelId="demo-simple-select-outlined-label"
                            value={inputField?.[item.dbfieldname]}
                            label={item?.label}
                            onChange={(e) => handleChangeDropDown(e.target.value, item.dbfieldname)}
                          >
                            {JSON.parse(item?.options)
                              && JSON.parse(item?.options).map((e, i) => (
                                <MenuItem value={e.label} key={i}>
                                  {e.label}
                                </MenuItem>
                              ))}
                          </Select>
                        </MuiThemeProvider>) : (
                          <Select
                            fullWidth
                            className={orderDialogView ? classes.commonFieldDialog : classes.commonField}
                            variant="outlined"
                            id={`value-${index}`}
                            readOnly={handleUndefined(orderDialogView)}
                            labelId="demo-simple-select-outlined-label"
                            value={inputField?.[item.dbfieldname]}
                            label={item?.label}
                            onChange={(e) => handleChangeDropDown(e.target.value, item.dbfieldname)}
                          >
                            {JSON.parse(item?.options)
                              && JSON.parse(item?.options).map((e, i) => (
                                <MenuItem value={e.label} key={i}>
                                  {e.label}
                                </MenuItem>
                              ))}
                          </Select>
                        )}
                      </Box>
                    </Grid>
                  ) : ('')}
                  {item?.fieldtype === getFieldType.Radioselect ? (
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                      <Box className={classes.fieldBox}>
                        <Typography className={orderDialogView ? classes.headingDialog : classes.heading}>{item?.label}</Typography>
                        {orderDialogView ? (
                          <Typography className={classes.fieldValue}>{inputField[item?.dbfieldname] || '-'}</Typography>
                        ) : (<RadioGroup
                          className={classes.commonField}
                          id={index?.toString()}
                          row
                          name={item.dbfieldname}
                          value={
                            inputField[item?.dbfieldname]
                              ? inputField[item?.dbfieldname] : ''
                          }
                          onChange={(e) => handleChangeRadio(e, item.dbfieldname)}
                        >
                          {JSON.parse(item?.options)
                            && JSON.parse(item?.options)?.map((i, ind) => (
                              <FormControlLabel
                                id={index?.toString()}
                                value={i?.label}
                                control={(
                                  <Radio
                                    readOnly={handleUndefined(orderDialogView)}
                                    disabled={handleUndefined(orderDialogView)}
                                  />
                                )}
                                label={i?.label}
                                key={ind}
                              />
                            ))}
                        </RadioGroup>)}
                      </Box>
                    </Grid>
                  ) : ('')}
                  {item?.fieldtype === getFieldType.Datepicker ? (
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                      <Box className={classes.fieldBox}>
                        <Typography className={orderDialogView ? classes.headingDialog : classes.heading}>{item?.label}</Typography>
                        {orderDialogView ? (
                          <Typography className={classes.fieldValue}>
                            {inputField?.[item.dbfieldname] ? moment(inputField?.[item.dbfieldname]).format('DD/MM/YYYY') : '-'}
                          </Typography>
                        ) : (<MuiPickersUtilsProvider
                          utils={DateFnsUtils}
                        >
                          <DateTimePicker
                            id={item?.label?.toString()}
                            inputVariant="outlined"
                            className={classes.commonField}
                            label={item?.label}
                            size="small"
                            readOnly={handleUndefined(orderDialogView)}
                            fullWidth
                            ampm={false}
                            clearable
                            value={inputField?.[item.dbfieldname]
                              ? inputField?.[item.dbfieldname]
                              : null}
                            onChange={(e) => handleChangeDate(e, item.dbfieldname)}
                            format="yyyy/MM/dd hh:mm"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton>
                                    <Event />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </MuiPickersUtilsProvider>)}
                      </Box>
                    </Grid>
                  ) : ('')}
                  {item?.fieldtype === getFieldType.Numbers ? (
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                      <Box className={classes.fieldBox}>
                        <Typography className={orderDialogView ? classes.headingDialog : classes.heading}>{item?.label}</Typography>
                        {orderDialogView ? (
                          <Typography className={classes.fieldValue}>{inputField?.[item.dbfieldname] || '-'}</Typography>
                        ) : (<TextField
                          fullWidth
                          className={classes.commonField}
                          size="small"
                          InputProps={{ readOnly: handleUndefined(orderDialogView) }}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: inputField[item?.dbfieldname],
                          }}
                          value={inputField?.[item.dbfieldname] || ''}
                          name={item.dbfieldname}
                          onChange={(e) => handleChangeValue(e, item.dbfieldname)}
                          type="number"
                          label={item?.label}
                        />)}
                      </Box>
                    </Grid>
                  ) : ('')}
                  {item?.fieldtype === getFieldType.Rating ? (
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                      <Box className={classes.fieldBox}>
                        <Typography className={orderDialogView ? classes.headingDialog : classes.heading}>{item?.label}</Typography>
                        <Rating
                          name={item?.dbfieldname}
                          precision={0.1}
                          readOnly={handleUndefined(orderDialogView)}
                          disabled={handleUndefined(orderDialogView)}
                          value={Number(inputField?.[item.dbfieldname])}
                          onChange={(e) => handleChangeValue(e, item.dbfieldname)}
                        />
                      </Box>
                    </Grid>
                  ) : ('')}
                  {item?.fieldtype === getFieldType.Phonenumber ? (
                    <>
                      <Grid item lg={6} md={12} sm={12} xs={12}>
                        <Box className={classes.fieldBox}>
                          <Typography className={orderDialogView ? classes.headingDialog : classes.heading}>{item?.label}</Typography>
                          {orderDialogView ? (
                            <Typography className={classes.fieldValue}>{inputField[item?.dbfieldname] || '-'}</Typography>
                          ) : (<ReactPhoneInput
                            specialLabel={false}
                            country={defaultCountry || 'us'}
                            disabled={handleUndefined(orderDialogView)}
                            className={classes.commonField}
                            isValid={(value, country) => {
                              if (value.length < 11 && value.length > 2 && country) {
                                return false;
                              }
                              return true;
                            }}
                            variant="outlined"
                            value={inputField[item?.dbfieldname] || ''}
                            placeholder="Enter Phone Number"
                            onChange={(phoneno) => handleChangePhone(phoneno, item?.dbfieldname)}
                          />)}
                        </Box>
                      </Grid>
                    </>
                  ) : ('')}
                  {item?.fieldtype === getFieldType.ContactDropDown ? (
                    <>
                      <Grid item lg={6} md={12} sm={12} xs={12}>
                        <Box className={classes.fieldBox}>
                          <Typography className={orderDialogView ? classes.headingDialog : classes.heading}>{item?.label}</Typography>
                          {orderDialogView ? (<MuiThemeProvider theme={getMuiTheme()}>
                            <ContactlistDropDown
                              isOrderModule
                              orderDialogView={!!handleUndefined(orderDialogView)}
                              label={item?.label}
                              contactId={inputField[item?.dbfieldname]}
                              setContactId={(newValue) => {
                                inputField[item?.dbfieldname] = newValue;
                                setInputField({ ...inputField });
                              }}
                            />
                          </MuiThemeProvider>) : (
                            <ContactlistDropDown
                              isOrderModule
                              orderDialogView={!!handleUndefined(orderDialogView)}
                              label={item?.label}
                              contactId={inputField[item?.dbfieldname]}
                              setContactId={(newValue) => {
                                inputField[item?.dbfieldname] = newValue;
                                setInputField({ ...inputField });
                              }}
                            />
                          )}
                        </Box>
                      </Grid>
                    </>
                  ) : ('')}
                </Fragment>
              ))}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box>
                  <Typography><b>Item List</b></Typography>
                  {orderDialogView ? (
                    <MuiThemeProvider theme={getTableMuiTheme()}>
                      <DynamicTable
                        rows={rows}
                        setRows={setRows}
                        orderStatus={orderStatus}
                        getSalesOrder={getSalesOrder}
                        sectionOrderData={sectionOrderData}
                        setInputFieldOrder={setInputFieldOrder}
                        inputFieldOrder={inputFieldOrder}
                        defaultCountryOrder={defaultCountryOrder}
                        defaultRow={defaultRow}
                        orderDialogView={!!handleUndefined(orderDialogView)}
                      />
                    </MuiThemeProvider>
                  ) : (<DynamicTable
                    rows={rows}
                    setRows={setRows}
                    orderStatus={orderStatus}
                    getSalesOrder={getSalesOrder}
                    sectionOrderData={sectionOrderData}
                    setInputFieldOrder={setInputFieldOrder}
                    inputFieldOrder={inputFieldOrder}
                    defaultCountryOrder={defaultCountryOrder}
                    defaultRow={defaultRow}
                    orderDialogView={!!handleUndefined(orderDialogView)}
                  />)}
                </Box>
                <br />
                <br />
              </Grid>
              <Grid item lg={5} />
              <Grid item lg={7} md={12} sm={12} xs={12}>
                <Paper className={classes.subPaper}>
                  <Box>
                    <Box className={classes.totalWrap}>
                      <Typography>
                        <b>Sub Total:</b>
                      </Typography>
                      <Typography>
                        {totalAmount?.toFixed(2)}
                      </Typography>
                    </Box>
                    <br />
                    <br />
                    <Box className={classes.totalWrap}>
                      <Box className={classes.fieldBoxSub}>
                        <Typography className={classes.headingSub}>Discount</Typography>
                        {orderDialogView ? (
                          <Typography className={classes.fieldValue}>{discount}</Typography>
                        ) : (<TextField
                          variant="outlined"
                          name="discount"
                          value={discount}
                          InputProps={{ readOnly: handleUndefined(orderDialogView) }}
                          onChange={(e) => setDiscount(e.target.value)}
                          className={classes.commonFieldSub}
                          type="number"
                          size="small"
                        />)}
                      </Box>
                      <Typography>
                        {(Number(totalAmount) - Number(discount))?.toFixed(2)}
                      </Typography>
                    </Box>
                    <Box className={classes.totalWrap}>
                      <Box className={classes.fieldBoxSub}>
                        <Typography className={classes.headingSub}>Tax</Typography>
                        <Box className={classes.dFlex}>
                          {orderDialogView ? (
                            <Typography className={classes.fieldValue}>{tax}</Typography>
                          ) : (<TextField
                            variant="outlined"
                            name="tax"
                            InputProps={{ readOnly: handleUndefined(orderDialogView) }}
                            value={tax}
                            onChange={(e) => setTax(e.target.value)}
                            type="number"
                            className={classes.commonFieldSub}
                            size="small"
                          />)}
                        </Box>
                      </Box>
                      <Typography>
                        {(Number(totalAmount) + Number(tax))?.toFixed(2)}
                      </Typography>
                    </Box>
                    <br />
                    <Divider />
                    <br />
                    <Box className={classes.totalWrap}>
                      <Typography variant="h6">
                        <b>
                          Total
                        </b>
                      </Typography>
                      <Typography>
                        <b>
                          {currency ? `(${CurrencySymbol[currency]})` : '($)'}
                          {' '}
                          {(Number(totalAmount) + Number(tax) - Number(discount))?.toFixed(2)}
                        </b>
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
                <br />
              </Grid>
              {!handleUndefined(orderDialogView) && (
                <Box className={classes.btnBox}>
                  <Button variant="contained" className={classes.orderBtn} color="primary" type="submit" size="medium" endIcon={<Receipt />}>Save</Button>
                </Box>
              )}
            </Grid>
          </form>
        </div>
      </Paper>
    </>
  );
}

export default CreateOrderModule;
