export const modules = {
  agency: 'Agency',
  business: 'Business',
  locationManage: 'Location',
  settings: 'Settings',
  client: 'Client',
  form: 'Forms',
  chat: 'Chat',
  myWorkspace: 'My Workspace',
  contacts: 'Contacts',
  organization: 'Organization',
  favourites: 'Favourites',
  leadfinder: 'Lead Finder',
  appointment: 'Appointments',
  productService: 'Product & Service',
  location: 'Location',
  locationSelection: 'Business/Location Selection Top Bar',
  inquiryForms: 'Inquiry Forms',
  emailMarketing: 'Email Marketing',
  textMarketing: 'Text Marketing',
  contentMarketing: 'Content Marketing',
  monitorReputation: 'Monitor Reputation',
  importLeads: 'Import Leads',
  searchLeads: 'Search Leads',
  reviewCampaigns: 'Review Campaigns',
  quickReviews: 'Quick Reviews',
  manageCustomers: 'Manage Customers',
  prospects: 'Prospects',
  reviewMarketing: 'Review Marketing',
  templateCatalog: 'Template Catalog',
  showcaseReviews: 'Showcase Reviews',
  reviewPage: 'Review Page',
  reviewBadge: 'Review Badge',
  kioskMode: 'Kiosk Mode',
  reputationDashboard: 'Reputation Dashboard',
  contactDashboard: 'Contact Dashboard',
  marketingDashboard: 'Marketing Dashboard',
  salesDashboard: 'Sales Dashboard',
  businessAnalytics: 'Business Analytics',
  manageAgency: 'Manage Agency',
  whiteLabelSettings: 'White Label Settings',
  manageBusiness: 'Manage Business',
  manageBrand: 'Manage Brand',
  userManagement: 'User Management',
  manageLocation: 'Manage Location',
  fetchOnlineReview: 'Fetch Online Review',
  campaignSettings: 'Campaign Settings',
  processAutomation: 'Process Automation',
  myProfile: 'My Profile',
  captureLeads: 'Capture Leads',
  professionalServices: 'Professional Services',
  manageSalesDeal: 'Deals',
  bixBox: 'BixBox',
  task: 'Task',
  call: 'Call',
  businessPage: 'Business Page',
  paymentInvoice: 'Payment & Invoice',
  segmentation: 'Segmentation',
  teams: 'Teams',
};
export const permissions = {
  agencyDashboard: 'Agency Dashboard',
  fromDashboard: 'Form List',
  myWorkspace: 'My Workspace',
  locationManage: 'Location Dashboard',
  locationCreate: 'Create Location',
  businessDashbord: 'Business Dashboard',
  createForm: 'Form Add/Edit',
  deleteFrom: 'Form Delete',
  manageChat: 'Manage Chat',
  prospects: 'Prospects',
  locationSelection: 'Business/Location Selection Top Bar',
  inquiryForms: 'Inquiry Form List',
  inquiryFormsAdd: 'Inquiry Form Add/Edit',
  inquiryFormsDelete: 'Inquiry Form Delete',
  inquiryFormsSearch: 'Manage Search Leads',
  manageAppointment: 'Manage Appointment',
  createBusiness: 'Create Business',
  clientDashboard: 'Client Dashboard',
  createLocation: 'Create Location',
  addDeal: 'Add Sales Deal',
  favourites: 'Favourites',
  editDeal: 'Edit Sales Deal',
  deleteDeal: 'Delete Sales Deal',
  viewDeal: 'Grid Sales Central',
  reviewGrid: 'Review Grid',
  autoReply: 'Auto Reply',
  editReply: 'Edit Reply/Response',
  report: 'Report',
  createCampaign: 'Create Campaign',
  campaignGrid: 'Campaign Grid',
  customerGrid: 'Customer Grid',
  addEditCustomer: 'Add/Edit Customer',
  exportCustomer: 'Export Customer',
  leadfinder: 'Manage Lead Finder',
  captureLeads: 'Capture Leads List',
  captureLeadsAddEdit: 'Capture Leads Add/Edit',
  captureLeadsDelete: 'Capture Leads Delete',
  importLeads: 'Manage Import Leads',
  searchLeads: 'Manage Search Leads',
  importCustomer: 'Import Contact',
  showCaseReview: 'Manage Showcase Review',
  reviewPage: 'Manage Review Page',
  reviewBadge: 'Manage Review Badge',
  kioskMode: 'Manage Kiosk Mode',
  businessAnalyticsDashboard: 'Business Analytics Dashboard',
  reputationDashboard: 'Manage Reputation Dashboard',
  contactDashboard: 'Manage Contact Dashboard',
  marketingDashboard: 'Manage Marketing Dashboard',
  salesDashboard: 'Manage Sales Dashboard',
  manageAgency: 'Manage Agency',
  whiteLabelSettings: 'White Label Settings',
  manageBusiness: 'Manage Business',
  manageBrand: 'Manage Your Brand',
  addUser: 'Add User',
  deleteUser: 'Delete User',
  editAccess: 'Edit Access',
  manageLocation: 'Manage Location',
  fetchOnlineReview: 'Fetch Online Review',
  campaignSettings: 'Campaign Settings',
  processAutomation: 'Process Automation',
  myProfile: 'My Profile',
  contacts: 'Contact Grid',
  addContact: 'Add/Edit Contact',
  organization: 'Organization List',
  organizationDelete: 'Organization Delete',
  addOrganization: 'Organization Create/Edit',
  exportContact: 'Export Contact',
  importContact: 'Import Contact',
  emailMarketing: 'Manage Email Marketing',
  templateCatalog: 'Manage Template Catalog',
  textMarketing: 'Text Marketing',
  manageTextMarketing: 'Manage Text Marketing',
  contentMarketing: 'Manage Content Marketing',
  managePaymentInvoice: 'Manage Payment & Invoice',
  professionalServices: 'Manage Professional Services',
  manageBixConnectPage: 'Manage Bix Connect Page',
  manageShowcaseReview: 'Manage Showcase Review',
  manageReviewPage: 'Manage Review Page',
  manageReviewBadge: 'Manage Review Badge',
  manageKioskMode: 'Manage Kiosk Mode',
  productService: 'Manage Product & Service',
  bixBox: 'BixBox',
  task: 'Task',
  call: 'Call',
  createEditCampaign: 'Create/Edit Campaign',
  deleteCampaign: 'Delete Campaign',
  manageProperties: 'Manage Properties',
  businessPage: 'Business Page',
  paymentInvoice: 'Manage Payment & Invoice',
  segmentation: 'Manage Segmentation',
  monitorReputation: 'Monitor Reputation',
  teams: 'Manage Teams',
};
