import React from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CurrencyJSON } from './const';

function CurrencySelect({ currency, setCurrency }) {
  const handleChange = (event, value) => {
    setCurrency(value?.value || '');
  };

  return (
    <div>
      <Autocomplete
        id="currency-autocomplete"
        options={CurrencyJSON}
        getOptionLabel={(option) => `${option.label}`}
        value={CurrencyJSON.find((c) => c.value === currency) || null}
        onChange={handleChange}
        size="small"
        renderInput={(params) => (
          <TextField
            {...params}
            style={{ width: 150 }}
            label="Select Currency"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
        )}
      />
    </div>
  );
}

export default CurrencySelect;
